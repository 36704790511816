import {JsonObject, JsonProperty} from 'json2typescript';
import {EVENT_TYPES} from '../enums/EventTypes';
import {Device} from './Device';
import {Site} from './Site';

@JsonObject('DeviceStatusAlert')
export class DeviceStatusAlert {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('eventGuid', String, true)
  public eventGuid: string = undefined;

  @JsonProperty('type', String, true)
  public type: string = undefined;

  @JsonProperty('status', String, true)
  public status: string = undefined;

  @JsonProperty('deviceId', Number, true)
  public deviceId: number = undefined;

  @JsonProperty('companyId', Number, true)
  public companyId: number = undefined;

  @JsonProperty('sentAt', Number, true)
  public sentAt: number = undefined;

  @JsonProperty('device', Device, true)
  public device: Device = undefined;

  @JsonProperty('site', Site, true)
  public site: Site = undefined;
}
