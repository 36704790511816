<div class="action-bar" *ngIf="currEmployeeRole !== 'controller'">
  <button class="btn btn-icon mr-2" (click)="selectDevice(null)">
    <span class="ul-btn__text">Add a new Device</span>
    <span class="ul-btn__icon"><mat-icon>add</mat-icon></span>
  </button>
</div>

<div class="card">

  <div class="card-body">
    <table mat-table [dataSource]="devices" class="table-hover">

      <ng-container matColumnDef="deviceName">
        <th mat-header-cell *matHeaderCellDef> Device Name</th>
        <td mat-cell *matCellDef="let element"> {{element.deviceName}} </td>
      </ng-container>

      <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef> Serial Number</th>
        <td mat-cell *matCellDef="let element"> {{element.serialNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="contactNumber">
        <th mat-header-cell *matHeaderCellDef> Contact Number</th>
        <td mat-cell *matCellDef="let element"> {{element.contactNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef> Site</th>
        <td mat-cell *matCellDef="let element"> {{element.site.name}} </td>
      </ng-container>

      <ng-container matColumnDef="isActivated">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="!element.appInstanceId" matTooltip="This device is NOT activated" matTooltipPosition="above">error_outline</mat-icon>
          <mat-icon *ngIf="element.appInstanceId" matTooltip="This device is activated" matTooltipPosition="above">check_circle_outline</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon>chevron_right</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          (click)="selectDevice(devices[i])"></tr>
    </table>
  </div>

</div>
<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
