import {Injectable} from '@angular/core';
import { Site } from '../models/Site';
import { Boundaries } from '../models/Boundaries';
import {CommonService} from "./commonService";

@Injectable({
  providedIn: 'root'
})
export class SiteServiceService {

  constructor(
    private commonService: CommonService
  ) {}

  getAllSitesByCompany(id: number) {
    return this.commonService.getData('/api/v1/site/byCompany',id);
  }

  getSiteById(id: number) {
    return this.commonService.getData('/api/v1/site', id)
  }

  upsertSite(site: Site) {
    return this.commonService.postData('/api/v1/site', site)
  }

  deleteSite(id: number) {
    return this.commonService.postData('/api/v1/site/removeSite', {id})
  }

  getSiteBranchOptions(companyId: number) {
    return this.commonService.getData(`/api/v1/site/branchOptions?companyId=${companyId}`);
  }

  getSiteDivisionOptions(companyId: number) {
    return this.commonService.getData(`/api/v1/site/divisionOptions?companyId=${companyId}`);
    }

    checkBoundries(boundries: Boundaries) {
        return this.commonService.postData(`/api/v1/site/BoundariesCheck?id=${boundries.id}&latitude=${boundries.latitude}&longitude=${boundries.longitude}`);
    }
}
