import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MessageService, MessageTypes} from '../../message.service';
import {Subscription} from 'rxjs';
import {Company} from '../../models/Company';

@Component({
  selector: 'app-sys-admin-navbar',
  templateUrl: './sys-admin-navbar.component.html',
  styleUrls: ['./sys-admin-navbar.component.css']
})

export class SysAdminNavbarComponent implements OnInit {


  constructor(private router: Router, private messageService: MessageService) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.router.navigate(['login']);
  }


}
