import {JsonObject, JsonProperty} from 'json2typescript';
import {EVENT_TYPES} from '../enums/EventTypes';
import {Device} from './Device';
import {RouteTaken} from './RouteTaken';
import {Site} from './Site';
import {RouteTag} from './RouteTag';

@JsonObject('RouteTagScan')
export class RouteTagScan {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('eventGuid', String, true)
  public eventGuid: string = undefined;

  @JsonProperty('type', String, true)
  public type: string = EVENT_TYPES.NFC_TAG_SCAN;

  @JsonProperty('companyId', Number, true)
  public companyId: number = undefined;

  @JsonProperty('deviceId', Number, true)
  public deviceId: number = undefined;

  @JsonProperty('device', Device, true)
  public device: Device = undefined;

  @JsonProperty('routeTagId', Number, true)
  public routeTagId: number = undefined;

  @JsonProperty('routeTakenId', Number, true)
  public routeTakenId: number = undefined;

  @JsonProperty('routeTaken', RouteTaken, true)
  public routeTaken: RouteTaken = undefined;

  @JsonProperty('scannedAt', Number, true)
  public scannedAt: number = undefined;

  @JsonProperty('site', Site, true)
  public site: Site = undefined;

  @JsonProperty('routeTag', RouteTag, true)
  public routeTag: RouteTag = undefined;

  @JsonProperty('isSkipped', Boolean, true)
  public isSkipped: Boolean = false;

  @JsonProperty('ReasonId', Number, true)
  public reasonId: Number = 0;
}
