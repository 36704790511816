import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ComponentBaseComponent, MessageType} from "../../component-base";
import {ProfileService} from "../../services/profile-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {StorageService} from "../../services/storage-service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends ComponentBaseComponent implements OnInit {

  code: string;
  isBusy = false;
  password: string = '';
  confirmPassword: string = '';

  constructor(
    private profileService: ProfileService,
    public router: Router,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    protected storageService: StorageService,
    protected route: ActivatedRoute
  ) { super(snackbar, dialog, router, storageService) }

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get("code")
  }

  resetPassword() {
    this.isBusy = true;
    if (this.validPasswords()) {
      this.profileService.updatePassword(this.code, this.confirmPassword).subscribe((result) => {
        this.showMessage('Password successfully updated.', MessageType.success);
        this.router.navigate(['/login']);
        this.isBusy = false;
      }, error => {
        this.handleError(error);
        this.isBusy = false;
      });
    }
  }

  validPasswords() {
    if(this.password !== this.confirmPassword) {
      this.showMessage('Passwords do not match.', MessageType.error);
      this.isBusy = false;
      return false;
    }
    if(this.password === '' || this.confirmPassword === '') {
      this.showMessage('Fill in all fields.', MessageType.error);
      this.isBusy = false;
      return false;
    }
    return true;
  }
}
