import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {StorageService} from './services/storage-service';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';

export enum MessageType {
  success = 'success',
  warning = 'warning',
  error = 'error'
}


export class ComponentBaseComponent {

  constructor(protected snackBar: MatSnackBar, protected dialog: MatDialog, protected router: Router, protected storageService: StorageService) {
  }

  handleError(error: HttpErrorResponse) {
    let messageType = MessageType.error;
    let errorMessage = 'An error has occurred';

    if (error.status === 477) {
      this.showMessage('Please complete your detail', messageType);
    } else {
      if (error.error instanceof ErrorEvent || (error.error && error.error.message)) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = error.error.message;
      } else if (error.statusText) {
        errorMessage = error.statusText;
      } else if (error.message) {
        //  errorMessage = error.message;
        errorMessage = error.message;
      } else if (error.error && error.error.Text && error.error.Details) {
        errorMessage = error.error.Text + ' - ' + error.error.Details;
      } else if (error.error.error_description) {
        //  errorMessage = error.message;
        errorMessage = error.error.error_description;
      } else {
        errorMessage = error.error;
      }

      if (error.status === 401) {
        errorMessage = 'Please log in again. Session expired.';
        messageType = MessageType.error;
        this.router.navigate(['login']);
        this.storageService.removeAccessToken();
      }
      this.showMessage(errorMessage, messageType);
    }
  }

  showMessage(message: string, type: MessageType) {
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.duration = 5000;
    this.snackBar.open(message, 'OK', config);
  }

  showConfirmationDialog(actionText = null, buttonText = null, buttonIcon = null) {
    return this.dialog.open(ConfirmDialogComponent, {
      height: 'fit-content',
      width: '450px',
      data: {
        actionText,
        buttonText,
        buttonIcon
      }
    });
  }

}
