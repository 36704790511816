<div class="column" style="overflow: hidden">
  <button id="btn-close" class="btn btn-rounded btn-gray-400" (click)="confirm(false)"><mat-icon>close</mat-icon></button>

  <div id="content">
    <h3 style="">Are you sure you would like to <span>{{actionText}}</span></h3>

    <button class="btn btn-primary btn-rounded mt-4" (click)="confirm(true)">
      <span class="mr-2">{{buttonText}}</span>
      <mat-icon>{{buttonIcon}}</mat-icon>
    </button>
  </div>
</div>
