import {Component, Input, OnInit} from '@angular/core';
import {GREEN_ALERTS, RED_ALERTS, YELLOW_ALERTS} from '../../enums/EventTypes';
import {getAlertDateTime, getAlertDescription} from '../../logic/AlertLogic';
import {StorageService} from "../../services/storage-service";

@Component({
  selector: 'app-alert-item',
  templateUrl: './alert-item.component.html',
  styleUrls: ['./alert-item.component.scss']
})
export class AlertItemComponent implements OnInit {
  @Input() alert;

  alertStyleClass = '';
  alertDateTime = '';
  alertDeviceName = '';
  alertDescription = '';
  currEmployeeRole : string;

  constructor(
    private storageService : StorageService
  ) { }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.alertDateTime = getAlertDateTime(this.alert);
    this.alertDescription = getAlertDescription(this.alert);
    this.alertDeviceName = (this.alert.device) ? this.alert.device.deviceName : (this.alert.site ? this.alert.site.name : null);
    this.alertStyleClass = this.getAlertStyleClass();
  }

  getAlertStyleClass() {
    if (GREEN_ALERTS.includes(this.alert.type)) {
      return 'alert alert-green';
    } else if (YELLOW_ALERTS.includes(this.alert.type)) {
      return 'alert alert-yellow';
    } else if (RED_ALERTS.includes(this.alert.type)) {
      return 'alert alert-red';
    } else {
      return 'alert alert-misc';
    }
  }

}
