import {Component, OnInit} from '@angular/core';
import {StorageService} from '../../services/storage-service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Site} from '../../models/Site';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {EmployeeService} from '../../services/employee-service';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {SiteServiceService} from "../../services/site.service";
import {validEmail} from "../../logic/commonLogic";
import {jsPDF} from "jspdf";

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent extends ComponentBaseComponent implements OnInit {
  EMPLOYEE_NOT_LINKED_TO_SITE_OPTION = 'None';

  isBusy = false;
  sites: Site[];
  roles = ['security-guard', 'controller', 'admin'];

  employeeForm: FormGroup;
  employeeId: number;
  userId: number;
  role: string;
  siteValue;
  currEmployeeRole: string;
  qrCodeData: string;

  PREVIOUS_PAGE = 'company/employees';

  constructor(
    protected storageService: StorageService,
    private formBuilder: FormBuilder,
    private siteServiceService: SiteServiceService,
    private employeeService: EmployeeService,
    public snackbar: MatSnackBar,
    public dialogRef: MatDialog,
    public router: Router
  ) {
    super(snackbar, dialogRef, router,storageService)
  }

  ngOnInit(): void {
    this.prepareForm();
    this.getEmployee();
    this.getSites();
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.createQRCode();
  }

  prepareForm() {
    this.employeeForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(128), Validators.minLength(3)]],
      surname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(128)]],
      contactNumber: ['', [Validators.maxLength(11), Validators.minLength(9)]],
      emailAddress: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(300)]],
      password: [''],
      confirmPassword: ['']
    });
  }

  formToObj() {
    const role = (this.role === 'security-guard') ? 'default' : this.role;
    const companyId = this.storageService.getSelectedCompany().id;
    const siteId = (this.siteValue && this.siteValue.id) ? this.siteValue.id : null;

    return {
      id: this.employeeId,
      name: this.employeeForm.value.name,
      surname: this.employeeForm.value.surname,
      contactNumber: this.employeeForm.value.contactNumber,
      emailAddress: this.employeeForm.value.emailAddress,
      companyId: companyId,
      siteId: siteId,
      role: role,
      password: this.employeeForm.value.password,
      confirmPassword: this.employeeForm.value.confirmPassword,
      userId: this.userId
    };
  }

  getEmployee() {
    const employee = this.storageService.getSelectedEmployee();

    if (employee) {
      this.employeeId = employee.id;
      this.userId = employee.userId;
      this.role = (employee.role === 'default') ? 'security-guard' : employee.role;
      this.siteValue = employee.site;

      this.employeeForm.patchValue({
        name: employee.name,
        surname: employee.surname,
        contactNumber: employee.contactNumber,
        emailAddress: employee.emailAddress
      });
    }
  }

  getSites() {
    this.isBusy = true;
    const companyId = this.storageService.getSelectedCompany().id;
    this.siteServiceService.getAllSitesByCompany(companyId).subscribe((result) => {
      let jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.sites = jsonConvert.deserializeArray(result.body as any, Site);

      if (this.siteValue && this.siteValue.id) {
        this.siteValue = this.sites.find(currentSite => currentSite.id === this.siteValue.id);
      }

      this.siteValue = (this.siteValue && this.siteValue.id) ? this.siteValue : this.EMPLOYEE_NOT_LINKED_TO_SITE_OPTION;

      this.isBusy = false;
    }, error => {
      this.isBusy = false;
      this.handleError(error);
    });
  }

  upsertEmployee() {
    if (this.employeeForm.valid) {
      const actionText = (this.employeeId) ? 'save your changes' : 'create a new employee';

      let dialogRef = this.showConfirmationDialog(actionText);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const employee = this.formToObj();

          if (this.employeeId || employee.password === employee.confirmPassword) {
            if (!validEmail(employee.emailAddress)) {
              this.showMessage('Invalid email address.', MessageType.error);
              return;
            }
            this.isBusy = true;
            this.employeeService.upsertEmployee(employee).subscribe((result) => {
              this.isBusy = false;
              this.showMessage('Employee successfully added/updated', MessageType.success);
              this.router.navigate([this.PREVIOUS_PAGE]);
            }, error => {
              this.isBusy = false;
              this.handleError(error);
            });
          } else {
            this.showMessage('Passwords do not match.', MessageType.error);
          }
        }
      });
    } else {
      const employee = this.formToObj();
      if (employee.name.length > 128) {
        this.showMessage('Employee name too long.', MessageType.error);
      } else if (employee.name.length < 3) {
        this.showMessage('Employee name too short.', MessageType.error);
      } else if (employee.surname.length > 128) {
        this.showMessage('Employee surname too long.', MessageType.error);
      } else if (employee.surname.length < 4) {
        this.showMessage('Employee surname too short.', MessageType.error);
      } else if (employee.contactNumber.length != 10) {
        this.showMessage('Employee Contact Number.', MessageType.error);
      } else if (employee.emailAddress.length <= 5) {
        this.showMessage('Employee email address to short.', MessageType.error);
      } else if (employee.emailAddress.length > 300) {
        this.showMessage('Employee email address too long', MessageType.error);
      }

    }
  }

  getEmployeeFullName() {
    return (this.employeeId) ? this.employeeForm.value.name + ' ' + this.employeeForm.value.surname : 'New Employee';
  }

  deleteEmployee(){
    const employee = this.storageService.getSelectedEmployee();
    let dialogRef = this.showConfirmationDialog(`delete employee ${employee.name}`, null, 'delete');

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isBusy = true;
        this.employeeService.deleteEmployee(this.storageService.getSelectedEmployee().id).subscribe((result) => {
          this.isBusy = false;
          this.showMessage('Employee Deleted', MessageType.success);
          this.router.navigate([this.PREVIOUS_PAGE]);
        }, error => {
          this.isBusy = false;
          this.handleError(error);
        });
      }
    });
  }

  createQRCode() {
    const userId = this.storageService.getSelectedEmployee().userId;

    this.qrCodeData = userId.toString().padStart(16, "0");
  }

  downloadEmployeeData () {
    const employee = this.storageService.getSelectedEmployee();

    const docName = employee.name + " " + employee.surname + " Information";

    let base64Image = document.getElementById('qr_code').querySelector("canvas").toDataURL("image/png");

    const content = `<h1>Employee Information</h1>
                    </br>
                    <h2>Name: ${employee.name}</h2>
                    <h2>Surname: ${employee.surname}</h2>
                    <h2>Email: ${employee.emailAddress}</h2>
                    <h2>Phone Number: ${employee.contactNumber}</h2>
                    <h2>Site: ${employee.site.name}</h2>
    `;

    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
    });

    doc.addImage(base64Image, 'png', 5, 120, 100, 100);
 
    doc.html(content, {
      async callback(doc) {
        await doc.save(docName);
      },
      margin: 15,
      width: 810,
      windowWidth: 2000,
      autoPaging: "text",
    });
  }
}
