import {Component, OnInit} from '@angular/core';
import {Area} from '../../models/Area';
import {Employee} from '../../models/Employee';
import {ROLE_TYPES} from '../../enums/RoleTypes';
import {StorageService} from '../../services/storage-service';
import {AreaService} from '../../services/area.service';
import {ComponentBaseComponent} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';

@Component({
  selector: 'app-area-summary',
  templateUrl: './area-summary.component.html',
  styleUrls: ['./area-summary.component.scss']
})
export class AreaSummaryComponent extends ComponentBaseComponent implements OnInit {
  ROLE_TYPES = ROLE_TYPES;

  isBusy = false;
  loggedInEmployee: Employee;
  displayedColumns = ['areaName', 'contactPerson', 'contactNumber', 'contactEmail', 'shouldReceiveReports', 'image'];
  areas: Area[] = [];

  constructor(public storageService: StorageService, private areaService: AreaService, public snackBar: MatSnackBar, public dialog: MatDialog, public router: Router) {
    super(snackBar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.getLoggedInEmployee();
    this.getAreas();
  }

  getAreas() {
    this.isBusy = true;

    const companyId = this.storageService.getSelectedCompany().id;
    this.areaService.getAreasByCompany(companyId).subscribe(result => {
      let jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.areas = jsonConvert.deserializeArray((result as any).body, Area);

      this.isBusy = false;
    }, error => {
      this.handleError(error);
    });
  }

  getLoggedInEmployee() {
    this.loggedInEmployee = this.storageService.getLoggedInEmployee();
  }

  selectArea(area: Area) {
    this.storageService.setSelectedArea(area);

    const areaId = (area) ? area.id : 'new';
    this.router.navigate(['/company/area/' + areaId]);
  }

}
