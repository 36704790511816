import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ALERT_COLOR_CLASSIFICATIONS, EVENT_TOPICS, EVENT_TYPES} from '../../enums/EventTypes';
import {SocketService} from '../../services/socketService';
import {MatDialog} from '@angular/material/dialog';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {getAlertColorClassification, getAlertDateTime, getAlertDescription} from '../../logic/AlertLogic';
import {EventService} from '../../services/event.service';
import {StorageService} from '../../services/storage-service';
import {AlertDetailsComponent} from '../alert-details/alert-details.component';
import * as moment from 'moment';

@Component({
  selector: 'app-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.scss']
})
export class AlertNotificationsComponent extends ComponentBaseComponent implements OnInit, OnDestroy {
  greenAlertCounter = 0;
  yellowAlertCounter = 0;
  redAlertCounter = 0;
  miscAlertCounter = 0;

  alarmIsPlaying = false;
  isMuted = true;
  socketConnectionAlive = true;
  socketConnectionUpdateTime = new Date().getTime();
  socketConnectionMessage = '';
  alertAlarms = [];
  timer;
  currEmployeeRole : string;

  @ViewChild('alertAlarmAudio') alertAlarmAudioRef: ElementRef;

  shouldPlayAlarm = (): boolean => !this.isMuted && !this.alarmIsPlaying && this.alertAlarms.length > 0;

  constructor(
    private socketService: SocketService,
    protected dialog: MatDialog,
    protected router: Router,
    protected snackbar: MatSnackBar,
    private eventService: EventService,
    protected storageService: StorageService,
  ) {
    super(snackbar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.getAllUnrespondedToAlertAlarms();
    this.listenForEvents();
    this.timer = this.resetHeartBeatTimer();
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role
  }

  resetHeartBeatTimer() {
    return setTimeout(() => {
      this.socketConnectionAlive = false;
      this.setSocketConnectionMessage();
    }, 31000);
  }

  getAllUnrespondedToAlertAlarms() {
    const companyId = this.storageService.getSelectedCompany().id;
    this.eventService.getUnrespondedToAlertAlarms(companyId).subscribe(result => {
      this.parseAlertData(result.body as Array<any>);
    }, error => {
      this.handleError(error);
    });
  }

  setSocketConnectionMessage() {
    this.socketConnectionMessage = `Server connection ${this.socketConnectionAlive ? 'fine' : 'down!'} (${moment(this.socketConnectionUpdateTime).format('HH:mm:ss')})`;
  }

  listenForEvents() {
    this.socketService.getSocket().on(`heartbeat`, (data) => {
      this.socketConnectionAlive = true;
      this.socketConnectionUpdateTime = new Date().getTime();
      this.setSocketConnectionMessage();
      clearTimeout(this.timer);
      this.timer = this.resetHeartBeatTimer();
    });

    this.socketService.getSocket().on(`${EVENT_TOPICS.CRITICAL}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + EVENT_TOPICS.CRITICAL);
        return;
      }
      this.parseAlertData([data]);
    });

    this.socketService.getSocket().on(`${EVENT_TOPICS.PATROL}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + EVENT_TOPICS.PATROL);
        return;
      }
      this.parseAlertData([data]);
    });

    this.socketService.getSocket().on(`${EVENT_TOPICS.ATTENDANCE}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + EVENT_TOPICS.ATTENDANCE);
        return;
      }
      this.parseAlertData([data]);
    });

    this.socketService.getSocket().on(`${EVENT_TOPICS.CHAT}.${EVENT_TYPES.PLEASE_CALL_ME}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + `${EVENT_TOPICS.CHAT}.${EVENT_TYPES.PLEASE_CALL_ME}`);
        return;
      }
      this.parseAlertData([data]);
    });
  }

  ngOnDestroy(): void {
    this.socketService.getSocket().off(`heartbeat`);
    this.socketService.getSocket().off(`${EVENT_TOPICS.CRITICAL}`);
    this.socketService.getSocket().off(`${EVENT_TOPICS.PATROL}`);
    this.socketService.getSocket().off(`${EVENT_TOPICS.ATTENDANCE}`);
    this.socketService.getSocket().off(`${EVENT_TOPICS.CHAT}.${EVENT_TYPES.PLEASE_CALL_ME}`);
  }

  parseAlertData(alertData: Array<any>) {
    for (let alert of alertData) {
      let alertColorClassification;

      if (!alert) {
        console.error('Received undefined Alert!');
        return;
      }

      if (alert.type === EVENT_TYPES.ALERT_ALARM_RESPONSE) {
        alertColorClassification = getAlertColorClassification(alert.respondedToType);

        this.removeAlertAlarm(alert.respondedToGuid);

        switch (alertColorClassification) {
          case ALERT_COLOR_CLASSIFICATIONS.GREEN: {
            if (this.greenAlertCounter > 0) this.greenAlertCounter--;
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.YELLOW: {
            if (this.yellowAlertCounter > 0) this.yellowAlertCounter--;
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.RED: {
            if (this.redAlertCounter > 0) this.redAlertCounter--;
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.MISC: {
            if (this.miscAlertCounter > 0) this.miscAlertCounter--;
            break;
          }
        }
      } else {
        alertColorClassification = getAlertColorClassification(alert.type);

        switch (alertColorClassification) {
          case ALERT_COLOR_CLASSIFICATIONS.GREEN: {
            this.greenAlertCounter++;
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.YELLOW: {
            this.yellowAlertCounter++;
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.RED: {
            this.redAlertCounter++;
            this.createAlertAlarm(alert);
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.MISC: {
            this.miscAlertCounter++;
            break;
          }
        }
      }
    }
  }

  createAlertAlarm(event) {
    this.alertAlarms.push(event);

    this.toggleAlarm();
  }

  toggleAlarm(shouldPlay: boolean = this.shouldPlayAlarm()) {
    if (shouldPlay) {
      this.playAlarm();
    } else {
      this.stopAlarm();
    }
  }

  playAlarm() {
    this.alarmIsPlaying = true;

    this.alertAlarmAudioRef.nativeElement.loop = true;
    this.alertAlarmAudioRef.nativeElement.play();
  }

  stopAlarm() {
    this.alarmIsPlaying = false;

    this.alertAlarmAudioRef.nativeElement.pause();
    this.alertAlarmAudioRef.nativeElement.currentTime = 0;
  }

  setAudioVolume(setTo: boolean) {
    this.isMuted = setTo;

    this.toggleAlarm();
  }

  getAlertDateTime(alert) {
    return getAlertDateTime(alert);
  }

  getAlertAlarmDescription(alert) {
    return getAlertDescription(alert, true);
  }

  removeAlertAlarm(alertAlarmEventGuid: string) {
    this.alertAlarms = this.alertAlarms.filter(currentAlertAlarm => currentAlertAlarm.eventGuid !== alertAlarmEventGuid);
  }

  openAlertAlarmResponseDialog(index) {
    this.toggleAlarm(false);

    let alertAlarmResponseDialog = this.dialog.open(AlertDetailsComponent, {
      height: 'fit-content',
      width: '600px',
      panelClass: 'dialog-container-hidden',
      data: {
        alert: this.alertAlarms[index]
      }
    });

    alertAlarmResponseDialog.afterClosed().subscribe(result => {
      if (result && result.successful) {
        this.showMessage('Alert response sent', MessageType.success);

        this.removeAlertAlarm(this.alertAlarms[index].eventGuid);
      }

      this.toggleAlarm();
    });
  }

}
