import {Injectable} from '@angular/core';
import {CommonService} from './commonService';


@Injectable({
  providedIn: 'root'
})

export class ScheduleService {


  constructor(
    private commonService: CommonService
  ) {
  }

  getSchedule(siteId) {
    return this.commonService.getData(`/api/v1/schedule/${Number(siteId)}`, '');
  }

  upsertSchedule(schedule) {
    return this.commonService.postData(`/api/v1/schedule`, schedule);
  }

  deleteScheduleById(scheduleId)  {
    return this.commonService.postData('/api/v1/schedule/removeSchedule', {scheduleId});
  }

  getSchedulePlansByScheduleId(scheduleId) {
    return this.commonService.getData(`/api/v1/schedule/plans/byScheduleId/${Number(scheduleId)}`);
  }

  getSchedulePlansGeneratedBySiteId(siteId) {
    return this.commonService.getData(`/api/v1/schedule/plans/generated/${siteId}`);
  }
}
