
<div class="flex-container">
  <button mat-button class="close-icon" [mat-dialog-close]="true" (click)="closeDialog(isPreview)">
    <mat-icon>close</mat-icon>
  </button>

  <input id="identificationInput"  #imageUploader  name="file" type="file" [hidden]="true" multiple
         (change)="onFileSelected($event)">
  <button mat-flat-button class="btn btn-icon btn-primary rounded float-left" *ngIf="!isPreview" (click)="imageUploader .click()">Upload Image</button>

  <img *ngIf="imgUri; else elseBlock" class="image-canvas" [src]="imgUri">
  <ng-template #elseBlock>
    <p class="select-img-msg">Select image to upload</p>
  </ng-template>

  <button mat-flat-button class="btn btn-primary btn-icon mr-2 rounded" *ngIf="!isPreview && imgUri"  (click)="sendImage()">Send Image</button>
</div>
<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
