import {Component} from '@angular/core';
import { SocketService } from './services/socketService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SecurityApp';
  // constructor(
  //   private service: SocketService
  // ){
  //   this.service.connect();
  // }
}
