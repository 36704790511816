<div class="action-bar" *ngIf="loggedInEmployee.role !== ROLE_TYPES.CONTROLLER">
  <button class="btn btn-icon mr-2" (click)="selectArea(null)">
    <span class="ul-btn__text">Add a new Area</span>
    <span class="ul-btn__icon"><mat-icon>add</mat-icon></span>
  </button>
</div>

<div class="card">
  <div class="card-body">
    <table mat-table [dataSource]="areas" class="table-hover">

      <ng-container matColumnDef="areaName">
        <th mat-header-cell *matHeaderCellDef> Area Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="contactPerson">
        <th mat-header-cell *matHeaderCellDef> Contact Person</th>
        <td mat-cell *matCellDef="let element"> {{element.contactPerson}} </td>
      </ng-container>

      <ng-container matColumnDef="contactNumber">
        <th mat-header-cell *matHeaderCellDef> Contact Number</th>
        <td mat-cell *matCellDef="let element"> {{element.contactNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="contactEmail">
        <th mat-header-cell *matHeaderCellDef> Email Address</th>
        <td mat-cell *matCellDef="let element"> {{element.contactEmail}} </td>
      </ng-container>

      <ng-container matColumnDef="shouldReceiveReports">
        <th mat-header-cell *matHeaderCellDef> Receive Reports</th>
        <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.shouldReceiveReports" [disabled]="true"></mat-checkbox></td>
      </ng-container>

      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon>chevron_right</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          (click)="selectArea(areas[i])"></tr>
    </table>
  </div>

</div>
<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
