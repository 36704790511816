import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Site } from '../../models/Site';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from '../../services/storage-service';
import { ComponentBaseComponent } from '../../component-base';

@Component({
    selector: 'app-siteextradetail',
    templateUrl: './siteextradetail.component.html',
    styleUrls: ['./siteextradetail.component.css']
})
export class SiteextradetailComponent extends ComponentBaseComponent implements OnInit, AfterViewInit {

    PREVIOUS_PAGE = 'company/sites';

    @ViewChild('searchAddress', { static: false })
    public addressSearchElementRef: ElementRef;

    @ViewChild('sideBarLeftSecondary') sideBarLeftSecondary: ElementRef;
    @ViewChild('sideBarOverlay') sideBarOverlay: ElementRef;

    private id: number;
    currentSite: Site;
    isBusy: boolean = false;
    constructor(
        protected router: Router,
        protected matSnackBar: MatSnackBar,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        protected storageService: StorageService,
    ) {
        super(matSnackBar, dialog, router, storageService);
    }

    ngOnInit(): void {
        this.id = Number(this.storageService.getCurrentSite().id);
        this.currentSite = this.storageService.getCurrentSite();
        this.navigateToSiteDetail();
    }

    ngAfterViewInit(): void {
        //  
    }

    navigateToChat() {
        this.router.navigate(['company/site/xtra/chat']);
    }

    navigateToSchedule() {
        this.router.navigate(['company/site/xtra/schedule']);
    }

    navigateToSiteDetail() {
        this.router.navigate(['company/site/xtra/details']);
    }
    navigateToRoute() {
        this.router.navigate(['company/site/xtra/routes']);
    }
    navigateToDevices() {
        this.router.navigate(['company/site/xtra/devices']);
    }
    navigateToTags() {
        this.router.navigate(['company/site/xtra/tags']);
    }
    navigateToTracking() {
        this.router.navigate(['company/site/xtra/tracking']);
    }
}
