<div class="chat-header row">
  <mat-icon style="color: #509F74;margin-right: 3px;">business</mat-icon>
  <h3>{{siteName}}</h3>
  <div style="margin-left: auto;">
    <button mat-flat-button *ngIf="!isScrollAtBottom" class="btn btn-primary btn-icon mr-2 rounded"
            style="background: #003473;height: 90%; margin-top: auto;margin-bottom: auto"
            (click)="scrollToBottom()">
      <mat-icon>arrow_downward</mat-icon>
      <span class="alert-count-inner">{{unseenMessagesCount}}</span>
    </button>
    <button mat-flat-button class="btn btn-icon mr-2 rounded btn-refresh-chats"
            style="background: #003473;height: 90%; margin-top: auto;margin-bottom: auto;margin-left: auto;"
            (click)="reload(false)">
      <mat-icon class="icon" style="margin: 0;padding-bottom: 30px;" matTooltip="Refresh">refresh</mat-icon> Refresh
    </button>
  </div>
</div>
<div class="chat-form">
  <div class="chat-body" (scroll)="onScroll($event)" #scrollframe>
    <div class="card-header" *ngIf="isPriorMessages" style="text-align: center;">{{messageListStatus}}</div>

    <div class="previous-chats-icon-wrapper" *ngIf="isPriorMessages">
      <mat-icon class="previous-messages-icon" color="primary" matTooltip="Load Previous Messages"
                (click)="reload(true)">arrow_circle_up
      </mat-icon>
    </div>

    <app-message-item *ngFor="let message of messageList;trackBy: trackByFn" [message]="message" [currentUserId]="currentEmployee.userId"
                      (click)="ifImageShowPreview(message)" style="margin-bottom: 4px" #item></app-message-item>
  </div>

  <div class="chat-footer">
    <input class="form-control form-control-rounded" placeholder="Type message here ..." [(ngModel)]="message" (keyup.enter)="sendTextMessage()"
             (input)="typing($event)"/>
    <div class="input-button">
      <button mat-flat-button class="btn btn-primary btn-icon mr-2 rounded" (click)="sendTextMessage()">
        <mat-icon>send</mat-icon> Send Message
      </button>
    </div>
    <div class="input-button">
      <button mat-flat-button class="btn btn-primary btn-icon mr-2 rounded" (click)="openDialog(false)">
        <mat-icon>add_a_photo</mat-icon> Attach Image
      </button>
    </div>
  </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="!hasSiteDataIn"></mat-spinner>
