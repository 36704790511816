<breadcrumb previousPageName="Areas"
            [previousPageLink]="PREVIOUS_PAGE"
            [currentPageName]="(isNew) ? 'New Area' : this.areaForm.value.name"
            [shouldShow]=true>
</breadcrumb>

<div class="card">
  <div class="card-body">
    <form [formGroup]="areaForm" class="column">
      <div class="form-group">
        <label for="name">Name</label>
        <input class="form-control form-control-rounded" formControlName="name" placeholder="Name" id="name">
      </div>

      <div class="form-group">
        <label for="contactPerson">Contact Person</label>
        <input class="form-control form-control-rounded" formControlName="contactPerson" placeholder="Contact Person" id="contactPerson">
      </div>

      <div class="form-group">
        <label for="contactNumber">Contact Number</label>
        <input class="form-control form-control-rounded" formControlName="contactNumber" placeholder="Contact Number" id="contactNumber">
      </div>

      <div class="form-group">
        <label for="contactEmail">Email Address</label>
        <input class="form-control form-control-rounded" formControlName="contactEmail" placeholder="Email Address" id="contactEmail">
      </div>

      <div class="form-group" style="margin-top: 15px;">
        <mat-checkbox formControlName="shouldReceiveReports">Receive Reports</mat-checkbox>
      </div>
    </form>

    <ng-container *ngIf="loggedInEmployee.role !== ROLE_TYPES.CONTROLLER">
      <hr>
      <div class="row">
        <button class="btn btn-primary btn-icon ml-0" (click)="upsertArea()">
          <span class="ul-btn__icon"><mat-icon>check</mat-icon></span>
          <span class="ul-btn__text">Save Area</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
