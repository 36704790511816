<div class="card column">
  <div class="card-header" [class]="headerStyleClass">
    <span>{{eventTypeTitle}}</span>
    <span>{{alertTime}}</span>
  </div>

  <div class="card-body">
    <p id="alert-description">{{getAlertDescription()}}</p>

    <hr style="margin-top: 1rem">

    <mat-accordion [multi]="true">
      <mat-expansion-panel class="alert-extra-detail-panel" *ngIf="alert.site" expanded="true">
        <mat-expansion-panel-header>{{'Site - ' + alert.site.name}}</mat-expansion-panel-header>

        <div class="panel-content">
          <p>{{getSitePanelContent()}}</p>

          <div>
            <p style="font-weight: 500">Contacts:</p>

            <div class="row" style="justify-content: space-between"><span style="flex: 1">Name</span><span style="flex: 1">Contact Number</span><span style="flex: 1">Email Address</span></div>
            <hr style="margin-top: 5px; margin-bottom: 5px">
            <div *ngFor="let contact of alert.site.contacts" class="row" style="justify-content: space-between">
              <span style="flex: 1">{{contact.name}}</span>
              <span style="flex: 1">{{contact.contactNumber}}</span>
              <span style="flex: 1">{{contact.emailAddress}}</span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="alert-extra-detail-panel" *ngIf="alert.imageUrl">
        <mat-expansion-panel-header>Clock in picture</mat-expansion-panel-header>

        <div class="panel-content">
          <img  class = "image-view" *ngIf="alert.imageUrl" [src]="alert.imageUrl" alt="image"/>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="alert-extra-detail-panel" *ngIf="alert.device">
        <mat-expansion-panel-header>{{'Device - ' + alert.device.deviceName + ' - ' + alert.device.contactNumber}}</mat-expansion-panel-header>

        <div class="panel-content">
          <p>{{getDevicePanelContent()}}</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel  class="alert-extra-detail-panel" *ngIf="alert.employee">
        <mat-expansion-panel-header>{{'Employee - ' + alert.employee.name + ' ' + alert.employee.surname}}</mat-expansion-panel-header>

        <div class="panel-content">
          <p>{{getEmployeePanelContent()}}</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="alert-extra-detail-panel" *ngIf="alert.routeTaken">
        <mat-expansion-panel-header>{{getRouteTakenPanelTitle()}}</mat-expansion-panel-header>

        <div class="panel-content">
          <p>{{getRouteTakenPanelContent()}}</p>
        </div>
      </mat-expansion-panel>

    </mat-accordion>

    <hr>
    <div class="column">
      <div class="column">
        <label for="responseMessage">Response Message</label>
        <textarea class="form-control" id="responseMessage" placeholder="Explain what you did to resolve the alert" rows="5"
                  [(ngModel)]="responseMessage" ></textarea>
      </div>
      <hr>
      <div class="row">
        <button class="btn btn-primary mr-3" (click)="sendResponseMessage()">Send</button>
        <button class="btn btn-gray-400" mat-dialog-close>Cancel</button>
      </div>
    </div>
  </div>

</div>
