import {Injectable} from '@angular/core';
import {CommonService} from './commonService';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }

  getSiteReports(siteId, startDate, endDate) {
    return this.commonService.getData(`api/v1/report/site/${siteId}?startDate=${startDate}&endDate=${endDate}`);
  }

  getAreaReports(areaId, startDate, endDate) {
    return this.commonService.getData(`api/v1/report/area/${areaId}?startDate=${startDate}&endDate=${endDate}`);
  }

  generateAndEmailAreaReport(startDate, endDate, company, areaId) {
    const date = {
      startDate,
      endDate,
      company,
      areaId
    };
    return this.commonService.postReportUrl(`api/v1/report/area`, date);
  }

  generateAndEmailSiteReport(startDate, endDate, company, siteId) {
    const date = {
      startDate,
      endDate,
      company,
      siteId
    };
    return this.commonService.postReportUrl(`api/v1/report/site`, date);
  }

  generateGeneralCsvReport(siteId) {
    return this.commonService.getReportUrl(`api/v1/report/csv/general/${siteId}`);
  }

  generateActivityCsvReport(siteId, startDate, endDate) {
    return this.commonService.getReportUrl(`api/v1/report/csv/activity/${siteId}?startDate=${startDate}&endDate=${endDate}`);
  }

  getCompanyTagsScannedData(companyId, startDate, endDate){
    return this.commonService.getReportUrl(`api/v1/report/dashboard/company/tagsScanned/${companyId}?startDate=${startDate}&endDate=${endDate}`);
  }

  getCompanyPatrolsData(companyId, startDate, endDate, status) {
    return this.commonService.getReportUrl(`api/v1/report/dashboard/company/patrols/${companyId}?startDate=${startDate}&endDate=${endDate}&status=${status}`);
  }

  getCompanyAlertCounts(companyId, startDate, endDate){
    return this.commonService.getReportUrl(`api/v1/report/dashboard/company/alerts/${companyId}?startDate=${startDate}&endDate=${endDate}`);
  }

  getSitesTagsScannedData(companyId, startDate, endDate) {
    return this.commonService.getReportUrl(`api/v1/report/dashboard/sites/tagsScanned/${companyId}?startDate=${startDate}&endDate=${endDate}`);
  }
}
