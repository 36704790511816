<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col" style="height: fit-content; margin: auto">
        <span>Device route history for {{currentSite.name}}</span>
        <span style="font-weight: bold; display: block">{{getDateRangeString()}}</span>
      </div>
      <div class="col" style="height: fit-content; margin: auto">
        <div class="input-group float-sm-right" style="display: flex; height: fit-content; margin: auto; width: 175px">
          <div class="input-group-prepend">
            <span class="input-group-text" style="padding: 0"><mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle></span>
          </div>
          <input class="form-control" style="height: auto" [matDatepicker]="picker" [(ngModel)]="mapDate" (dateChange)="dateSetRange()">
        </div>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
  </div>
  <div class="card-body row">
    <div class="col-9">
      <agm-map
        [latitude]="currentSite?.latitude"
        [longitude]="currentSite?.longitude"
        [scrollwheel]="scrollWheel"
        [zoom]="routesMapZoom"
        (zoomChange)="routesMapZoomChange($event)"
      >
        <agm-marker
          *ngFor="let tag of routeTags"
          [latitude]="tag.latitude"
          [longitude]="tag.longitude">
          <agm-info-window>
            <h3>{{ tag.name }}</h3>
          </agm-info-window>
        </agm-marker>
        <agm-polyline *ngFor="let routeCoords of routesCoords;let i = index;" [strokeColor]="routeCoords.color">
          <agm-polyline-point *ngFor="let coords of routeCoords.coordinates" [latitude]="coords.lat"
                              [longitude]="coords.lon">
          </agm-polyline-point>
        </agm-polyline>
        <agm-polyline *ngFor="let device of selectedDevicesRoutes;" [strokeColor]="device.color">
          <agm-polyline-point *ngFor="let location of device.locations" [latitude]="location.latitude"
                              [longitude]="location.longitude">
          </agm-polyline-point>
        </agm-polyline>
        <agm-circle
          *ngFor="let device of selectedDevicesGpsLocations"
          [radius]="device.radius"
          [fillColor]="device.fillColor"
          [strokeColor]="device.strokeColor"
          [strokeWeight]="device.strokeWeight"
          [latitude]="device.coordinates.lat"
          [longitude]="device.coordinates.lng"
        >
          <agm-info-window>
            <h3>{{ device.sentAt }}</h3>
          </agm-info-window>
        </agm-circle>
      </agm-map>
    </div>
    <div class="col-3" style="padding-right: 0">
      <div class="row">
        <div class="col-12">
          <h2>Routes</h2>
        </div>
        <div class="col-12">
          <div class="cardsListWrap">
            <div *ngFor="let route of routes;">
              <div class="card row map-entity-card" [className]="route.selected ? 'selectedRoute':''"
                   (click)="getTagsToRouteAndDraw(route.id, route.color);">
                <div class="card-body col-3">
                  <svg height="50" width="50">
                    <circle cx="25" cy="25" r="20" stroke="black" stroke-width="1" [style.fill]="route.color"/>
                  </svg>
                </div>
                <div class="card-body col-9 map-entity-card-text">
                  <span>{{ route.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h2>Devices</h2>
        </div>
        <div class="col-12">
          <div class="cardsListWrap">
            <div *ngFor="let device of devices">
              <div class="card row map-entity-card" [className]="device.selected ? 'selectedDevice':''"
                   (click)="fetchDeviceLocations(device)">
                <div class="card-body col-3">
                  <svg height="50" width="50">
                    <circle cx="25" cy="25" r="20" stroke="black" stroke-width="1" [style.fill]="device.color"/>
                  </svg>
                </div>
                <div class="card-body col-9 map-entity-card-text">
                  <span>{{ device.deviceName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
