import {Injectable} from '@angular/core';
import {CommonService} from "./commonService";

@Injectable({
  providedIn: 'root'
})

export class DeviceService {

  constructor(
    private commonService: CommonService
  ) {
  }

  getAllDevices(deviceId) {
    return this.commonService.getData('/api/v1/device', deviceId)
  }

  getAllDevicesBySite(siteId) {
    return this.commonService.getData(`/api/v1/device/bySite`, siteId)
  }

  upsertDevice(device) {
    return this.commonService.postData('/api/v1/device', device)
  }

  getDeviceInstallInfoByAppInstanceId(appInstanceId) {
    return this.commonService.getData('/api/v1/device/installInfo/' + appInstanceId);
  }

  getDeviceLastLocation(deviceId) {
    return this.commonService.getData('/api/v1/device/' + deviceId + '/lastLocation');
  }

  getDeviceBattery(serialNumber) {
    return this.commonService.getData(`/api/v1/device/${serialNumber}/battery`, '')
  }

  getDeviceLocations(serialNumber) {
    return this.commonService.getData(`/api/v1/device/${serialNumber}/locations`, '')
  }

  rebootDevice(serialNumber) {
    return this.commonService.getData(`/api/v1/device/${serialNumber}/reboot`, '')
  }

  lockDevice(serialNumber) {
    return this.commonService.getData(`/api/v1/device/${serialNumber}/lock`, '')
  }

  unlockDevice(serialNumber) {
    return this.commonService.getData(`/api/v1/device/${serialNumber}/unlock`, '')
  }

  getScaleFusionStatus(serialNumber) {
    return this.commonService.getData(`/api/v1/device/${serialNumber}/scaleFusion/isRegistered`);
  }

  deleteDevice(id) {
    return this.commonService.postData('api/v1/device/removeDevice', {id});
  }

  getDeviceLocationsByDate(deviceId, date) {
    return this.commonService.getData(`/api/v1/device/${deviceId}/locations/${date}`, '');
  }
}
