<div style="margin-top: 0.5rem;">
  <div *ngIf="isMyMessage(message); else messageItemOther" class="message-item-self">
    <div class="header-self">
      <h5 class="header-name">You &bull;</h5>
      <h5 class="footer-date"> {{formatDate(message.createdAt)}}</h5>
    </div>
    <div class="message-block">
      <p class="message" *ngIf="!message.imageUrl">{{message.message}}</p>
      <img class="image-view" *ngIf="message.imageUrl" [src]="message.imageUrl" alt="image"/>
    </div>
  </div>

  <ng-template #messageItemOther>
    <div class="message-item-other">
      <div class="header-other">
        <h5 class="header-name" >{{message.senderName}} &bull;</h5>
        <h5 class="footer-date"> {{formatDate(message.createdAt)}}</h5>
      </div>
      <div class="message-block">
        <p class="message" *ngIf="!message.imageUrl">{{message.message}}</p>
        <img class="image-view" *ngIf="message.imageUrl" [src]="message.imageUrl" alt="image"/>
      </div>
    </div>
  </ng-template>
</div>
