import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, OnDestroy} from '@angular/core';
import {SocketService} from '../../services/socketService';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {ALERT_COLOR_CLASSIFICATIONS, EVENT_TOPICS, EVENT_TYPES, PATROL_ALERTS} from '../../enums/EventTypes';
import {PanicAlert} from '../../models/PanicAlert';
import {RouteTagScan} from '../../models/RouteTagScan';
import {EventService} from '../../services/event.service';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Attendance} from '../../models/Attendance';
import {AlertDetailsComponent} from '../alert-details/alert-details.component';
import {StorageService} from '../../services/storage-service';
import {AlertAlarmResponse} from '../../models/AlertAlarmResponse';
import {getAlertColorClassification, getAlertEpochDateTime} from '../../logic/AlertLogic';
import {ROLE_TYPES} from '../../enums/RoleTypes';
import {PatrolScanAlert} from '../../models/PatrolScanAlert';
import {RouteTaken} from '../../models/RouteTaken';
import {DeviceStatusAlert} from '../../models/DeviceStatusAlert';
import {UnallocatedRouteTagScan} from "../../models/UnallocatedRouteTagScan";
@Component({
  selector: 'app-alerts',
  templateUrl: './alerts-summary.component.html',
  styleUrls: ['./alerts-summary.component.scss']
})
export class AlertsSummaryComponent extends ComponentBaseComponent implements OnInit, AfterViewInit {
  isBusy = false;

  ALERT_COLOR_CLASSIFICATIONS = ALERT_COLOR_CLASSIFICATIONS;

  redAlerts = [];
  greenAlerts = [];
  yellowAlerts = [];
  miscAlerts = [];

  public isRedScrollAtBottom: boolean = true;
  public isGreenScrollAtBottom: boolean = true;
  public isYellowScrollAtBottom: boolean = true;
  public isMiscScrollAtBottom: boolean = true;

  public redScrollContainer: any;
  public greenScrollContainer: any;
  public yellowScrollContainer:any;
  public miscScrollContainer: any;


  @ViewChild('greenAlertsTab',{static:false})  greenAlertsTab : ElementRef;
  @ViewChildren('greenItems') greenItems : QueryList<any>

  @ViewChild('miscAlertsTab',{static:false})  miscAlertsTab : ElementRef;
  @ViewChildren('miscItems') miscItems : QueryList<any>

  @ViewChild('redAlertsTab',{static:false})  redAlertsTab : ElementRef;
  @ViewChildren('redItems') redItems : QueryList<any>

  @ViewChild('yellowAlertsTab',{static:false})  yellowAlertsTab : ElementRef;
  @ViewChildren('yellowItems') yellowItems : QueryList<any>

  constructor(
    private socketService: SocketService,
    private eventService: EventService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router,
    protected storageService: StorageService,
  ) {
    super(snackBar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.getCurrentDaysAlerts();
    this.listenForEvents();
  }

  trackByFn(index, item) {
    let thisitem = (item.hasOwnProperty('eventGuid')?  item.eventGuid : item.completedEventGuid);
    return thisitem;
  }

  ngAfterViewInit(): void {
    this.greenScrollContainer = this.greenAlertsTab.nativeElement;
    this.miscScrollContainer = this.miscAlertsTab.nativeElement;
    this.yellowScrollContainer = this.yellowAlertsTab.nativeElement;
    this.redScrollContainer = this.redAlertsTab.nativeElement;

    this.greenItems.changes.subscribe(_ => {
      if (this.isGreenScrollAtBottom) {
        this.scrollToBottom(ALERT_COLOR_CLASSIFICATIONS.GREEN,'greenScrollContainer');
      }
    });

    this.miscItems.changes.subscribe(_ => {
      if (this.isMiscScrollAtBottom) {
        this.scrollToBottom(ALERT_COLOR_CLASSIFICATIONS.MISC,'miscScrollContainer');
      }
    });

    this.yellowItems.changes.subscribe(_ => {
      if (this.isYellowScrollAtBottom) {
        this.scrollToBottom(ALERT_COLOR_CLASSIFICATIONS.YELLOW,'yellowScrollContainer');
      }
    });

    this.redItems.changes.subscribe(_ => {
      if (this.isRedScrollAtBottom) {
        this.scrollToBottom(ALERT_COLOR_CLASSIFICATIONS.RED,'redScrollContainer');
      }
    });

    this.scrollAllTabsToBottom();
  }

  public scrollToBottom(color,containerName) {
    this[containerName].scroll({
      //top: this[containerName].scrollHeight,
      left: 0,
      behavior: 'smooth'
    });

    if(color === ALERT_COLOR_CLASSIFICATIONS.GREEN){
      this.isGreenScrollAtBottom = true;
    } else if(color === ALERT_COLOR_CLASSIFICATIONS.MISC){
      this.isMiscScrollAtBottom = true;
    } else if(color === ALERT_COLOR_CLASSIFICATIONS.YELLOW){
      this.isYellowScrollAtBottom = true;
    } else if(color === ALERT_COLOR_CLASSIFICATIONS.RED){
      this.isRedScrollAtBottom = true;
    }
  }

  listenForEvents() {
    // NB: Don't put 'off' the socket listeners on this component

    this.socketService.getSocket().on(`${EVENT_TOPICS.CRITICAL}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + EVENT_TOPICS.CRITICAL);
        return;
      }
      this.parseAlertData([data]);
    });

    this.socketService.getSocket().on(`${EVENT_TOPICS.PATROL}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + EVENT_TOPICS.PATROL);
        return;
      }
      if (data.type === EVENT_TYPES.PATROL_ENDED) {
        switch (data.alert) {
          case PATROL_ALERTS.FAILED:
          case PATROL_ALERTS.INCOMPLETE:
            
          // case PATROL_ALERTS.FAILED_TO_START:
             {
            let patrolEnded = data.routeTaken;
            patrolEnded.type = data.type;
            patrolEnded.completedEventGuid = data.eventGuid;
            patrolEnded.status = data.alert;
            patrolEnded.completedEventTime = data.receivedByServiceBusAt;
            patrolEnded.site = data.site;
            this.parseAlertData([patrolEnded]);
            break;
          }
          default:
            return;
        }
      } else {
        this.parseAlertData([data]);
      }
    });

    this.socketService.getSocket().on(`${EVENT_TOPICS.ATTENDANCE}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + EVENT_TOPICS.ATTENDANCE);
        return;
      }
      this.parseAlertData([data]);
    });

    this.socketService.getSocket().on(`${EVENT_TOPICS.CHAT}.${EVENT_TYPES.PLEASE_CALL_ME}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + `${EVENT_TOPICS.CHAT}.${EVENT_TYPES.PLEASE_CALL_ME}`);
        return;
      }
      this.parseAlertData([data]);
    });
    this.socketService.getSocket().on(`${EVENT_TOPICS.DEVICE_STATUS}.${EVENT_TYPES.DEVICE_OFFLINE}`, (data) => {
      if (!data) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + `${EVENT_TOPICS.DEVICE_STATUS}.${EVENT_TYPES.DEVICE_OFFLINE}`);
        return;
      }
      this.parseAlertData([data]);
    });
    this.socketService.getSocket().on(`${EVENT_TOPICS.DEVICE_STATUS}.${EVENT_TYPES.DEVICE_BATTERY_LOW}`, (data) => {
      this.parseAlertData([data]);
    });
  }

  getCurrentDaysAlerts() {
    this.isBusy = true;

    let startDate = new Date();
    if (startDate.getHours() < 6) {
      startDate.setDate(startDate.getDate() - 1);
    }
    startDate.setHours(6, 0, 0, 0);

    let endDate = new Date(startDate.getTime());
    endDate.setDate(startDate.getDate() + 1);

    const loggedInEmployee = this.storageService.getLoggedInEmployee();
    let companyId;
    if (loggedInEmployee.role === ROLE_TYPES.SYS_ADMIN) {
      const selectedCompany = this.storageService.getSelectedCompany();

      companyId = selectedCompany.id;
    } else {
      companyId = loggedInEmployee.company.id;
    }

    this.eventService.getEventsByDateRange(startDate.getTime() / 1000, endDate.getTime() / 1000, companyId).subscribe(result => {
      this.parseAlertData(result.body as Array<any>);
      this.isBusy = false;
    }, error => {
      this.handleError(error);
    });
  }

  parseAlertData(alertData: Array<any>) {
    let jsonConvert = new JsonConvert();
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    for (let data of alertData) {
      const eventType = data.type;
      let event;

      switch (eventType) {
        case EVENT_TYPES.ALERT_ALARM_RESPONSE: {
          event = jsonConvert.deserializeObject(data, AlertAlarmResponse);
          break;
        }
        case EVENT_TYPES.PANIC: {
          event = jsonConvert.deserializeObject(data, PanicAlert);
          break;
        }
        case EVENT_TYPES.NFC_TAG_SCAN: {
          event = jsonConvert.deserializeObject(data, RouteTagScan);
          break;
        }
        case EVENT_TYPES.NFC_TAG_SCAN_UNALLOCATED: {
          event = jsonConvert.deserializeObject(data, UnallocatedRouteTagScan);
          break;
        }
        case EVENT_TYPES.ATTENDANCE: {
          event = jsonConvert.deserializeObject(data, Attendance);
          break;
        }
        case EVENT_TYPES.PATROL_SCAN_ALERT: {
          event = jsonConvert.deserializeObject(data, PatrolScanAlert);
          if (!event.routeTag) {
            console.error('NO ROUTETAG DATA ON PATROL_SCAN_ALERT');
            //console.error(`DATA: ${JSON.stringify(data)}`);
          }
          break;
        }
        case EVENT_TYPES.PATROL_ENDED: {
          event = jsonConvert.deserializeObject(data, RouteTaken);
          break;
        }
        case EVENT_TYPES.DEVICE_OFFLINE: {
          event = jsonConvert.deserializeObject(data, DeviceStatusAlert);
          break;
        }
        case EVENT_TYPES.DEVICE_BATTERY_LOW: {
          event = jsonConvert.deserializeObject(data, DeviceStatusAlert);
          break;
        }
        default: {
          event = data;
        }
      }

      let alertColorClassification;

      if (event.type === EVENT_TYPES.ALERT_ALARM_RESPONSE) {
        alertColorClassification = getAlertColorClassification(event.respondedToType);

        switch (alertColorClassification) {
          case ALERT_COLOR_CLASSIFICATIONS.GREEN: {
            this.removeAlert(event.respondedToGuid, alertColorClassification);
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.YELLOW: {
            this.removeAlert(event.respondedToGuid, alertColorClassification);
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.RED: {
            this.removeAlert(event.respondedToGuid, alertColorClassification);
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.MISC: {
            this.removeAlert(event.respondedToGuid, alertColorClassification);
            break;
          }
        }
      } else {
        alertColorClassification = getAlertColorClassification(event.type);

        switch (alertColorClassification) {
          case ALERT_COLOR_CLASSIFICATIONS.GREEN: {
            this.greenAlerts.unshift(event);
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.YELLOW: {
            if(event.status !== 'complete'){
              this.yellowAlerts.unshift(event);
            }
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.RED: {
            if((event.latitude == undefined && event.longitude == undefined) || (event.latitude == undefined || event.longitude == undefined)){
              this.redAlerts.unshift(event);
            }
            break;
          }
          case ALERT_COLOR_CLASSIFICATIONS.MISC: {
            this.miscAlerts.unshift(event);
            break;
          }
        }
      }
    }
  }

  scrollAllTabsToBottom(){
    if(this.isGreenScrollAtBottom){
      this.scrollToBottom(ALERT_COLOR_CLASSIFICATIONS.GREEN,'greenScrollContainer');
    } else if (this.isMiscScrollAtBottom){
      this.scrollToBottom(ALERT_COLOR_CLASSIFICATIONS.MISC,'miscScrollContainer');
    } else if(this.isYellowScrollAtBottom){
      this.scrollToBottom(ALERT_COLOR_CLASSIFICATIONS.YELLOW,'yellowScrollContainer');
    } else if(this.isRedScrollAtBottom){
      this.scrollToBottom(ALERT_COLOR_CLASSIFICATIONS.RED,'redScrollContainer');
    }
  }

  openAlertInfoDialog(alert, alertClassification) {
    let dialogRef = this.dialog.open(AlertDetailsComponent, {
      height: 'fit-content',
      width: '600px',
      panelClass: 'dialog-container-hidden',
      data: {
        alert: alert
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.successful) {
        this.showMessage('Response to alert has been sent', MessageType.success);

        this.removeAlert((typeof alert.completedEventGuid !== 'undefined') ? alert.completedEventGuid : alert.eventGuid, alertClassification);
      }
    });
  }

  removeAlert(alertEventGuid, alertClassification) {
    switch (alertClassification) {
      case ALERT_COLOR_CLASSIFICATIONS.GREEN: {
        this.greenAlerts = this.greenAlerts.filter(currentAlert => currentAlert.completedEventGuid !== alertEventGuid).filter(currentAlert => currentAlert.eventGuid !== alertEventGuid);
        break;
      }
      case ALERT_COLOR_CLASSIFICATIONS.YELLOW: {
        this.yellowAlerts = this.yellowAlerts.filter(currentAlert => currentAlert.completedEventGuid !== alertEventGuid).filter(currentAlert => currentAlert.eventGuid !== alertEventGuid);
        break;
      }
      case ALERT_COLOR_CLASSIFICATIONS.RED: {
        this.redAlerts = this.redAlerts.filter(currentAlert => currentAlert.completedEventGuid !== alertEventGuid ).filter(currentAlert => currentAlert.eventGuid !== alertEventGuid).filter(currentAllert => typeof currentAllert.longitude != "undefined");
        break;
      }
      case ALERT_COLOR_CLASSIFICATIONS.MISC: {
        this.miscAlerts = this.miscAlerts.filter(currentAlert => currentAlert.completedEventGuid !== alertEventGuid).filter(currentAlert => currentAlert.eventGuid !== alertEventGuid);
        break;
      }
    }
  }

  public onAlertScroll(event, alertColor) {
    let scrollView = event.target;
    let isScrollAtBottom = Math.ceil(scrollView.scrollTop) === scrollView.scrollHeight - scrollView.clientHeight;

    if(alertColor === ALERT_COLOR_CLASSIFICATIONS.GREEN){
      this.isGreenScrollAtBottom = isScrollAtBottom;
    } else if(alertColor === ALERT_COLOR_CLASSIFICATIONS.MISC){
      this.isMiscScrollAtBottom = isScrollAtBottom;
    } else if(alertColor === ALERT_COLOR_CLASSIFICATIONS.YELLOW){
      this.isYellowScrollAtBottom = isScrollAtBottom;
    } else if(alertColor === ALERT_COLOR_CLASSIFICATIONS.RED){
      this.isRedScrollAtBottom = isScrollAtBottom;
    }
  }

  sortAllAlertsByDate(){

    //sort green alerts
    this.greenAlerts = this.sortAlerts(this.greenAlerts);

    //sort yellow alerts
    this.yellowAlerts = this.sortAlerts(this.yellowAlerts);

    //sort red alerts
    this.redAlerts = this.sortAlerts(this.redAlerts);

    //sort miscellaneous alerts
    this.miscAlerts = this.sortAlerts(this.miscAlerts);

  }

  sortAlerts(alerts: any[]) {
    const sorter = [];
    const sortedAlerts = [];

    alerts.forEach((alert, i) => {
      sorter.push({
        index: i,
        dateTime: getAlertEpochDateTime(alert)
      });
    });
    sorter.sort((a, b) => (a.dateTime < b.dateTime) ? 1 : -1);

    sorter.forEach((element) => {
      sortedAlerts.push(alerts[element.index]);
    });
    return sortedAlerts;
  }
}
