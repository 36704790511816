import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {isCombinedModifierFlagSet} from "tslint";
import {StorageService} from "../../services/storage-service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  site : boolean;
  alert : boolean;
  chat :boolean;
  employees: boolean;
  reports: boolean;
  currEmployeeRole : string;

  constructor(
    private router: Router,
    private storageService : StorageService
  ) { }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
  }

  sites(){
    this.site = true;
    this.alert = false;
    this.reports = false;
    this.employees = false;
    this.chat = false;
  }
}
