import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage-service';
import { RouteTag } from '../../models/RouteTag';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Site } from '../../models/Site';
import { RouteService } from '../../services/route-service';
import { ComponentBaseComponent, MessageType } from '../../component-base';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JsonConvert, ValueCheckingMode } from "json2typescript";
import { Location } from '@angular/common';

@Component({
  selector: 'app-tag-detail',
  templateUrl: './tag-detail.component.html',
  styleUrls: ['./tag-detail.component.scss']
})
export class TagDetailComponent extends ComponentBaseComponent implements OnInit {
  PREVIOUS_PAGE = '/company/site/xtra/tags';

  isBusy = false;
  isNewTag: boolean;
  site: Site;
  tagId: number;
  tagForm: FormGroup;
  currEmployeeRole: string;
  unallocatedTags: RouteTag[] = [];
  mapCenter: google.maps.LatLngLiteral;
  mapMarker;

  constructor(
    private location: Location,
    protected storageService: StorageService,
    private routeService: RouteService,
    private formBuilder: FormBuilder,
    protected snackBar: MatSnackBar,
    protected dialog: MatDialog,
    protected router: Router
  ) {
    super(snackBar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.prepareForm();
    this.getSite();
    this.getTag();
    this.getMapCenter();
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
  }

  prepareForm() {
    this.tagForm = this.formBuilder.group({
      name: ['', Validators.required],
      serialNumber: ['', Validators.required],
      longitude: [''],
      latitude: [''],
      isPanic: [false, Validators.required]
    });
  }

  populateForm(tag: RouteTag) {
    this.tagForm.patchValue({
      name: tag.name == undefined? this.tagForm.controls['name'].value: tag.name,
      serialNumber: tag.serialNumber,
      longitude: tag.longitude,
      latitude: tag.latitude,
      isPanic : tag.isPanic
    });
  }

  formToObject() {
    return {
      name: this.tagForm.controls['name'].value,
      serialNumber: this.tagForm.controls['serialNumber'].value,
      longitude: this.tagForm.controls['longitude'].value,
      latitude: this.tagForm.controls['latitude'].value,
      isPanic: this.tagForm.value.isPanic,
    }
  }

  formToTag() {
    const formObject = this.formToObject();

    let tag = new RouteTag();
    tag.name = formObject.name;
    tag.serialNumber = formObject.serialNumber;
    tag.longitude = formObject.longitude;
    tag.latitude = formObject.latitude;
    tag.id = this.tagId;
    tag.siteId = this.site.id;
    tag.isPanic = formObject.isPanic;

    return tag;
  }

  getSite() {
    this.site = this.storageService.getCurrentSite();
  }

  getTag() {
    this.isBusy = true;
    const selectedTag = this.storageService.getSelectedTag();

    if (selectedTag && selectedTag.id) {
      this.isNewTag = false;

      this.tagId = selectedTag.id;
      this.populateForm(selectedTag);
    } else {
      this.isNewTag = true;

      let newTag = new RouteTag();
      newTag.latitude = this.site.latitude;
      newTag.longitude = this.site.longitude;

      this.populateForm(newTag);
      this.getUnallocatedTags();

    }

    this.setMapMarker();

    this.isBusy = false;
  }

  setMapMarker() {
    const tag = this.formToTag();

    this.mapMarker = {
      position: {
        lat: tag.latitude,
        lng: tag.longitude
      },
      options: { draggable: true },
    }
  }

  getTagName() {
    return (this.isNewTag) ? 'New Tag' : this.tagForm.value.name;
  }

  getMapCenter() {
    if (!this.isNewTag || this.selectedUnallocatedTag()) {
      this.mapCenter = {
        lat: Number(this.tagForm.controls['latitude'].value),
        lng: Number(this.tagForm.controls['longitude'].value)
      };
    } else {
      if (this.site && this.site.latitude & this.site.longitude) {
        this.mapCenter = {
          lat: Number(this.site.latitude),
          lng: Number(this.site.longitude)
        };
      }
    }
  }

  upsertTag() {
    const tag = this.formToTag();
    if (!tag.serialNumber) {
      this.showMessage("Serial number can't be empty", MessageType.warning);
    }
    else {
      const actionText = (this.tagId) ? 'save your changes' : 'create a new tag';
      let dialogRef = this.showConfirmationDialog(actionText);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isBusy = true;
          this.selectedUnallocatedTag();
          const tag = this.formToTag();
  
          this.routeService.upsertRouteTag(tag).subscribe(result => {
            console.log('Previous location: ' + this.PREVIOUS_PAGE);
            this.location.back();
            this.showMessage('Tag successfully created/updated.', MessageType.success);
            this.isBusy = false;
          }, error => {
            this.isBusy = false;
            this.handleError(error);
          });
        }
      });
    }
  }

  updateTagLocation(event) {
    const newLatitude = event.latLng.lat();
    const newLongitude = event.latLng.lng();

    this.tagForm.patchValue({
      longitude: newLongitude,
      latitude: newLatitude
    });
  }

  deleteTag() {
    const selectedTag = this.storageService.getSelectedTag();
    let dialogRef = this.showConfirmationDialog(`delete tag ${selectedTag.name}`, null, 'delete');

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isBusy = true;
        this.routeService.deleteAllRouteTagsById(selectedTag.id).subscribe(result => {
          this.router.navigate([this.PREVIOUS_PAGE]);
          this.showMessage('Tag successfully deleted.', MessageType.success);
          this.isBusy = false;


        }, error => {
          this.isBusy = false;
          this.handleError(error);
        });
      }
    });
  }

  getUnallocatedTags() {
    this.routeService.getAllUnallocatedBySiteId(this.site.id).subscribe((result) => {
      let jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.unallocatedTags = jsonConvert.deserializeArray(result.body as any, RouteTag);
    })
  }

  unallocatedTagSelected(e) {
    const serialNumber = e.option.value;
    const routeTag = this.unallocatedTags.filter((tag) => tag.serialNumber === serialNumber)[0];
    this.populateForm(routeTag);
    this.getMapCenter();
    this.setMapMarker();
  }

  selectedUnallocatedTag() {
    const formTag = this.formToTag();
    const routeTags = this.unallocatedTags.filter((tag) => tag.serialNumber === formTag.serialNumber);
    if (routeTags.length > 0) {
      this.tagId = routeTags[0].id;
      return true;
    }
    return false;
  }
}
