import {Injectable} from '@angular/core';
import {CommonService} from './commonService';
import {RouteTag} from '../models/RouteTag';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
    private commonService: CommonService
  ) {
  }


  // RouteTag
  upsertRouteTag(routeTag: RouteTag) {
    return this.commonService.postData('/api/v1/routeTag', routeTag);
  }

  getAllRouteTagsBySiteId(id) {
    return this.commonService.getData('/api/v1/routeTag', id);
  }

  getAllRouteTagsByRouteId(id) {
    return this.commonService.getData(`/api/v1/tagToRoute/`, id);
  }

  deleteAllRouteTagsById(id){
    return  this.commonService.postData('/api/v1/routeTag/removeTag', {id})
  }

  getAllUnallocatedBySiteId(siteId) {
    return this.commonService.getData(`/api/v1/routeTag/getAllUnallocated/${siteId}`);
  }

  // Route
  upsertRoute(route) {
    return this.commonService.postData(`/api/v1/route`, route);
  }

  getAllRoutesBySiteId(id) {
    return this.commonService.getData(`/api/v1/route/bySite/`, id);
  }


  // TagToRoute

  getAllTagsToRouteByRouteId(id) {
    return this.commonService.getData('/api/v1/tagToRoute', id);
  }

  upsertTagToRoute(tagToRoute) {
    return this.commonService.postData(`/api/v1/tagToRoute`, tagToRoute);
  }

  deleteTagToRoute(id: number) {
    return this.commonService.postData('/api/v1/tagToRoute/removeTagToRoute', {id});
  }

  deleteRoute(id: number) {
    return this.commonService.postData('/api/v1/route/removeRoute', {id});
  }

  //RouteTagEnroll
  getAllEnrollingTags(){
    return this.commonService.getData('/api/v1/routeTag/TagEnrollment/GetEnrollingTags');
  }

  declineTagEnrollment(serialNumber){
    return this.commonService.postData(`/api/v1/routeTag/TagEnrollment/DeclineTag/${serialNumber}`);
  }

  acceptTagEnrollment(serialNumber){
    return this.commonService.postData(`/api/v1/routeTag/TagEnrollment/AcceptTag/${serialNumber}`);
  }
}
