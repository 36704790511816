import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Site} from '../../models/Site';
import {ReportService} from '../../services/report.service';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {StorageService} from '../../services/storage-service';
import * as moment from 'moment';

@Component({
  selector: 'app-reports-csv',
  templateUrl: './reports-csv.component.html',
  styleUrls: ['./reports-csv.component.scss']
})
export class ReportsCsvComponent extends ComponentBaseComponent implements OnInit {
  UTC_SHIFT_START_HOUR = 4;

  @Input() sites: Site[];
  @Output() downloadingReport = new EventEmitter<boolean>();

  selectedSite: Site;
  startDate;
  endDate;

  constructor(
    private reportService: ReportService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    public router: Router,
    public storageService: StorageService
  ) {
    super(snackbar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.resetDate();
  }

  resetDate() {
    let today = moment(new Date(`${moment(new Date()).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`));

    this.endDate = today.toDate();

    today.subtract(1, 'days');

    this.startDate = today.toDate();
  }

  validateDateRange(startDate, endDate): {isValid: boolean, message: string} {
    let startDateEpoch = startDate.getTime();
    let endDateEpoch = endDate.getTime();

    if (startDateEpoch === endDateEpoch) {
      return {isValid: false, message: 'Start and end date can\'t be the same.'}
    } else if (startDateEpoch > endDateEpoch) {
      return {isValid: false, message: 'Start date can\'t be greater than the end date.'}
    }

    return {isValid: true, message: null}
  }

  getShiftStartTimeString(): string {
    let date = moment(new Date(`1970-01-01T0${this.UTC_SHIFT_START_HOUR}:00:00Z`));

    return date.format('HH:mm');
  }

  downloadGeneralReport() {
    this.downloadingReport.emit(true);

    this.reportService.generateGeneralCsvReport(this.selectedSite.id).subscribe(result => {
      let responseBody = (result as any).body;

      let devicesCsv = responseBody.devicesCsv;
      let tagsCsv = responseBody.tagsCsv;
      let routesCsv = responseBody.routesCsv;
      let schedulesCsv = responseBody.schedulesCsv;

      let todayString = moment(new Date()).format('YYYY-MM-DD');

      this.downloadCsv(devicesCsv, this.selectedSite.name + ' Devices ' + todayString);
      this.downloadCsv(tagsCsv, this.selectedSite.name + ' Tags ' + todayString);
      this.downloadCsv(routesCsv, this.selectedSite.name + ' Routes ' + todayString);
      this.downloadCsv(schedulesCsv, this.selectedSite.name + ' Schedules ' + todayString);

      this.downloadingReport.emit(false);
    }, error => {
      this.handleError(error);
      this.downloadingReport.emit(false);
    });
  }

  downloadActivityReport() {
    let utcStartDate = new Date(`${moment(this.startDate).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`);
    let utcEndDate = new Date(`${moment(this.endDate).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`);

    let validationResponse = this.validateDateRange(utcStartDate, utcEndDate);

    if (validationResponse.isValid) {
      let startDateEpoch = utcStartDate.getTime() / 1000;
      let endDateEpoch = utcEndDate.getTime() / 1000;

      this.downloadingReport.emit(true);
      this.reportService.generateActivityCsvReport(this.selectedSite.id, startDateEpoch, endDateEpoch).subscribe(result => {
        let responseBody = (result as any).body;

        let panicAlertCsv = responseBody.panicAlertCsv;
        let routeTagScanCsv = responseBody.routeTagScanCsv;
        let attendanceCsv = responseBody.attendanceCsv;
        let pleaseCallMeCsv = responseBody.pleaseCallMeCsv;
        let routeTakenCsv = responseBody.routeTakenCsv;
        let deviceStatusCsv = responseBody.deviceStatusCsv;

        let startDateString = moment(startDateEpoch * 1000).format('YYYY-MM-DD');
        let endDateString = moment(endDateEpoch * 1000).format('YYYY-MM-DD');

        this.downloadCsv(panicAlertCsv, this.selectedSite.name + ' Panic Alerts for ' + startDateString + ' to ' + endDateString);
        this.downloadCsv(routeTagScanCsv, this.selectedSite.name + ' Route Tag Scans for ' + startDateString + ' to ' + endDateString);
        this.downloadCsv(attendanceCsv, this.selectedSite.name + ' Attendance Records for ' + startDateString + ' to ' + endDateString);
        this.downloadCsv(pleaseCallMeCsv, this.selectedSite.name + ' Please Call Mes for ' + startDateString + ' to ' + endDateString);
        this.downloadCsv(routeTakenCsv, this.selectedSite.name + ' Patrols for ' + startDateString + ' to ' + endDateString);
        this.downloadCsv(deviceStatusCsv, this.selectedSite.name + ' Device Statuses for ' + startDateString + ' to ' + endDateString);

        this.downloadingReport.emit(false);
      }, error => {
        this.handleError(error);
        this.downloadingReport.emit(false);
      });
    } else {
      this.showMessage(validationResponse.message, MessageType.error);
    }

  }

  downloadCsv(data, fileName) {
    let blob = new Blob([data], {type: 'text/csv;charset=utf-8'});

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
