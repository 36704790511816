<app-reports-csv [sites]="sites" (downloadingReport)="setIsBusy($event)"></app-reports-csv>

<div class="page-title" style="margin-top: 32px">
  <p>Auto Generated PDF Reports</p>
  <hr>
</div>

<div class="card row" id="top-bar">
  <div class="row">
    <mat-radio-group [(ngModel)]="selectedReportType" aria-label="Select a report type" (change)="getReports()">
      <mat-radio-button [value]="REPORT_TYPES.SITE">Site</mat-radio-button>
      <mat-radio-button [value]="REPORT_TYPES.AREA">Area</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="selectedReportType === REPORT_TYPES.SITE">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedSite" (selectionChange)="getReports()">
          <mat-option *ngFor="let site of sites" [value]="site">
            {{site.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="selectedReportType === REPORT_TYPES.AREA">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedArea" (selectionChange)="getReports()">
          <mat-option *ngFor="let area of areas" [value]="area">
            {{area.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>


  <div>
    <label>Period:</label>
    <mat-form-field>
      <mat-select [(ngModel)]="selectedReportPeriod" (selectionChange)="resetDate()">
        <mat-option [value]="REPORT_PERIODS.DAILY">
          {{REPORT_PERIODS.DAILY}}
        </mat-option>
        <mat-option [value]="REPORT_PERIODS.MONTHLY">
          {{REPORT_PERIODS.MONTHLY}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div id="date-picker">
    <button mat-stroked-button (click)="previousTimePeriod()">
      <mat-icon aria-label="Previous">keyboard_arrow_left</mat-icon>
    </button>
    <button mat-stroked-button (click)="resetDate()" matTooltip="Reset Date"><span>{{getDateRange()}}</span></button>
    <button [disabled]="isNextDateDisabled" mat-stroked-button (click)="nextTimePeriod()">
      <mat-icon aria-label="Next">keyboard_arrow_right</mat-icon>
    </button>
  </div>

</div>


<div class="row">
  <button class="btn btn-primary" (click)="generateAndEmailReport()">Generate and email report</button>
</div>

<div class="row" style="margin-top: 10px;">

  <div class="card" *ngIf="reports.length === 0">
    <div class="card-body">No reports</div>
  </div>

  <div class="card report-card" *ngFor="let report of reports">
    <div class="card-header">
      <a class="btn btn-primary" target="_blank" [href]="report.reportUrl">
        Open
      </a>
    </div>
    <div class="card-body column">
      <iframe  [src]="report.reportUrl | safe"></iframe>
    </div>
  </div>

</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>

