<breadcrumb previousPageName="Devices"
            [previousPageLink]="PREVIOUS_PAGE"
            [currentPageName]="getDeviceName()"
            [shouldShow]=true
>
</breadcrumb>

<!-- Only commenting out, maybe they want it back in the future -->
<!-- <div id="mdmActions" *ngIf="deviceId">
  <div matTooltip="MDM functionality is disabled for this device because it is not registered on Scale Fusion."
       [matTooltipDisabled]="isScaleFusionDevice">
    <button class="btn btn-rounded btn-info" (click)="rebootDevice()" [disabled]="!isScaleFusionDevice || (isScaleFusionDevice && isBusyRebooting)">
      <mat-spinner *ngIf="isBusyRebooting" class="inline-spinner button-spinner" style="margin-left: 28px" diameter="25"></mat-spinner>
      <span class="ul-btn__icon"><mat-icon>refresh</mat-icon></span>
      <span class="ul-btn__text">Reboot</span>
    </button>
  </div>

  <div matTooltip="MDM functionality is disabled for this device because it is not registered on Scale Fusion."
       [matTooltipDisabled]="isScaleFusionDevice">
    <button class="btn btn-rounded btn-info" (click)="lockDevice()" [disabled]="!isScaleFusionDevice || (isScaleFusionDevice && isBusyLocking)">
      <mat-spinner *ngIf="isBusyLocking" class="inline-spinner button-spinner" style="margin-left: 22px" diameter="25"></mat-spinner>
      <span class="ul-btn__icon"><mat-icon>lock</mat-icon></span>
      <span class="ul-btn__text">Lock</span>
    </button>
  </div>

  <div matTooltip="MDM functionality is disabled for this device because it is not registered on Scale Fusion."
       [matTooltipDisabled]="isScaleFusionDevice">
    <button class="btn btn-rounded btn-info" (click)="unlockDevice()" [disabled]="!isScaleFusionDevice || (isScaleFusionDevice && isBusyUnlocking) || currEmployeeRole === 'controller'">
      <mat-spinner *ngIf="isBusyUnlocking" class="inline-spinner button-spinner" style="margin-left: 33px" diameter="25"></mat-spinner>
      <span class="ul-btn__icon"><mat-icon>lock_open</mat-icon></span>
      <span class="ul-btn__text">Unlock</span>
    </button>
  </div>
</div> -->

<div class="row" style="margin-bottom: 16px;">
  <div [ngStyle]="{flex: 1, marginRight: (deviceId) ? '25px' : 0}">
    <div class="card">
      <div class="card-header" *ngIf="deviceId && isScaleFusionDevice">
        <span class="row" id="device-battery">
          <span>{{deviceBatteryStatus + '%'}}</span>
          <mat-icon [matTooltip]="(deviceIsCharging) ? 'Device is charging' : 'Device is not charging'">
            {{(deviceIsCharging) ? 'battery_charging_full' : 'battery_full'}}
          </mat-icon>
          <mat-icon (click)="getBatteryStatus()">refresh</mat-icon>
          <mat-spinner *ngIf="isBusyGettingBattery" diameter="20" class="inline-spinner" style="margin-left: 10px"></mat-spinner>
        </span>
      </div>
      <div class="card-body">
        <form [formGroup]="deviceForm" class="column">

          <div class="form-group">
            <label>Device name</label>
            <input formControlName="deviceName" class="form-control form-control-rounded"
                   placeholder="Device name" type="text">
          </div>

          <div class="form-group">
            <label>App Instance ID</label>
            <input formControlName="appInstanceId" class="form-control form-control-rounded"
                   placeholder="App Instance ID" type="text" (change)="getSerialNumberFromInstall()">
          </div>

          <div class="form-group">
            <div class="row" id="serial-number-header">
              <label>Serial Number</label>
              <button *ngIf="appInstallSerialNumber" (click)="autoFillSerialNumber()"
                      class="btn btn-secondary"
                      matTooltip="A serial number from when the app was installed was found. Click to auto fill in the serial number.">
                Auto Fill
              </button>
            </div>
            <input formControlName="serialNumber" class="form-control form-control-rounded"
                   placeholder="Serial Number">
          </div>

          <div class="form-group">
            <label>Contact Number</label>
            <input formControlName="contactNumber" class="form-control form-control-rounded"
                   placeholder="Contact Number" type="tel">
          </div>
        </form>

        <ng-container *ngIf="currEmployeeRole !== 'controller'">
          <hr>
          <div class="row">
            <button *ngIf="deviceId" class="btn btnDel btn-primary btn-icon" (click)="deleteDevice()">
              <span class="ul-btn__icon"><mat-icon>delete</mat-icon></span>
              <span class="ul-btn__text">Delete</span>
            </button>
            <button class="btn btn-primary btn-icon mr-2" (click)="upsertDevice()">
              <span class="ul-btn__icon"><mat-icon>check</mat-icon></span>
              <span class="ul-btn__text">Save Device</span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="card" id="locations" *ngIf="deviceId">
    <div class="card-header">
      <span>Live Location</span>
    </div>

    <div class="card-body">
      <div id="map" class="map-styling"></div>

      <div *ngIf="!deviceCurrentLocation.latitude || !deviceCurrentLocation.longitude">
        <p>No location data</p>
        <div style="width: 300px; height: 250px"></div>
      </div>
    </div>
  </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
