import {
  ALERT_COLOR_CLASSIFICATIONS,
  ATTENDANCE_TOPIC_EVENTS,
  CHAT_TOPIC_EVENTS,
  CRITICAL_TOPIC_EVENTS,
  DEVICE_LOCATION_TOPIC_EVENTS,
  EVENT_TOPICS,
  EVENT_TYPES, GREEN_ALERTS, MISC_ALERTS,
  PATROL_TOPIC_EVENTS, RED_ALERTS, YELLOW_ALERTS,
  DEVICE_STATUS_TOPIC_EVENTS
} from '../enums/EventTypes';
import {Attendance} from '../models/Attendance';
import {PanicAlert} from '../models/PanicAlert';
import {RouteTagScan} from '../models/RouteTagScan';
import {AlertAlarmResponse} from '../models/AlertAlarmResponse';
import * as moment from 'moment';

const EMPTY_FIELD_TEXT = 'N/A';

export const getAlertColorClassification = (alertType: string) => {
  if (GREEN_ALERTS.includes(alertType)) {
    return ALERT_COLOR_CLASSIFICATIONS.GREEN;
  } else if (YELLOW_ALERTS.includes(alertType)) {
    return ALERT_COLOR_CLASSIFICATIONS.YELLOW;
  } else if (RED_ALERTS.includes(alertType)) {
    return ALERT_COLOR_CLASSIFICATIONS.RED;
  } else if (MISC_ALERTS.includes(alertType)) {
    return ALERT_COLOR_CLASSIFICATIONS.MISC;
  } else {
    return null;
  }
};

export const getAlertTopic = (alert) => {
  if (CRITICAL_TOPIC_EVENTS.includes(alert.type)) {
    return EVENT_TOPICS.CRITICAL;
  } else if (PATROL_TOPIC_EVENTS.includes(alert.type)) {
    return EVENT_TOPICS.PATROL;
  } else if (DEVICE_LOCATION_TOPIC_EVENTS.includes(alert.type)) {
    return EVENT_TOPICS.DEVICE_LOCATION;
  } else if (CHAT_TOPIC_EVENTS.includes(alert.type)) {
    return EVENT_TOPICS.CHAT;
  } else if (ATTENDANCE_TOPIC_EVENTS.includes(alert.type)) {
    return EVENT_TOPICS.ATTENDANCE;
  } else if (DEVICE_STATUS_TOPIC_EVENTS.indexOf(alert.type)) {
    return EVENT_TOPICS.DEVICE_STATUS;
  } else {
    return null;
  }
};

export const getAlertEpochDateTime = (alert) => {

  let alertDateTime;

  switch (alert.type) {
    case EVENT_TYPES.PANIC: {
      alertDateTime = new Date(alert.sentAt * 1000);
      break;
    }
    case EVENT_TYPES.NFC_TAG_SCAN: {
      alertDateTime = new Date(alert.scannedAt * 1000);
      break;
    }
    case EVENT_TYPES.NFC_TAG_SCAN_UNALLOCATED: {
      alertDateTime = new Date(alert.scannedAt * 1000);
      break;
    }
    case EVENT_TYPES.ALERT_ALARM_RESPONSE: {
      alertDateTime = new Date(alert.respondedAt * 1000);
      break;
    }
    case EVENT_TYPES.ATTENDANCE: {
      alertDateTime = new Date(alert.clockTime * 1000);
      break;
    }
    case EVENT_TYPES.PLEASE_CALL_ME: {
      alertDateTime = new Date(alert.sentAt * 1000);
      break;
    }
    case EVENT_TYPES.PATROL_SCAN_ALERT: {
      alertDateTime = new Date(alert.scannedAt * 1000);
      break;
    }
    case EVENT_TYPES.PATROL_ENDED: {
      alertDateTime = new Date(alert.completedEventTime);
      break;
    }
    case EVENT_TYPES.DEVICE_OFFLINE: {
      alertDateTime = new Date(alert.sentAt * 1000);
      break;
    }
    case EVENT_TYPES.DEVICE_BATTERY_LOW: {
      alertDateTime = new Date(alert.sentAt * 1000);
      break;
    }
  }
  return alertDateTime;
};


export const getAlertDateTime = (alert) => {
  const alertDateTime = getAlertEpochDateTime(alert);
  if (alertDateTime) {
    return moment(alertDateTime).format('HH:mm DD/MM');
  } else {
    return '';
  }
};

export const getAlertDescription = (alert, isDetailed: boolean = false) => {
  switch (alert.type) {
    case EVENT_TYPES.PANIC: {
      return getPanicAlertDescription(alert, isDetailed);
    }
    case EVENT_TYPES.NFC_TAG_SCAN: {
      return getRouteTagScanDescription(alert, isDetailed);
    }
    case EVENT_TYPES.NFC_TAG_SCAN_UNALLOCATED: {
      return getUnallocatedRouteTagScanDescription(alert, isDetailed);
    }
    case EVENT_TYPES.ALERT_ALARM_RESPONSE: {
      return getAlertAlarmResponseDescription(alert, isDetailed);
    }
    case EVENT_TYPES.ATTENDANCE: {
      return getAttendanceDescription(alert, isDetailed);
    }
    case EVENT_TYPES.PLEASE_CALL_ME: {
      return getPleaseCallMeDescription(alert, isDetailed);
    }
    case EVENT_TYPES.PATROL_SCAN_ALERT: {
      return getPatrolScanAlertDescription(alert, isDetailed);
    }
    case EVENT_TYPES.PATROL_ENDED: {
      return getPatrolEndedDescription(alert, isDetailed);
    }
    case EVENT_TYPES.DEVICE_OFFLINE: {
      return getDeviceOfflineDescription(alert, isDetailed);
    }
    case EVENT_TYPES.DEVICE_BATTERY_LOW: {
      return getDeviceBatteryLowDescription(alert, isDetailed);
    }
    default: {
      return 'An alert of type ' + alert.type.toUpperCase() + ' was sent';
    }
  }
};

const getPanicAlertDescription = (alert: PanicAlert, isDetailed: boolean) => {
  let description = '';

  if (isDetailed) {
    description = `A panic was sent`;
    (alert.device && alert.device.deviceName) ? description = `${description} from device ${alert.device.deviceName}` : null;
    description = `${description} at ${getAlertDateTime(alert)}.`
  } else {
    description = 'Panic alert';
  }

  return description;
};

const getRouteTagScanDescription = (alert: RouteTagScan, isFullDescription: boolean) => {
  let description = '';

  if (isFullDescription) {
    description = `A route tag was scanned while a guard was on patrol`;
    (alert.routeTag && alert.routeTag.name) ? description = `Tag ${alert.routeTag.name}(${alert.routeTag.serialNumber}) was scanned while a guard was on patrol` : description;
    (alert.device && alert.device.deviceName) ? description = `${description} using device ${alert.device.deviceName}` : null;
    description = `${description} at ${getAlertDateTime(alert)}.`;
  } else {
    (alert.routeTag && alert.routeTag.name) ? description = `Scanned tag ${alert.routeTag.name} ` : `Scanned a tag`;
  }

  return description;
};

const getUnallocatedRouteTagScanDescription = (alert: RouteTagScan, isFullDescription: boolean) => {
  let description = '';

  if (isFullDescription) {
    description = `An unallocated tag was scanned`;
    (alert.device && alert.device.deviceName) ? description = `${description} using device ${alert.device.deviceName}` : null;
    description = `${description} at ${getAlertDateTime(alert)}.`;
  } else {
    description = `Scanned an unallocated tag`;
  }

  return description;
};

const getAlertAlarmResponseDescription = (alert: AlertAlarmResponse, isFullDescription: boolean) => {
  let description = '';

  let respondedToType = '';
  switch (alert.respondedToType) {
    case EVENT_TYPES.PANIC: {
      respondedToType = 'panic ';
      break;
    }
  }

  if (isFullDescription) {
    description = `A user responded to a ${respondedToType}alert alarm at ${getAlertDateTime(alert)} with the following message: ${alert.message}.`;
  } else {
    description = `A user responded to a ${respondedToType}alert alarm.`;
  }

  return description;
};

const getAttendanceDescription = (alert: Attendance, isFullDescription: boolean) => {
  let description = '';

  if (isFullDescription) {
    description = `A user clocked ${alert.clockType}`;

    if (alert.alternateIdentification) {
      description = `${description} using an alternate identification code (${alert.alternateIdentification})`
    } else if (alert.employeeId) {
      description = `${description} using their name tag`
    }

    if (alert.device.deviceName) {
      description = `${description} on device: ${alert.device.deviceName}`;
    }

    description = `${description} at ${getAlertDateTime(alert)}.`;
  } else {
    description = `Clocked ${alert.clockType}`;
  }

  return description;
};

const getPleaseCallMeDescription = (alert, isDetailed) => {
  let description = `A please call me was requested`;

  if (isDetailed) {
    if (alert.device && alert.device.deviceName) description = `From: ${alert.device.deviceName}`;
  } else {
    description = `Please Call Me`;
  }

  return description;
};

const getPatrolScanAlertDescription = (alert, isDetailed) => {
  let description = '';

  if (isDetailed) {
    description = (alert.routeTag && alert.routeTag.name) ? `Tag ${alert.routeTag.name}(${alert.routeTag.serialNumber}) was scanned too ${alert.alertType} by` : `A tag was scanned too ${alert.alertType} by`;
    if (alert.alertType == 'onTime') description =  `Tag ${alert.routeTag.name}(${alert.routeTag.serialNumber}) was scanned on time by`;
    description = (alert.device && alert.device.deviceName) ? `${description} device (${alert.device.deviceName})` : `${description} a device`;
    description = `${description} at ${getAlertDateTime(alert)}.`;
  } else {
    if(alert.isSkipped){ 
      `Skipped tag ${alert.routeTag.name} ${alert.alertType}`
    }else{
      description =  `Scanned tag ${alert.routeTag.name} ${alert.alertType}`;
      if (alert.alertType == 'onTime') description = `Scanned tag ${alert.routeTag.name} on time`;
    }
  }

  return description;
};

const getDeviceOfflineDescription = (alert, isDetailed) => {
  let description = '';

  if (isDetailed) {
    description = `Device is offline.`;
  } else {
    description = `Offline`;
  }

  return description;
};

const getDeviceBatteryLowDescription = (alert, isDetailed) => {
  let description = '';

  if (isDetailed) {
    description = `Device battery low at ${getAlertDateTime(alert)}.`;
  } else {
    description = `Battery low`;
  }

  return description;
}

const getPatrolEndedDescription = (alert, isDetailed) => {
  let description = '';

  if (isDetailed) {
    description = `A patrol ended`;
    description += ` as ${alert.status}`;
    if (alert.route && alert.route.id) description += ` on route ${alert.route.name}`;
    if (alert.device && alert.device.deviceName) description += ` using device ${alert.device.deviceName}`;
    description += ` at ${getAlertDateTime(alert)}.`;
  } else {
    description = `Patrol ${alert.status}`;
  }

  return description;
};

export const getDeviceDetailFromAlert = (alert) => {
  return `Name: ${(alert.device.deviceName) ? alert.device.deviceName : EMPTY_FIELD_TEXT} \n
          Serial Number: ${(alert.device.serialNumber) ? alert.device.serialNumber : EMPTY_FIELD_TEXT} \n
          Contact Number: ${(alert.device.contactNumber) ? alert.device.contactNumber : EMPTY_FIELD_TEXT} \n
          Site Name: ${(alert.device.site && alert.device.site.name) ? alert.device.site.name : EMPTY_FIELD_TEXT}`;
};

export const getEmployeeDetailFromAlert = (alert) => {
  const employeeFullName = alert.employee.name + ' ' + alert.employee.surname;

  return `Full Name: ${(employeeFullName !== ' ') ? employeeFullName : EMPTY_FIELD_TEXT} \n
          Contact Number: ${(alert.employee.contactNumber) ? alert.employee.contactNumber : EMPTY_FIELD_TEXT} \n
          Email Address: ${(alert.employee.emailAddress) ? alert.employee.emailAddress : EMPTY_FIELD_TEXT}`;
};

export const getRouteTakenDetailFromAlert = (alert) => {
  return `Route Name: ${(alert.routeTaken.route && alert.routeTaken.route.name) ? alert.routeTaken.route.name : EMPTY_FIELD_TEXT} \n
          Duration: ${(alert.routeTaken.duration) ? alert.routeTaken.duration: EMPTY_FIELD_TEXT} \n
          Status: ${(alert.routeTaken.status) ? alert.routeTaken.status : EMPTY_FIELD_TEXT}`;
};

export const getSiteDetailFromAlert = (alert) => {
  return `Site Name: ${(alert.site.name) ? alert.site.name : EMPTY_FIELD_TEXT} \n
          Address: ${(alert.site.address) ? alert.site.address : EMPTY_FIELD_TEXT}`;
};
