import {Injectable} from '@angular/core';
import {CommonService} from "./commonService";

@Injectable({
  providedIn: 'root'
})

export class EmployeeService {

  constructor(
   private commonService: CommonService
  ) {}

  getEmployees(id) {
   return this.commonService.getData('/api/v1/employee/byCompany', id)
  }

  upsertEmployee(employee) {
   return this.commonService.postData('/api/v1/employee', employee)
  }

  uploadImage(employeeId: number, image: any) {
    return this.commonService.postData('/api/v1/chat-image', image);
  }

  deleteEmployee(employee){
    return this.commonService.postData('/api/v1/employee/removeEmployee', {employee});
  }

}
