<div class="card">
    <div class="card-header">
      <div class="row">
        <label class="site-select-styling">Select a Site: </label>
        <mat-form-field>
          <mat-select [(ngModel)]="selectedSite" (selectionChange)="onParametersChanged()">
            <mat-option *ngFor="let site of sites" [value]="site">
              {{site.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span class="divide-text"></span>

        <label class="site-select-styling">Select Report Date Range: </label>

        <div class="input-group" [matTooltip]="'Date starts at shift time (' + getShiftStartTimeString() + ')'">
            <div class="input-group-prepend">
                <span class="input-group-text" style="padding: 0"><mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle></span>
            </div>
            <input class="form-control" style="height: auto" [matDatepicker]="startDatePicker" (dateChange)="onParametersChanged()" [(ngModel)]="startDate">
            <mat-datepicker #startDatePicker></mat-datepicker>
        </div>

        <span style="margin: auto 10px">to</span>

        <div class="input-group" [matTooltip]="'Date starts at shift time (' + getShiftStartTimeString() + ')'">
            <div class="input-group-prepend">
                <span class="input-group-text" style="padding: 0"><mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle></span>
            </div>
            <input class="form-control" style="height: auto" [matDatepicker]="endDatePicker" (dateChange)="onParametersChanged()" [(ngModel)]="endDate">
            <mat-datepicker #endDatePicker></mat-datepicker>
        </div>
    </div>
    </div>
    <div class="card-body">
      <table mat-table [dataSource]="dataSource" class="table-hover">
        <ng-container matColumnDef="employeeName">
            <th mat-header-cell *matHeaderCellDef>Employee Name</th>
            <td mat-cell *matCellDef="let element"> {{element.employeeName}} </td>
        </ng-container>
        <ng-container matColumnDef="employeeSurname">
            <th mat-header-cell *matHeaderCellDef>Employee Surname</th>
            <td mat-cell *matCellDef="let element"> {{element.employeeSurname}} </td>
        </ng-container>
        <ng-container matColumnDef="siteName">
            <th mat-header-cell *matHeaderCellDef>Site</th>
            <td mat-cell *matCellDef="let element"> {{element.siteName}} </td>
        </ng-container>
        <ng-container matColumnDef="userRole">
            <th mat-header-cell *matHeaderCellDef>User Role</th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.userRole != 'default';">
                {{element.userRole}}
              </div>
              <div *ngIf="element.userRole == 'default';">
                Security Guard
              </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="clockInTimeEpoch">
            <th mat-header-cell *matHeaderCellDef>Clock In Time</th>
            <td mat-cell *matCellDef="let element"> {{formatDateTime(element.clockInTimeEpoch)}} </td>
        </ng-container>
        <ng-container matColumnDef="clockOutTimeEpoch">
            <th mat-header-cell *matHeaderCellDef>Clock Out Time</th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.clockOutTimeEpoch != undefined;">
                {{formatDateTime(element.clockOutTimeEpoch)}}
              </div>
              <div *ngIf="element.clockOutTimeEpoch == undefined;">
                No Clock Out Found
              </div>
            </td>
            
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
      </table>
    </div>
</div>

<mat-paginator #Attendance_paginator
    [length] = "employeeClockTimes?.length"
    [pageSize] = "10"
    [pageSizeOptions] = "[10, 25, 100]">
</mat-paginator>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>