import {Injectable} from '@angular/core';
import {Device} from '../models/Device';
import {Employee} from '../models/Employee';
import {SiteContact} from '../models/SiteContact';
import {Company} from '../models/Company';
import {RouteTag} from '../models/RouteTag';
import {Site} from '../models/Site';
import {Route} from '../models/Route';
import {Schedule} from '../models/Schedule';
import {Area} from '../models/Area';

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  ITEM_SELECTED_ROUTE = 'selectedRoute';
  ITEM_SELECTED_TAG = 'selectedTag';
  ITEM_SELECTED_AREA = 'selectedArea';
  ITEM_CURRENT_SITE = 'currentSite';
  ITEM_CURRENT_CHAT_CHANNEL = 'currentChatChannel';
  ITEM_CURRENT_SCHEDULE = 'currentSchedule';

  constructor() {
  }

  setAccessToken(accessToken: string) {
    sessionStorage.setItem('accessToken', accessToken);
  }

  removeAccessToken() {
    sessionStorage.removeItem('accessToken');
  }

  getAccessToken(): string {
    return sessionStorage.getItem('accessToken');
  }

  setSelectedDevice(device: Device) {
    sessionStorage.setItem('selectedDevice', JSON.stringify(device));
  }

  getSelectedDevice() {
    return JSON.parse(sessionStorage.getItem('selectedDevice')) as Device;
  }

  removeSelectedDevice() {
    sessionStorage.removeItem('selectedDevice');
  }

  setSelectedEmployee(employee: Employee) {
    sessionStorage.setItem('selectedEmployee', JSON.stringify(employee));
  }

  getSelectedEmployee() {
    return JSON.parse(sessionStorage.getItem('selectedEmployee')) as Employee;
  }

  setSelectedCompany(company) {
    sessionStorage.setItem('selectedCompany', JSON.stringify(company));
  }

  getSelectedCompany() {
    return JSON.parse(sessionStorage.getItem('selectedCompany')) as Company;
  }

  setLoggedInEmployee(employee: Employee) {
    sessionStorage.setItem('loggedInEmployee', JSON.stringify(employee));
  }

  getLoggedInEmployee() {
    return JSON.parse(sessionStorage.getItem('loggedInEmployee')) as Employee;
  }

  setSelectedRoute(route: Route) {
    sessionStorage.setItem(this.ITEM_SELECTED_ROUTE, JSON.stringify(route));
  }

  getSelectedRoute() {
    return JSON.parse(sessionStorage.getItem(this.ITEM_SELECTED_ROUTE)) as Route;
  }

  setSelectedTag(tag: RouteTag) {
    sessionStorage.setItem(this.ITEM_SELECTED_TAG, JSON.stringify(tag));
  }

  getSelectedTag(): RouteTag {
    return JSON.parse(sessionStorage.getItem(this.ITEM_SELECTED_TAG)) as RouteTag;
  }

  setCurrentSite(site: Site) {
    sessionStorage.setItem(this.ITEM_CURRENT_SITE, JSON.stringify(site));
  }

  getCurrentSite(): Site {
    return JSON.parse(sessionStorage.getItem(this.ITEM_CURRENT_SITE)) as Site;
  }

  setSelectedSchedule(schedule: Schedule) {
    sessionStorage.setItem(this.ITEM_CURRENT_SCHEDULE, JSON.stringify(schedule));
  }

  getSelectedSchedule(): Schedule {
    return JSON.parse(sessionStorage.getItem(this.ITEM_CURRENT_SCHEDULE)) as Schedule;
  }

  setSelectedChatChannelSiteData(selectedChatChannelData: Site) {
    sessionStorage.setItem(this.ITEM_CURRENT_CHAT_CHANNEL, JSON.stringify(selectedChatChannelData));
  }

  getSelectedChatChannelSiteData(): Site {
    return JSON.parse(sessionStorage.getItem(this.ITEM_CURRENT_CHAT_CHANNEL)) as Site;
  }

  setSelectedArea(selectedArea: Area) {
    sessionStorage.setItem(this.ITEM_SELECTED_AREA, JSON.stringify(selectedArea));
  }

  getSelectedArea(): Area {
    return JSON.parse(sessionStorage.getItem(this.ITEM_SELECTED_AREA)) as Area;
  }

}
