<div class="app-admin-wrap layout-sidebar-large">
  <!-- TOP BAR -->
  <div class="main-header">
    <mat-icon *ngIf="loggedInEmployee.role === ROLE_TYPES.SYS_ADMIN" (click)="returnHome()" class="icon mr-2" aria-hidden="false" matTooltip="Return to Home Page" aria-label="Return to Home Page">arrow_back</mat-icon>
    <div>
      <img src="../../../assets/dist-assets/images/Navigation.png" alt="" style="width: auto; height: 50px; margin-left: 10px; margin-right: 25px">
    </div>
    <div class="company-label" *ngIf="company">{{company.name}}</div>

    <div style="margin: auto; display: flex">
      <app-alert-notifications *ngIf="loggedInEmployee.role !== 'sys-admin'"></app-alert-notifications>
    </div>

    <div class="header-part-right">
      <span class="">{{loggedInEmployee.name + ' ' + loggedInEmployee.surname + ' (' + loggedInEmployee.role + ')'}}</span>
      <button class="btn mr-2 btnSignOut" (click)="logout()" matTooltip="Sign Out">
      <span>
      <mat-icon class="ul-btn__icon" aria-hidden="false"  aria-label="Sign Out">exit_to_app</mat-icon>
      </span>
        <span class="ul-btn__text">Sign Out</span>
      </button>
    </div>
  </div>
  <!-- TOP BAR END -->

  <app-sidebar></app-sidebar>
  <!-- =============== Left side End ================-->
  <div class="main-content-wrap sidenav-open d-flex flex-column">
    <!-- ============ Body content start ============= -->
    <div class="main-content">
      <router-outlet></router-outlet>
      <!-- end of main-content -->
    </div>
  </div>
</div>
