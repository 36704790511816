import {Component, OnInit} from '@angular/core';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {StorageService} from '../../services/storage-service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ScheduleService} from '../../services/schedule-service';
import {Schedule} from '../../models/Schedule';
import * as moment from 'moment';
import {Route} from '../../models/Route';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {Site} from '../../models/Site';
import {RouteService} from '../../services/route-service';
import {SchedulePlan} from '../../models/SchedulePlan';

@Component({
  selector: 'app-schedule-details',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.scss']
})
export class ScheduleDetailsComponent extends ComponentBaseComponent implements OnInit {
  PREVIOUS_PAGE = 'company/site/xtra/schedule';
  REQUIRED_SCHEDULE_TIMEZONE_OFFSET = 2;

  routes: Route[] = [];
  scheduleForm: FormGroup;
  scheduleId: number;
  selectedSchedule: Schedule;
  schedulePlans: SchedulePlan[] = [];
  site: Site;
  currEmployeeRole: string;
  selectedDayTimes = true;
  selectedNightTimes = true;

  userTimezoneOffset = 0;

  isBusy = false;
  currentRoute: Route;

  constructor(
    private scheduleService: ScheduleService,
    protected storageService: StorageService,
    public routeService: RouteService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialog,
    public snackbar: MatSnackBar,
    public router: Router,
  ) {
    super(snackbar, dialogRef, router, storageService);

  }

  ngOnInit(): void {
    this.currentRoute = new Route();
    this.site = this.storageService.getCurrentSite();
    this.prepareForm();
    this.getSchedule();
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.getUserTimezoneOffset();
  }

  prepareForm() {
    this.scheduleForm = this.formBuilder.group({
      routeId: [''],
      startTimeDay: [''],
      endTimeDay: [''],
      startTimeNight: [''],
      endTimeNight: [''],
      repeatMinutes: ['', Validators.required],
      randomizeStart: [false],
      monday: [false],
      tuesday: [false],
      wednesday: [false],
      thursday: [false],
      friday: [false],
      saturday: [false],
      sunday: [false]
    });
  }

  getUserTimezoneOffset() {
    this.userTimezoneOffset = (new Date().getTimezoneOffset() / 60) * -1;
  }

  getTimezoneOffsetString(timezoneOffset: number) {
    let timezoneString;

    if (timezoneOffset > 0) {
      timezoneString = '+';
    } else {
      timezoneString = '-';
      timezoneOffset *= -1;
    }

    timezoneString = (timezoneOffset.toString().length === 1) ? `${timezoneString}0${timezoneOffset}` : `${timezoneString}${timezoneOffset}`;

    timezoneString = `${timezoneString}:00`;

    return timezoneString
  }

  convertToLocalTime(dateString: string) {
    if (!dateString) {
      return moment();
    }

    const today = moment.utc(new Date());
    const momentResult = moment(new Date(`${today.format('YYYY-MM-DD')}T${dateString.split(':')[0]}:${dateString.split(':')[1]}:00Z`));
    return moment(momentResult).local().format('YYYY-MM-DD HH:mm:ss');
  }

  getSchedule() {
    this.isBusy = true;
    this.selectedSchedule = this.storageService.getSelectedSchedule();
    let isUpdatingSchedule = this.selectedSchedule !== null;

    if (isUpdatingSchedule) {
      this.scheduleId = this.selectedSchedule.id;

      let startTimeD = moment(this.convertToLocalTime(this.selectedSchedule.startTimeDay)).format('HH:mm');
      let endTimeD = moment(this.convertToLocalTime(this.selectedSchedule.endTimeDay)).format('HH:mm');
      let startTimeN = moment(this.convertToLocalTime(this.selectedSchedule.startTimeNight)).format('HH:mm');
      let endTimeN = moment(this.convertToLocalTime(this.selectedSchedule.endTimeNight)).format('HH:mm');

      if (startTimeD === endTimeD) {
        this.selectedDayTimes = false;
        this.scheduleForm.controls['startTimeDay'].disable();
        this.scheduleForm.controls['endTimeDay'].disable();
      }

      if (startTimeN === endTimeN) {
        this.selectedNightTimes = false;
        this.scheduleForm.controls['startTimeNight'].disable();
        this.scheduleForm.controls['endTimeNight'].disable();
      }

      this.routeService.getAllRoutesBySiteId(this.site.id).subscribe(res => {
        let jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        this.routes = jsonConvert.deserializeArray(res.body as any, Route);
        this.currentRoute = (this.selectedSchedule && this.selectedSchedule.routeId) ? this.routes.filter(route => route.id === this.selectedSchedule.routeId)[0] : this.routes[0];

        this.scheduleForm.patchValue({
          routeId: this.currentRoute,
          startTimeDay: startTimeD,
          endTimeDay: endTimeD,
          startTimeNight: startTimeN,
          endTimeNight: endTimeN,
          repeatMinutes: this.selectedSchedule.repeatMinutes,
          randomizeStart: this.selectedSchedule.randomizeStart,
          monday: this.selectedSchedule.monday,
          tuesday: this.selectedSchedule.tuesday,
          wednesday: this.selectedSchedule.wednesday,
          thursday: this.selectedSchedule.thursday,
          friday: this.selectedSchedule.friday,
          saturday: this.selectedSchedule.saturday,
          sunday: this.selectedSchedule.sunday
        });

        if (this.selectedSchedule.id > 0) {
          this.scheduleService.getSchedulePlansByScheduleId(this.selectedSchedule.id).subscribe((result) => {
            const schedulePlansResult = result.body as any;
            if (schedulePlansResult !== null && schedulePlansResult.recordset.length > 0) {
              this.schedulePlans = jsonConvert.deserializeArray(schedulePlansResult.recordset as any, SchedulePlan);
            }
          }, error => {
            this.handleError(error);
          });
        }

        this.isBusy = false;
      }, error => {
        this.isBusy = false;
        this.handleError(error);
      });

    }
    this.isBusy = false;
  }

  getCurrentPageName() {
    return (this.scheduleId) ? 'Schedule - ' + this.selectedSchedule.routeName : 'New Route Schedule';
  }

  formToObj() {
    const now = moment('HH:mm:ss');
    return {
      id: this.scheduleId,
      routeId: this.scheduleForm.value.routeId.id,
      startTimeDay: this.selectedDayTimes ? moment(this.scheduleForm.value.startTimeDay, 'HH:mm:ss') : now,//this.scheduleForm.value.startTimeDay,
      endTimeDay: this.selectedDayTimes ? moment(this.scheduleForm.value.endTimeDay, 'HH:mm:ss') : now,//this.scheduleForm.value.endTimeDay,
      startTimeNight: this.selectedNightTimes ? moment(this.scheduleForm.value.startTimeNight, 'HH:mm:ss') : now,//this.scheduleForm.value.startTimeNight,
      endTimeNight: this.selectedNightTimes ? moment(this.scheduleForm.value.endTimeNight, 'HH:mm:ss') : now,//this.scheduleForm.value.endTimeNight,
      repeatMinutes: this.scheduleForm.value.repeatMinutes,
      randomizeStart: this.scheduleForm.value.randomizeStart ? this.scheduleForm.value.randomizeStart : false,
      monday: this.scheduleForm.value.monday ? this.scheduleForm.value.monday : false,
      tuesday: this.scheduleForm.value.tuesday ? this.scheduleForm.value.tuesday : false,
      wednesday: this.scheduleForm.value.wednesday ? this.scheduleForm.value.wednesday : false,
      thursday: this.scheduleForm.value.thursday ? this.scheduleForm.value.thursday : false,
      friday: this.scheduleForm.value.friday ? this.scheduleForm.value.friday : false,
      saturday: this.scheduleForm.value.saturday ? this.scheduleForm.value.saturday : false,
      sunday: this.scheduleForm.value.sunday ? this.scheduleForm.value.sunday : false,
      siteId: this.storageService.getCurrentSite().id
    };
  }

  updateSchedule() {
    if (this.scheduleForm.valid) {
      if (this.validScheduleTimes()) {
        let actionText = (this.scheduleId) ? 'save your changes' : 'create a new schedule';

        const hasSchedulePlans = (this.schedulePlans.length > 0);

        if (hasSchedulePlans) {
          actionText = 'update this schedule as it has up-coming schedules planned. If you continue it will result in the loss of all schedule plans made after this change has been made.';
        }

        let dialogRef = this.showConfirmationDialog(actionText);

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (this.isPageValid()) {
              this.isBusy = true;
              const newSchedule = this.formToObj();
              this.scheduleService.upsertSchedule(newSchedule).subscribe((result) => {
                this.showMessage('Schedule successfully added/updated', MessageType.success);
                this.storageService.setSelectedSchedule(null);
                this.isBusy = false;
                this.router.navigate([this.PREVIOUS_PAGE]);
              }, error => {
                this.isBusy = false;
                this.showMessage('Error', MessageType.error);
              });
            }
          }
        });
      }
    } else {
      this.showMessage('This is not a valid schedule', MessageType.warning);
    }
  }

  deleteSchedule() {
    let actionText = 'delete schedule.';

    const hasSchedulePlans = (this.schedulePlans.length > 0);

    if (hasSchedulePlans) {
      actionText = 'delete this schedule as it has up-coming schedules planned. If you continue it will result in the loss of all schedule plans made after this change has been made.';
    }
    this.deleteScheduleDialog(actionText);

  }

  deleteScheduleDialog(actionText) {

    let dialogRef = this.showConfirmationDialog(actionText);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isBusy = true;
        this.scheduleService.deleteScheduleById(this.scheduleId).subscribe((result) => {
          this.showMessage('Schedule successfully deleted.', MessageType.success);
          this.isBusy = false;
          this.router.navigate([this.PREVIOUS_PAGE]);
        }, error => {
          this.isBusy = false;
          this.showMessage('Error', MessageType.error);
        });
      }
    });
  }

  selectedDayTimesChange(e) {
    this.selectedDayTimes = e.checked;
    if (e.checked) {
      this.scheduleForm.controls['startTimeDay'].enable();
      this.scheduleForm.controls['endTimeDay'].enable();
    } else {
      this.scheduleForm.controls['startTimeDay'].disable();
      this.scheduleForm.controls['endTimeDay'].disable();
    }
  }

  selectedNightTimesChange(e) {
    this.selectedNightTimes = e.checked;
    if (e.checked) {
      this.scheduleForm.controls['startTimeNight'].enable();
      this.scheduleForm.controls['endTimeNight'].enable();
    } else {
      this.scheduleForm.controls['startTimeNight'].disable();
      this.scheduleForm.controls['endTimeNight'].disable();
    }
  }

  private isPageValid() {
    if (this.scheduleForm.value.repeatMinutes < this.scheduleForm.value.routeId.durationMinutes) {
      this.showMessage('Your schedule repeat minutes can not be less than your route duration of ' + this.scheduleForm.value.routeId.durationMinutes + ' minutes', MessageType.warning);
      return false;
    }
    return true;
  }

  validScheduleTimes() {
    if (this.selectedDayTimes) {
      const startTimeDay = this.scheduleForm.value.startTimeDay
      const endTimeDay = this.scheduleForm.value.endTimeDay;
      if ((moment(startTimeDay, 'HH:mm') < moment('06:00','HH:mm')) || (moment(startTimeDay, 'HH:mm') > moment('17:59','HH:mm'))) {
        this.showMessage('Schedule day start time is invalid.', MessageType.error);
        return false;
      }
      if ((moment(endTimeDay, 'HH:mm') < moment('06:00','HH:mm')) || (moment(endTimeDay, 'HH:mm') > moment('17:59','HH:mm'))) {
        this.showMessage('Schedule day end time is invalid.', MessageType.error);
        return false;
      }
    }

    if (this.selectedNightTimes) {
      const startTimeNight = this.scheduleForm.value.startTimeNight
      const endTimeNight = this.scheduleForm.value.endTimeNight;
      if ((moment(startTimeNight, 'HH:mm') < moment('18:00','HH:mm')) && (moment(startTimeNight, 'HH:mm') > moment('05:59','HH:mm'))) {
        this.showMessage('Schedule night start time is invalid.', MessageType.error);
        return false;
      }
      if ((moment(endTimeNight, 'HH:mm') < moment('18:00','HH:mm')) && (moment(endTimeNight, 'HH:mm') > moment('05:59','HH:mm'))) {
        this.showMessage('Schedule night end time is invalid.', MessageType.error);
        return false;
      }
    }
    return true;
  }
}
