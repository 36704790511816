import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {StorageService} from '../../services/storage-service';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Employee} from '../../models/Employee';
import {ROLE_TYPES} from '../../enums/RoleTypes';
import {AreaService} from '../../services/area.service';
import {validEmail} from "../../logic/commonLogic";

@Component({
  selector: 'app-area-detail',
  templateUrl: './area-detail.component.html',
  styleUrls: ['./area-detail.component.scss']
})
export class AreaDetailComponent extends ComponentBaseComponent implements OnInit {
  PREVIOUS_PAGE = '/company/areas';
  ROLE_TYPES = ROLE_TYPES;

  isBusy = false;
  loggedInEmployee: Employee;
  isNew = true;
  areaForm;
  areaId: number;

  constructor(private formBuilder: FormBuilder,
              public storageService: StorageService,
              public snackbar: MatSnackBar,
              public dialog: MatDialog,
              public router: Router,
              private areaService: AreaService) {
    super(snackbar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.getLoggedInEmployee();
    this.prepareForm();
    this.getArea();
  }

  prepareForm() {
    this.areaForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(30)]],
      contactPerson: ['', Validators.required],
      contactEmail: ['', Validators.required],
      contactNumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(13)]],
      shouldReceiveReports: [false, Validators.required]
    });
  }

  formToObject() {
    return {
      id: this.areaId,
      name: this.areaForm.value.name,
      contactPerson: this.areaForm.value.contactPerson,
      contactEmail: this.areaForm.value.contactEmail,
      contactNumber: this.areaForm.value.contactNumber,
      shouldReceiveReports: this.areaForm.value.shouldReceiveReports,
      companyId: this.storageService.getSelectedCompany().id
    }
  }

  getArea() {
    const area = this.storageService.getSelectedArea();

    if (area && area.id) {
      this.isNew = false;
      this.areaId = area.id;

      this.areaForm.patchValue({
        name: area.name,
        contactPerson: area.contactPerson,
        contactEmail: area.contactEmail,
        contactNumber: area.contactNumber,
        shouldReceiveReports: area.shouldReceiveReports
      });
    } else {
     this.isNew = true;
    }
  }

  getLoggedInEmployee() {
    this.loggedInEmployee = this.storageService.getLoggedInEmployee();
  }

  upsertArea() {
    if (this.validatePage()) {
      const area = this.formToObject();

      this.areaService.upsertArea(area).subscribe(result => {
        this.showMessage('Area successfully added/updated', MessageType.success);
        this.router.navigate([this.PREVIOUS_PAGE]);
      }, error => {
        this.handleError(error);
      });
    }
  }

  validatePage(): boolean {
    let area = this.formToObject();
    if (!validEmail(area.contactEmail)) {
      this.showMessage('Invalid email address.', MessageType.error);
      return;
    }
    if (this.areaForm.invalid) {
      if (area.name.length > 30) {
        this.showMessage("Area name can't be over 30 characters long", MessageType.error);
      } else if (area.contactNumber.length > 13 || area.contactNumber.length < 9) {
        this.showMessage('Please enter a valid contact number', MessageType.error);
      } else if (this.areaForm.controls['contactEmail'].errors) {
        this.showMessage('Invalid email address', MessageType.error);
      } else {
        this.showMessage('All fields are required.', MessageType.error);
      }

      return false;
    } else {
      return true;
    }
  }

}
