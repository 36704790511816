import {Component} from '@angular/core';
import {ProfileService} from '../../services/profile-service';
import {Router} from '@angular/router';
import {StorageService} from '../../services/storage-service';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {Employee} from '../../models/Employee';
import {Company} from '../../models/Company';
import {Site} from "../../models/Site";
import {ForgotPasswordComponent} from "../forgot-password/forgot-password.component";
import {validEmail} from '../../../app/logic/commonLogic';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ComponentBaseComponent  {
  emailAddress: string = '';
  password: string = '';
  isBusy = true;

  constructor(
    private profileService: ProfileService,
    protected storageService: StorageService,
    public router: Router,
    public snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { super(snackbar, dialog, router,storageService) }

  ngOnInit(): void {
    this.isBusy = false;
  }

  onPasswordEnter() {
    this.login();
  }

  login() {
    this.isBusy = true;

    if (!validEmail(this.emailAddress)) {
      this.showMessage('Invalid email address.', MessageType.error);
      this.isBusy = false;
      return;
    }

    if (this.emailAddress !== '' && this.password !== '') {
      this.profileService.login(this.emailAddress, this.password).subscribe(result => {
        this.storageService.setAccessToken(result.headers.get('x-access-token'));

        const employeeId = (result.body as any).employeeId;

        this.profileService.getCurrentUser().subscribe(result => {
          const data = result.body as any;

          let jsonConvert = new JsonConvert();
          jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

          let loggedInEmployee: Employee = jsonConvert.deserializeObject(data, Employee);
          loggedInEmployee.id = employeeId;
          loggedInEmployee.userId = data.id;
          loggedInEmployee.company = new Company();
          loggedInEmployee.company.id = data.companyId;
          loggedInEmployee.company.name = data.companyName;
          loggedInEmployee.site = new Site();
          loggedInEmployee.site.id = data.siteId;
          loggedInEmployee.site.name = data.siteName;

          this.storageService.setLoggedInEmployee(loggedInEmployee);
          this.isBusy = false;

          if (loggedInEmployee.role === 'sys-admin'){
            this.router.navigate(['companies']);
          } else {
            this.storageService.setSelectedCompany(loggedInEmployee.company);
            this.router.navigate(['company/alerts']);
          }
        });

      },error => {
        this.handleError(error);
        this.isBusy = false;
      });
    } else {
      this.showMessage('Fill in all fields.', MessageType.error);
      this.isBusy = false;
    }
  }

  showForgotPassword(){
    this.dialog.open(ForgotPasswordComponent, {width: '350px', height: '230px'})
  }

}
