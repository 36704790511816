import {Component, OnInit} from '@angular/core';
import {Company} from '../../models/Company';
import {ComponentBaseComponent} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {StorageService} from '../../services/storage-service';
import * as moment from 'moment';
import * as utils from '../../utils';
import {GraphService} from '../../graph/graphService';
import {ReportService} from '../../services/report.service';
import {PATROL_ALERTS} from '../../enums/EventTypes';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends ComponentBaseComponent implements OnInit {
  UTC_SHIFT_START_HOUR = 4;

  company: Company;
  lastShiftStart;
  lastShiftEnd;

  tagsScannedGauge;
  tagsScanned = 0;
  tagsShouldveBeenScanned = 0;
  tagsScannedPercentage = 0;
  tagsScannedGaugeIsBusy = false;

  completedPatrolsGauge;
  completedPatrols = 0;
  totalPatrols = 0;
  completedPatrolsPercentage = 0;
  completedPatrolsGaugeIsBusy = false;

  panicAlerts = 0;
  pleaseCallMeAlerts = 0;
  deviceOfflineAlerts = 0;
  lowBatteryAlerts = 0;

  slaThreshold = 0;
  sitesBelowThreshold = [];
  sitesAboveThreshold = [];
  sitesAboveThresholdSunburstIsBusy = false;
  sitesBelowThresholdSunburstIsBusy = false;
  sitesAboveThresholdSunburstColors = ['#006400', '#008000', '#228B22', '#32CD32', '#98FB98'];
  sitesBelowThresholdSunburstColors = ['#85000d', '#970b13', '#bb151a', '#d92723', '#FF4500'];

  constructor(
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public router: Router,
    public storageService: StorageService,
    private graphService: GraphService,
    private reportService: ReportService,
  ) {
    super(snackbar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.getCompany();
    this.calculateLastShift();
    this.generateTagsScannedGauge();
    this.generateTagsScannedSunbursts();
    this.generateCompletedPatrolsGauge();
    this.getAlertCounts();
  }

  getCompany() {
    this.company = this.storageService.getSelectedCompany();
  }

  calculateLastShift() {
    let todayUtc = new Date(`${moment(new Date()).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`);

    this.lastShiftStart = moment(todayUtc).subtract(1, 'days');
    this.lastShiftEnd = moment(todayUtc);
  }

  generateTagsScannedGauge() {
    this.tagsScannedGaugeIsBusy = true;

    this.reportService.getCompanyTagsScannedData(this.company.id, this.lastShiftStart.toDate().getTime() / 1000, this.lastShiftEnd.toDate().getTime() / 1000).subscribe(result => {
      this.tagsScanned = (result as any).body.tagsScanned;
      this.tagsShouldveBeenScanned = (result as any).body.tagsShouldveBeenScanned;

      this.tagsScannedPercentage = utils.calculatePercentage(this.tagsScanned, this.tagsShouldveBeenScanned);

      this.tagsScannedGauge = this.graphService.createGauge('#tags-scanned-gauge', {
        size: 250,
        maxValue: 100,
        clipWidth: 250,
        clipHeight: 150,
        ringWidth: 60,
        transitionMs: 4000,
        gradientStartColor: '#d62728',
        gradientEndColor: '#2ca12c'
      });

      this.tagsScannedGauge.render(this.tagsScannedPercentage);

      this.tagsScannedGaugeIsBusy = false;
    }, error => {
      this.handleError(error);
      this.tagsScannedGaugeIsBusy = false;
    });
  }

  generateCompletedPatrolsGauge() {
    this.completedPatrolsGaugeIsBusy = true;

    this.reportService.getCompanyPatrolsData(this.company.id, this.lastShiftStart.toDate().getTime() / 1000, this.lastShiftEnd.toDate().getTime() / 1000, PATROL_ALERTS.COMPLETE).subscribe(result => {
      this.completedPatrols = (result as any).body.patrols;
      this.totalPatrols = (result as any).body.totalPatrols;

      this.completedPatrolsPercentage = utils.calculatePercentage(this.completedPatrols, this.totalPatrols);

      this.completedPatrolsGauge = this.graphService.createGauge('#completed-patrols-gauge', {
        size: 250,
        maxValue: 100,
        clipWidth: 250,
        clipHeight: 150,
        ringWidth: 60,
        transitionMs: 4000,
        gradientStartColor: '#d62728',
        gradientEndColor: '#2ca12c'
      });

      this.completedPatrolsGauge.render(this.completedPatrolsPercentage);
      this.completedPatrolsGaugeIsBusy = false;
    }, error => {
      this.handleError(error);
      this.completedPatrolsGaugeIsBusy = false;
    });
  }

  generateTagsScannedSunbursts() {
    this.sitesAboveThresholdSunburstIsBusy = true;
    this.sitesBelowThresholdSunburstIsBusy = true;

    this.reportService.getSitesTagsScannedData(this.company.id, this.lastShiftStart.toDate().getTime() / 1000, this.lastShiftEnd.toDate().getTime() / 1000).subscribe(result => {
      this.slaThreshold = (result as any).body.slaThreshold;
      this.sitesAboveThreshold = (result as any).body.sitesAboveThreshold;
      this.sitesBelowThreshold = (result as any).body.sitesBelowThreshold;

      this.generateSitesAboveThresholdSunburst();
      this.generateSitesBelowThresholdSunburst();
    }, error => {
      this.handleError(error);
      this.sitesAboveThresholdSunburstIsBusy = false;
      this.sitesBelowThresholdSunburstIsBusy = false;
    });
  }

  createSunburstRanges(object, bottomRange: number, topRange: number, isAboveThresholdGraph: boolean, sites, lastRangeDifferenceBeforeDisplayingSites = 1) {
    const getColor = (lowerRange: number) => {
      let color: string;

      if (isAboveThresholdGraph) {
        if (lowerRange <= 92) {
          color = this.sitesAboveThresholdSunburstColors[4];
        } else if (lowerRange <= 94) {
          color = this.sitesAboveThresholdSunburstColors[3];
        } else if (lowerRange <= 96) {
          color = this.sitesAboveThresholdSunburstColors[2];
        } else if (lowerRange <= 98) {
          color = this.sitesAboveThresholdSunburstColors[1];
        } else {
          color = this.sitesAboveThresholdSunburstColors[0];
        }
      } else {
        if (lowerRange <= 20) {
          color = this.sitesBelowThresholdSunburstColors[0];
        } else if (lowerRange <= 40) {
          color = this.sitesBelowThresholdSunburstColors[1];
        } else if (lowerRange <= 60) {
          color = this.sitesBelowThresholdSunburstColors[2];
        } else if (lowerRange <= 80) {
          color = this.sitesBelowThresholdSunburstColors[3];
        } else {
          color = this.sitesBelowThresholdSunburstColors[4];
        }
      }

      return color;
    };

    let rangeDifference = topRange - bottomRange;

    if (rangeDifference <= lastRangeDifferenceBeforeDisplayingSites) {
      let sitesInRange = sites.filter(currentSite => currentSite.tagsScannedPercentage >= bottomRange && currentSite.tagsScannedPercentage <= topRange);

      sitesInRange.forEach(currentSite => {
        let siteName = currentSite.name;

        if (siteName.length > 15) {
          siteName = currentSite.name.slice(0, 14) + '...';
        }

        object.push({
          name: `${siteName}`,
          value: 1,
          color: getColor(bottomRange),
          tagsScannedPercentage: currentSite.tagsScannedPercentage
        });
      });

      return object;
    } else {
      let slices = 2;
      let degreesPerSlice = rangeDifference / slices;

      for (let i = 0; i < slices; i++) {
        let lowerRange = utils.roundDecimal(bottomRange + (i * degreesPerSlice), 1);
        let upperRange = utils.roundDecimal(bottomRange + ((i + 1) * degreesPerSlice), 1);

        let children = [];
        this.createSunburstRanges(children, lowerRange, upperRange, isAboveThresholdGraph, sites, lastRangeDifferenceBeforeDisplayingSites);

        object.push({name: `${lowerRange} - ${upperRange}`, children, color: getColor(lowerRange)});
      }
    }
  };

  generateSitesAboveThresholdSunburst() {
    if (this.sitesAboveThreshold.length > 0) {
      let children = [];
      this.createSunburstRanges(children, 90, 100, true, this.sitesAboveThreshold, 2.5);

      let data = {
        name: 'Sites Above Threshold',
        children: children
      };

      this.graphService.createSunburst('#sites-above-threshold-sunburst', data);
    }

    this.sitesAboveThresholdSunburstIsBusy = false;
  }

  generateSitesBelowThresholdSunburst() {
    if (this.sitesBelowThreshold.length > 0) {
      let children = [];
      this.createSunburstRanges(children, 0, 89, false, this.sitesBelowThreshold, 10);

      let data = {
        name: 'Sites Below Threshold',
        children: children
      };

      this.graphService.createSunburst('#sites-below-threshold-sunburst', data);
    }

    this.sitesBelowThresholdSunburstIsBusy = false;
  }

  getAlertCounts() {
    this.reportService.getCompanyAlertCounts(this.company.id, this.lastShiftStart.toDate().getTime() / 1000, this.lastShiftEnd.toDate().getTime() / 1000).subscribe(result => {
      this.panicAlerts = (result as any).body.panicAlerts;
      this.pleaseCallMeAlerts = (result as any).body.pleaseCallMeAlerts;
      this.deviceOfflineAlerts = (result as any).body.deviceOfflineAlerts;
      this.lowBatteryAlerts = (result as any).body.lowBatteryAlerts;
    }, error => {
      this.handleError(error);
    });
  }

}
