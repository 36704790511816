export const roundDecimal = (number: number, decimalPlaces: number) => {
  let x = Math.pow(10, decimalPlaces);

  return Math.round((number + Number.EPSILON) * x) / x;
};

export const calculatePercentage = (value: number, total: number) => {
  let percentage = 100;

  if (total !== 0) {
    percentage = value / total * 100;

    percentage = roundDecimal(percentage, 2);
  }

  return percentage;
};
