import {StorageService} from '../services/storage-service';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {MessageType} from '../component-base';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private router: Router,
    public snackBar: MatSnackBar
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const loggedInEmployee = this.storageService.getLoggedInEmployee();
    const token = this.storageService.getAccessToken();
    if (token) {
      const isExpired = this.isTokenExpired(token);
      if (isExpired) {
        this.showMessage('Please log in again. Session expired.',MessageType.error);
        this.storageService.removeAccessToken();
        this.router.navigate(['login']);
        return false;
      }
      if (loggedInEmployee) {
        if (next.data.roles.indexOf(loggedInEmployee.role) > -1) {
          return true;
        }
        this.storageService.setSelectedCompany(loggedInEmployee.company);
        this.router.navigate(['company/sites']);
        return false;
      }
    } else {
      this.showMessage('Unauthorized. Log in with correct credentials.',MessageType.error);
      this.storageService.removeAccessToken();
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  showMessage(message: string, type: MessageType) {
    const config = new MatSnackBarConfig();
    config.panelClass = [type];
    config.duration = 5000;
    this.snackBar.open(message, 'OK', config);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.storageService.getAccessToken();
    }
    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }
}
