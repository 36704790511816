import * as d3 from 'd3';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GraphService {
  aggregateOptions(originalConfig, inputtedConfig) {
    Object.keys(originalConfig).forEach((currentKey) => {
      if (!inputtedConfig[currentKey]) {
        inputtedConfig[currentKey] = originalConfig[currentKey];
      }
    });

    return inputtedConfig;
  }

  createGauge(container, options) {
    let gaugeChart = {
      update: null,
      render: null,
      isRendered: null,
      configure: null
    };

    let gaugeOptions = {
      size: 710,
      clipWidth: 200,
      clipHeight: 110,
      ringInset: 20,
      ringWidth: 20,

      pointerWidth: 10,
      pointerTailLength: 5,
      pointerHeadLengthPercent: 0.9,

      minValue: 0,
      maxValue: 10,

      minAngle: -90,
      maxAngle: 90,

      transitionMs: 750,

      majorTicks: 5,
      labelFormat: d3.format('d'),
      labelInset: 10,

      gradientStartColor: '#e8e2ca',
      gradientEndColor: '#3e6c0a'
    };

    let range;
    let r;
    let pointerHeadLength;

    let svg;
    let arc;
    let scale;
    let ticks;
    let tickData;
    let pointer;

    options = this.aggregateOptions(gaugeOptions, options);

    function deg2rad(deg) {
      return deg * Math.PI / 180;
    }

    function configure(options) {
      range = options.maxAngle - options.minAngle;
      r = options.size / 2;
      pointerHeadLength = Math.round(r * options.pointerHeadLengthPercent);

      scale = d3.scaleLinear()
        .range([0, 1])
        .domain([options.minValue, options.maxValue]);

      ticks = scale.ticks(options.majorTicks);
      tickData = d3.range(options.majorTicks).map(function() {
        return 1 / options.majorTicks;
      });

      arc = d3.arc()
        .innerRadius(r - options.ringWidth - options.ringInset)
        .outerRadius(r - options.ringInset)
        .startAngle(function(d, i) {
          // @ts-ignore
          let ratio = d * i;
          return deg2rad(options.minAngle + (ratio * range));
        })
        .endAngle(function(d, i) {
          // @ts-ignore
          let ratio = d * (i + 1);
          return deg2rad(options.minAngle + (ratio * range));
        });
    }

    function centerTranslation() {
      return 'translate(' + r + ',' + r + ')';
    }

    function isRendered() {
      return (svg !== undefined);
    }

    function render(newValue) {
      svg = d3.select(container)
        .append('svg:svg')
        .attr('class', 'gauge')
        .attr('width', options.clipWidth)
        .attr('height', options.clipHeight);

      let centerTx = centerTranslation();

      let arcs = svg.append('g')
        .attr('class', 'arc')
        .attr('transform', centerTx);

      const arcColorFn = d3.interpolateHsl(d3.rgb(options.gradientStartColor), d3.rgb(options.gradientEndColor));

      arcs.selectAll('path')
        .data(tickData)
        .enter().append('path')
        .attr('fill', function(d, i) {
          return arcColorFn(d * i);
        })
        .attr('d', arc);

      let lg = svg.append('g')
        .attr('class', 'label')
        .attr('transform', centerTx);
      lg.selectAll('text')
        .data(ticks)
        .enter().append('text')
        .attr('transform', function(d) {
          let ratio = scale(d);
          let newAngle = options.minAngle + (ratio * range);
          return 'rotate(' + newAngle + ') translate(0,' + (options.labelInset - r) + ')';
        })
        .text(options.labelFormat);

      let lineData = [[options.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(options.pointerWidth / 2), 0],
        [0, options.pointerTailLength],
        [options.pointerWidth / 2, 0]];
      let pointerLine = d3.line().curve(d3.curveLinear);
      let pg = svg.append('g').data([lineData])
        .attr('class', 'pointer')
        .attr('transform', centerTx);

      pointer = pg.append('path')
        .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
        .attr('transform', 'rotate(' + options.minAngle + ')');

      update(newValue === undefined ? 0 : newValue);
    }

    function update(newValue, newConfiguration?) {
      if (newConfiguration !== undefined) {
        configure(newConfiguration);
      }
      let ratio = scale(newValue);
      let newAngle = options.minAngle + (ratio * range);
      pointer.transition()
        .duration(options.transitionMs)
        .ease(d3.easeElastic)
        .attr('transform', 'rotate(' + newAngle + ')');
    }

    gaugeChart.configure = configure;
    gaugeChart.isRendered = isRendered;
    gaugeChart.render = render;
    gaugeChart.update = update;

    configure(options);

    return gaugeChart
  }

  createSunburst(container, data) {
    //https://observablehq.com/@d3/zoomable-sunburst

    let partition = data => {
      const root = d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value);
      return d3.partition()
        .size([2 * Math.PI, root.height + 1])
        (root);
    };

    let format = d3.format(",d");

    let width = 500;
    let radius = width / 6;

    let arc = d3.arc()
      .startAngle(d => (d as any).x0)
      .endAngle(d => (d as any).x1)
      .padAngle(d => Math.min(((d as any).x1 - (d as any).x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius(d => (d as any).y0 * radius)
      .outerRadius(d => Math.max((d as any).y0 * radius, (d as any).y1 * radius - 1));

    const root = partition(data);

    root.each(d => (d as any).current = d);

    const svg = d3.select(container).append("svg")
      .attr("viewBox", `0 0 ${width} ${width}`)
      .style("font", "10px sans-serif");

    const g = svg.append("g")
      .attr("transform", `translate(${width / 2},${width / 2})`);

    const path = g.append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", (d) => (d as any).data.color)
      .attr("fill-opacity", d => arcVisible((d as any).current) ? 1 : 0)
      .attr("d", d => arc((d as any).current));

    path.filter(d => (d as any).children)
      .style("cursor", "pointer")
      .on("click", clicked);

    path.append("title")
      .text(d => ((d as any).data.tagsScannedPercentage || (d as any).data.tagsScannedPercentage === 0) ? `${(d as any).data.tagsScannedPercentage}% Tags Scanned` : `${format(d.value)} Sites`);

    let label = g.append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", d => +labelVisible((d as any).current))
      .attr("transform", d => labelTransform((d as any).current))
      .style('text-shadow', '-1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff')
      .style('font-weight', 'bold')
      .style('font-size', d => ((d as any).data.tagsScannedPercentage || (d as any).data.tagsScannedPercentage === 0) ? '12px' : '15px')
      .text(d => (d as any).data.name);

    const parent = g.append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("click", clicked);

    function clicked(p) {
      parent.datum(p.parent || root);

      root.each(d => (d as any).target = {
        x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        y0: Math.max(0, d.y0 - p.depth),
        y1: Math.max(0, d.y1 - p.depth)
      });

      const t = g.transition().duration(750);

      // Transition the data on all arcs, even the ones that aren’t visible,
      // so that if this transition is interrupted, entering arcs will start
      // the next transition from the desired position.
      // @ts-ignore
      path.transition(t)
        .tween("data", d => {
          const i = d3.interpolate((d as any).current, (d as any).target);
          return t => (d as any).current = i(t);
        })
        // @ts-ignore
        .filter(function(d) {
          // @ts-ignore
          return +this.getAttribute("fill-opacity") || arcVisible((d as any).target);
        })
        .attr("fill-opacity", d => arcVisible(d.target) ? 1 : 0)
        .attrTween("d", d => () => arc(d.current));

      // @ts-ignore
      label.filter(function(d) {
        // @ts-ignore
        return +this.getAttribute("fill-opacity") || labelVisible((d as any).target);
      }).transition(t)
        .attr("fill-opacity", d => +labelVisible(d.target))
        .attrTween("transform", d => () => labelTransform(d.current));
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d) {
      const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
      const y = (d.y0 + d.y1) / 2 * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  }
}
