import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {StorageService} from './storage-service';
import {CommonService} from "./commonService";


@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private commonService: CommonService
  ) {
  }

  getCompany() {
   return this.commonService.getData('/api/v1/company','')
  }

  upsertCompany(company) {
    return this.commonService.postData('/api/v1/company', company)
  }

  deleteCompany(id) {
    return this.commonService.postData('api/v1/company/removeCompany', {id})
  }

  registerCompany(registrationData) {
    return this.commonService.postData('/api/v1/company/register', registrationData);
  }

}
