import {Component, OnInit, ViewChild} from '@angular/core';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SiteServiceService} from '../../services/site.service';
import {Site} from '../../models/Site';
import {MatTableDataSource} from '@angular/material/table';
import {StorageService} from '../../services/storage-service';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-sites-summary',
  templateUrl: './sites-summary.component.html',
  styleUrls: ['./sites-summary.component.scss']
})

export class SitesSummaryComponent extends ComponentBaseComponent implements OnInit {

  sites: Site[] = [];
  dataSource = new MatTableDataSource<Site>(this.sites);
  displayedColumns = ['name', 'address', 'companyName', 'image'];
  currEmployeeRole: string;
  isBusy: boolean = false;
  @ViewChild(MatPaginator,{static: true}) paginator : MatPaginator;

  constructor(
    protected matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    protected router: Router,
    private siteService: SiteServiceService,
    protected storageService: StorageService,
  ) {
    super(matSnackBar, dialog, router,storageService);
  }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.getAllSitesByCompany();
  }

  getAllSitesByCompany() {
    this.isBusy = true;
    const companyId = this.storageService.getSelectedCompany().id;
    this.siteService.getAllSitesByCompany(companyId).subscribe(data => {
      let jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.sites = jsonConvert.deserializeArray(data.body as any, Site);
      this.dataSource = new MatTableDataSource<Site>(this.sites);

      this.dataSource.paginator = this.paginator;

      this.isBusy = false;
    }, error => {
      this.isBusy = false;
      this.handleError(error);
    });
  }

  siteSelect(site) {
    if(site === null){
      this.storageService.setCurrentSite(new Site());
      this.router.navigate(['company/site/details']);
    } else {
      this.storageService.setCurrentSite(site);
      this.router.navigate(['company/site/xtra/details']);
    }
  }

  onSearchKeyUp(searchQuery) {
    if (searchQuery.length > 0) {
      const searchedSites = this.sites.filter((site) =>  site.name.toLowerCase().includes(searchQuery.toLowerCase()));
      this.dataSource = new MatTableDataSource<Site>(searchedSites);
      this.dataSource.paginator = this.paginator;
    } else {
      this.dataSource = new MatTableDataSource<Site>(this.sites);
      this.dataSource.paginator = this.paginator;
    }
  }
}
