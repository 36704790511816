import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Site} from '../../models/Site';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {SiteServiceService} from '../../services/site.service';
import {StorageService} from '../../services/storage-service';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {EVENT_TOPICS, EVENT_TYPES} from "../../enums/EventTypes";
import {ChatMessage} from "../../models/ChatMessage";
import {SocketService} from '../../services/socketService';
import {SiteChatComponent} from '../../components/site-chat/site-chat.component';

@Component({
  selector: 'app-company-chat',
  templateUrl: './company-chat.component.html',
  styleUrls: ['./company-chat.component.css']
})
export class CompanyChatComponent extends ComponentBaseComponent implements OnInit, OnDestroy {

  @ViewChild(SiteChatComponent) siteChatComponent: SiteChatComponent;
  sites: Site[] = [];
  siteValue: Site = null;
  resetFormSubject: Subject<Object> = new Subject<Object>();
  isSelected: boolean = false;
  hasSiteData: boolean = false;
  selectedSite: Site;
  siteMessagesCount: number[] = [];
  companyId: number;

  constructor(protected matSnackBar: MatSnackBar,
              public dialog: MatDialog,
              protected router: Router,
              private siteService: SiteServiceService,
              private socketService: SocketService,
              protected storageService: StorageService,) {
    super(matSnackBar, dialog, router, storageService);
    this.storageService.setSelectedChatChannelSiteData(null);
  }

  ngOnInit(): void {
    this.isSelected = !!this.storageService.getSelectedChatChannelSiteData();
    this.selectedSite = this.isSelected ? this.storageService.getSelectedChatChannelSiteData() : null;
    this.getAllSitesByCompany(this.isSelected);
  }

  resetSiteForm(previousSiteId) {
    this.resetFormSubject.next({previousSiteId: previousSiteId});
  }


  getAllSitesByCompany(isSiteChannelLoaded) {
    const companyId = this.storageService.getSelectedCompany().id;
    this.siteService.getAllSitesByCompany(companyId).subscribe(data => {
      let jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.sites = jsonConvert.deserializeArray(data.body as any, Site);

      if (isSiteChannelLoaded) {
        this.siteValue = this.sites.filter(site => {
          return site.id === this.selectedSite.id;
        })[0];
      } else {
        this.siteValue = this.sites[0];
        this.storageService.setSelectedChatChannelSiteData(this.sites[0]);
      }
      this.selectedSite = this.siteValue;
      this.isSelected = !!this.storageService.getSelectedChatChannelSiteData();
      this.listenCompanySitesMessageEvents();
    }, error => {
      this.handleError(error);
    });
  }

  siteChange(site) {
    this.siteValue = site;
    let previousSiteId = this.selectedSite.id;
    this.selectedSite = this.siteValue;
    this.storageService.setSelectedChatChannelSiteData(this.selectedSite);
    this.isSelected = true;
    this.resetSiteForm(previousSiteId);
    this.sites.filter((s) => s.id === previousSiteId)[0].selected = false;
    let currentSite = this.sites.filter((s) => s.id === site.id)[0];
    currentSite.selected = true;
    if (currentSite.messagesCounter > 0) {
      currentSite.messagesCounter = 0;
      this.sortSitesList();
    }
  }

  ngOnDestroy(): void {
    this.socketService.getSocket().off(`${EVENT_TOPICS.CHAT}.${EVENT_TYPES.MESSAGE}`);
  }

  listenCompanySitesMessageEvents() {
    const jsonConvert = new JsonConvert();
    this.socketService.getSocket().on(`${EVENT_TOPICS.CHAT}.${EVENT_TYPES.MESSAGE}`, (message) => {
      if (!message) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + `${EVENT_TOPICS.CHAT}.${EVENT_TYPES.MESSAGE}`);
        return;
      }
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
      const incomingMessage = jsonConvert.deserializeObject(message as any, ChatMessage);
      if (incomingMessage.siteId !== this.selectedSite.id){
        const site = this.sites.filter((s) => s.id === incomingMessage.siteId)[0];
        site.messagesCounter = site.messagesCounter + 1;
        this.sortSitesList();
      } else {
        this.siteChatComponent.newMessageFromSocket(message);
      }
    });
  }

  sortSitesList () {
    const sitesUnreadMessages = this.sites.filter((s) => s.messagesCounter > 0);
    const sitesReadMessages = this.sites.filter((s) => s.messagesCounter === 0);
    sitesUnreadMessages.sort((a, b) => (a.name > b.name) ? 1 : -1);
    sitesReadMessages.sort((a, b) => (a.name > b.name) ? 1 : -1);
    this.sites = sitesUnreadMessages.concat(sitesReadMessages);
  }
}
