import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() previousPageLink: string;
  @Input() previousPageName: string;
  @Input() currentPageName: string;
  @Input() shouldShow: boolean = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.formatPreviousPageLink();
  }

  formatPreviousPageLink() {
    if (this.previousPageLink && this.previousPageLink[0] === '/') {
      this.previousPageLink = this.previousPageLink.substring(1, this.previousPageLink.length);
    }
  }

  goToPreviousPage() {
    if (this.previousPageLink) {
      this.router.navigate([this.previousPageLink]);
    }
  }
}
