import {JsonObject, JsonProperty} from 'json2typescript';
import {EVENT_TYPES} from '../enums/EventTypes';
import {Device} from './Device';
import {RouteTaken} from './RouteTaken';
import {Site} from './Site';
import {RouteTag} from './RouteTag';
import {RouteTagScan} from './RouteTagScan';

@JsonObject('PatrolScanAlert')
export class PatrolScanAlert {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('eventGuid', String, true)
  public eventGuid: string = undefined;

  @JsonProperty('type', String, true)
  public type: string = EVENT_TYPES.PATROL_SCAN_ALERT;

  @JsonProperty('companyId', Number, true)
  public companyId: number = undefined;

  @JsonProperty('deviceId', Number, true)
  public deviceId: number = undefined;

  @JsonProperty('device', Device, true)
  public device: Device = undefined;

  @JsonProperty('tagId', Number, true)
  public tagId: number = undefined;

  @JsonProperty('routeTag', RouteTag, true)
  public routeTag: RouteTag = undefined;

  @JsonProperty('routeTakenId', Number, true)
  public routeTakenId: number = undefined;

  @JsonProperty('routeTaken', RouteTaken, true)
  public routeTaken: RouteTaken = undefined;

  @JsonProperty('scannedAt', Number, true)
  public scannedAt: number = undefined;

  @JsonProperty('site', Site, true)
  public site: Site = undefined;

  @JsonProperty('tagScanId', Number, true)
  public tagScanId: number = undefined;

  @JsonProperty('tagScan', RouteTagScan, true)
  public tagScan: RouteTagScan = undefined;

  @JsonProperty('alertType', String, true)
  public alertType: string = undefined;

  @JsonProperty('tagToRouteId', Number, true)
  public tagToRouteId: number = undefined;

  @JsonProperty('schedulePlanId', Number, true)
  public schedulePlanId: number = undefined;

  @JsonProperty('estimatedScanEpoch', Number, true)
  public estimatedScanEpoch: number = undefined;


}
