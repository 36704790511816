import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('Route')
export class Route {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('siteId', Number, true)
  public siteId: number = undefined;

  @JsonProperty('durationMinutes', Number, true)
  public durationMinutes: number = undefined;

  @JsonProperty('name', String, true)
  public name: string = undefined;

  public color: string = undefined;

  public selected = false;
}
