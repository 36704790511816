import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceService} from '../../services/device-service';
import {StorageService} from '../../services/storage-service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SiteServiceService} from '../../services/site.service';
import {Site} from '../../models/Site';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {SocketService} from '../../services/socketService';
import {EVENT_TOPICS, EVENT_TYPES} from '../../enums/EventTypes';
import * as L from 'leaflet'
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss']
})
export class DeviceDetailComponent extends ComponentBaseComponent implements OnInit, OnDestroy, AfterViewInit  {
  PREVIOUS_PAGE = '/company/site/xtra/devices';

  isBusyRebooting = false;
  isBusyLocking = false;
  isBusyUnlocking = false;
  isBusyGettingBattery = false;

  isScaleFusionDevice = false;
  deviceBatteryStatus = 0;
  deviceIsCharging = false;

  appInstallSerialNumber = null;

  deviceCurrentLocation = {
    latitude: 0,
    longitude: 0
  };

  deviceMapMarker = {
    position: {
      lat: Number(this.deviceCurrentLocation.latitude),
      lng: Number(this.deviceCurrentLocation.longitude)
    }
  };

  mapCenter = {
    lat: Number(this.deviceCurrentLocation.latitude),
    lng: Number(this.deviceCurrentLocation.longitude)
  };

  sites: Site[] = [];

  deviceId: number;
  deviceForm: FormGroup;
  siteValue: Site;

  isBusy = false;

  currEmployeeRole: string;

  private map;
  marker: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceService,
    protected storageService: StorageService,
    private siteService: SiteServiceService,
    private formBuilder: FormBuilder,
    public snackbar: MatSnackBar,
    public dialogRef: MatDialog,
    public router: Router,
    private socketService: SocketService
  ) {
    super(snackbar, dialogRef, router, storageService);
  }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.prepareForm();
    this.getSite();
    this.getDevice();
    this.getDeviceLastLocation();
    this.listenForGpsTracking();
    // this.getScaleFusionDeviceInfo();
  }

  ngAfterViewInit(): void {
    //this.loadMap();
  }
  
  prepareForm() {
    this.deviceForm = this.formBuilder.group({
      contactNumber: ['', [Validators.required, Validators.maxLength(13), Validators.minLength(9)]],
      serialNumber: ['', [Validators.required]],
      appInstanceId: ['', Validators.required],
      deviceName: ['', Validators.required]
    });
  }

  getDevice() {
    const selectedDevice = this.storageService.getSelectedDevice();

    if (selectedDevice && selectedDevice.id) {
      this.deviceId = selectedDevice.id;
      this.deviceForm.patchValue({
        contactNumber: selectedDevice.contactNumber,
        serialNumber: selectedDevice.serialNumber,
        appInstanceId: selectedDevice.appInstanceId,
        deviceName: selectedDevice.deviceName
      });
    }

  }


  getSerialNumberFromInstall() {
    this.deviceService.getDeviceInstallInfoByAppInstanceId(this.deviceForm.value.appInstanceId).subscribe(result => {
      this.appInstallSerialNumber = (result.body as any) ? (result.body as any).serialNumber : null;
    }, error => {
      this.handleError(error);
    });
  }

  autoFillSerialNumber() {
    this.deviceForm.patchValue({
      serialNumber: this.appInstallSerialNumber
    });
  }

  formToObj() {
    return {
      id: this.deviceId,
      siteId: this.siteValue.id,
      serialNumber: this.deviceForm.value.serialNumber,
      appInstanceId: this.deviceForm.value.appInstanceId,
      contactNumber: this.deviceForm.value.contactNumber,
      deviceName: this.deviceForm.value.deviceName,
    };
  }

  upsertDevice() {
    if (this.deviceForm.valid) {
      const actionText = (this.deviceId) ? 'save your changes' : 'create a new device';

      let dialogRef = this.showConfirmationDialog(actionText);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isBusy = true;

          const newDevice = this.formToObj();
          this.deviceService.upsertDevice(newDevice).subscribe((result) => {
            this.showMessage('Device successfully added/updated', MessageType.success);
            this.isBusy = false;
            this.router.navigate([this.PREVIOUS_PAGE]);
          }, error => {
            this.handleError(error);
            this.isBusy = false;
          });
        }
      });
    } else {
      const newDevice = this.formToObj();

      if (!newDevice.contactNumber) {
        this.showMessage('Contact Number Required.', MessageType.error);
      } else if (newDevice.contactNumber.length < 9) {
        this.showMessage('Invalid Contact Number.', MessageType.error);
      } else if (newDevice.contactNumber.length > 13) {
        this.showMessage('Invalid Contact Number.', MessageType.error);
      } else if (!newDevice.serialNumber) {
        this.showMessage('Serial Number Required.', MessageType.error);
      } else if (newDevice.deviceName < 1) {
        this.showMessage('Device Name too short.', MessageType.error);
      } else if (newDevice.deviceName > 29) {
        this.showMessage('Device Name too Long.', MessageType.error);
      }
    }
  }

  listenForGpsTracking() {
    this.socketService.getSocket().on(`${EVENT_TOPICS.DEVICE_LOCATION}.${EVENT_TYPES.DEVICE_LOCATION}`, (event) => {
      if (!event) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + `${EVENT_TOPICS.DEVICE_LOCATION}.${EVENT_TYPES.DEVICE_LOCATION}`);
        return;
      }
      if (Number(event.deviceId) === this.deviceId) {
        this.updateDeviceCurrentLocation(event.latitude, event.longitude, true);
      }
    });
  }

  ngOnDestroy(): void {
    this.socketService.getSocket().off(`${EVENT_TOPICS.DEVICE_LOCATION}.${EVENT_TYPES.DEVICE_LOCATION}`);
  }

  updateDeviceCurrentLocation(latitude, longitude, shouldCenterMap = false) {
    if (latitude && longitude) {
      this.deviceCurrentLocation.latitude = latitude;
      this.deviceCurrentLocation.longitude = longitude;
    }
  }

  updateLiveLocationMap(centerMap) {
    this.deviceMapMarker = {
      position: {
        lat: Number(this.deviceCurrentLocation.latitude),
        lng: Number(this.deviceCurrentLocation.longitude)
      }
    };

    if (centerMap) {
      this.mapCenter = {
        lat: Number(this.deviceCurrentLocation.latitude),
        lng: Number(this.deviceCurrentLocation.longitude)
      };
    }
  };

  getBatteryStatus() {
    if (this.isScaleFusionDevice) {
      this.isBusyGettingBattery = true;
      this.deviceService.getDeviceBattery(this.deviceForm.value.serialNumber).subscribe(result => {
        this.deviceBatteryStatus = (result.body as any).batteryStatus;
        this.deviceIsCharging = (result.body as any).isCharging;

        this.isBusyGettingBattery = false;
      }, error => {
        this.handleError(error);
        this.isBusyGettingBattery = false;
      });
    } else {
      this.showMessage('Device is not registered on Scale Fusion', MessageType.error);
    }
  }

  rebootDevice() {
    if (this.isScaleFusionDevice) {
      this.isBusyRebooting = true;
      this.deviceService.rebootDevice(this.deviceForm.value.serialNumber).subscribe(result => {
        this.showMessage('Device successfully rebooted', MessageType.success);
        this.isBusyRebooting = false;
      }, error => {
        this.handleError(error);
        this.isBusyRebooting = false;
      });
    } else {
      this.showMessage('Device is not registered on Scale Fusion', MessageType.error);
    }
  }

  lockDevice() {
    if (this.isScaleFusionDevice) {
      this.isBusyLocking = true;
      this.deviceService.lockDevice(this.deviceForm.value.serialNumber).subscribe(result => {
        this.showMessage('Device successfully locked', MessageType.success);
        this.isBusyLocking = false;
      }, error => {
        this.handleError(error);
        this.isBusyLocking = false;
      });
    } else {
      this.showMessage('Device is not registered on Scale Fusion', MessageType.error);
    }
  }

  unlockDevice() {
    if (this.isScaleFusionDevice) {
      this.isBusyUnlocking = true;
      this.deviceService.unlockDevice(this.deviceForm.value.serialNumber).subscribe(result => {
        this.showMessage('Device successfully unlocked', MessageType.success);
        this.isBusyUnlocking = false;
      }, error => {
        this.handleError(error);
        this.isBusyUnlocking = false;
      });
    } else {
      this.showMessage('Device is not registered on Scale Fusion', MessageType.error);
    }
  }

  getScaleFusionDeviceInfo() {
    if (this.deviceForm && this.deviceForm.value && this.deviceForm.value.serialNumber) {
      this.isBusyLocking = true;
      this.isBusyUnlocking = true;
      this.isBusyRebooting = true;
      this.deviceService.getScaleFusionStatus(this.deviceForm.value.serialNumber).subscribe(result => {
        this.isScaleFusionDevice = (result.body as any).isRegistered;

        if (this.isScaleFusionDevice) {
          this.getBatteryStatus();
        }

        this.isBusyLocking = false;
        this.isBusyUnlocking = false;
        this.isBusyRebooting = false;
      }, error => {
        this.isBusyLocking = false;
        this.isBusyUnlocking = false;
        this.isBusyRebooting = false;

        this.handleError(error);
      });
    }
  }

  getDeviceName() {
    return (this.deviceId) ? this.deviceForm.value.deviceName : 'New Device';
  }

  private getSite() {
    this.siteValue = this.storageService.getCurrentSite();
  }

  deleteDevice() {
    let dialogRef = this.showConfirmationDialog('delete that device', null, 'delete');

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isBusy = true;
        this.deviceService.deleteDevice(this.deviceId).subscribe(result => {
          this.showMessage('Device deleted', MessageType.success);
          this.storageService.removeSelectedDevice();
          this.router.navigate([this.PREVIOUS_PAGE]);
        }, error => {
          this.isBusy = false;
          this.handleError(error);
        });
      }
    });
  }

  getDeviceLastLocation() {
    if (this.deviceId) {
      this.deviceService.getDeviceLastLocation(this.deviceId).pipe(finalize(() => {
        this.loadMap()
      })).subscribe((result) => {
        const locationData = result.body as any;

        this.updateDeviceCurrentLocation(locationData.latitude, locationData.longitude, true);
      });
    }
  }

  private loadMap(): void {
    this.map = L.map('map').setView([Number(this.deviceCurrentLocation.latitude), Number(this.deviceCurrentLocation.longitude)], 16);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 18,
      tileSize: 512,
      zoomOffset: -1,
    }).addTo(this.map);

    this.map.flyTo([Number(this.deviceCurrentLocation.latitude), Number(this.deviceCurrentLocation.longitude)], 16);
    this.setMarker(new L.LatLng(Number(this.deviceCurrentLocation.latitude), Number(this.deviceCurrentLocation.longitude)));
  }

  setMarker(position : L.LatLng) {
    if(this.marker) {
      this.marker.setLatLng(position);
    }
    else {
      const icon = L.icon({
        iconUrl: '../../../assets/images/maps/map-marker.png',
        iconSize: [50, 50],
        iconAnchor: [25, 30],
        popupAnchor: [0, -41],
      });
 
      this.marker = L.marker(position, {
        icon: icon,
        draggable: false
      }).bindPopup('Device Location');
      this.marker.addTo(this.map);
    }
  }
}
