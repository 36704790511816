import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject ('ChatMessage')
export class ChatMessage {

  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('message', String, true)
  public message: string = undefined;

  @JsonProperty('senderName', String, true)
  public senderName: string = undefined;

  @JsonProperty('createdAt', Number, true)
  public createdAt: number = undefined;

  @JsonProperty('userId', Number, true)
  public userId: number = undefined;

  @JsonProperty('deviceId', Number, true)
  public deviceId: number = undefined;

  @JsonProperty('siteId', Number, true)
  public siteId: number = undefined;

  @JsonProperty('imageUrl', String, true)
  public imageUrl: string = undefined;

}
