<div id="alerts-container" class="column">
  <div class="row">
    <div id="alerts-red" class="card flex-1">
      <div class="card-header">
        <span>Red Alerts</span>
        <span class="alert-count bg-alert-red"><span class="alert-count-inner">{{redAlerts.length}}</span></span>
      </div>
      <div class="card-body" (scroll) = "onAlertScroll($event,ALERT_COLOR_CLASSIFICATIONS.RED)"  #redAlertsTab>
        <app-alert-item *ngFor="let redAlert of redAlerts;trackBy: trackByFn" [alert]="redAlert"
                        (click)="openAlertInfoDialog(redAlert, ALERT_COLOR_CLASSIFICATIONS.RED)" #redItems></app-alert-item>
      </div>
    </div>
    <div id="alerts-misc" class="card flex-1">
      <div class="card-header">
        <span>Miscellaneous Alerts</span>
        <span class="alert-count bg-alert-misc"><span class="alert-count-inner">{{miscAlerts.length}}</span></span>
      </div>
      <div class="card-body" (scroll) = "onAlertScroll($event,ALERT_COLOR_CLASSIFICATIONS.MISC)"  #miscAlertsTab>
        <app-alert-item *ngFor="let miscAlert of miscAlerts;trackBy: trackByFn" [alert]="miscAlert"
                        (click)="openAlertInfoDialog(miscAlert, ALERT_COLOR_CLASSIFICATIONS.MISC)" #miscItems></app-alert-item>
      </div>
    </div>
  </div>
  <div class="row">
    <div id="alerts-yellow" class="card flex-1">
      <div class="card-header">
        <span>Yellow Alerts</span>
        <span class="alert-count bg-alert-yellow"><span class="alert-count-inner">{{yellowAlerts.length}}</span></span>
      </div>
      <div class="card-body" (scroll) = "onAlertScroll($event,ALERT_COLOR_CLASSIFICATIONS.YELLOW)"  #yellowAlertsTab>
        <app-alert-item *ngFor="let yellowAlert of yellowAlerts;trackBy: trackByFn" [alert]="yellowAlert"
                        (click)="openAlertInfoDialog(yellowAlert, ALERT_COLOR_CLASSIFICATIONS.YELLOW)" #yellowItems></app-alert-item>
      </div>
    </div>
    <div id="alerts-green" class="card flex-1" >
      <div class="card-header">
        <span>Green Alerts</span>
        <span class="alert-count bg-alert-green"><span class="alert-count-inner">{{greenAlerts.length}}</span></span>
      </div>
      <div class="card-body" (scroll) = "onAlertScroll($event,ALERT_COLOR_CLASSIFICATIONS.GREEN)"  #greenAlertsTab>
        <app-alert-item *ngFor="let greenAlert of greenAlerts;trackBy: trackByFn" [alert]="greenAlert"
                        (click)="openAlertInfoDialog(greenAlert, ALERT_COLOR_CLASSIFICATIONS.GREEN)" #greenItems></app-alert-item>
      </div>
    </div>
  </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
