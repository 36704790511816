import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {StorageService} from '../../services/storage-service';
import {ChatMessage} from '../../models/ChatMessage';
import {ChatService} from '../../services/chat-service';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {Employee} from '../../models/Employee';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SendImageViewComponent} from '../send-image-view/send-image-view.component';
import {EVENT_TOPICS, EVENT_TYPES} from '../../enums/EventTypes';
import {SocketService} from '../../services/socketService';
import {interval, Subject} from 'rxjs';
import {Site} from '../../models/Site';

@Component({
  selector: 'app-site-chat',
  templateUrl: './site-chat.component.html',
  styleUrls: ['./site-chat.component.css']
})
export class SiteChatComponent extends ComponentBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  public senderName: string;
  public messageListStatus: string = '';
  public siteName: string = '';
  public messageList: ChatMessage[] = [];
  public currentEmployee: Employee;
  public hasSiteDataIn: Boolean = false;
  public isScrollAtBottom: boolean = true;
  public isPriorMessages: boolean = true;
  public currentSite: Site = null;
  public maxMessagesCount: number = 0;
  public unseenMessagesCount: number = 0;
  public message: string = '';
  private timer: any;
  private scrollContainer: any;

  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;
  @Input() resetFormSubject: Subject<Object> = new Subject<Object>();

  constructor(
    protected storageService: StorageService,
    private chatService: ChatService,
    protected snackBar: MatSnackBar,
    protected dialog: MatDialog,
    protected router: Router,
    private socketService: SocketService
  ) {
    super(snackBar, dialog, router, storageService);
  }

  trackByFn(index, item) {
    return item.id;
  }

  ngOnInit(): void {
    this.currentSite = this.storageService.getSelectedChatChannelSiteData();
    this.hasSiteDataIn = this.currentSite !== null;
    if (this.hasSiteDataIn) {
      this.startUp(this.currentSite.id, this.currentSite.name);
    }

    this.resetFormSubject.subscribe(response => {
      if (response) {
        console.log(response);
        let res = response as any;
        this.hasSiteDataIn = !!this.currentSite;

        if (this.hasSiteDataIn) {
          let shouldSwitchPreviousSocketOff = res.oldSiteId === this.currentSite.id;
          if (shouldSwitchPreviousSocketOff) {
            this.socketService.getSocket().off(`site.${res.oldSiteId}.${EVENT_TOPICS.CHAT}.${EVENT_TYPES.MESSAGE}`);
          }
        }

        this.currentSite = this.storageService.getSelectedChatChannelSiteData();
        this.startUp(this.currentSite.id, this.currentSite.name);
      }
    });

    this.timer = interval(10000)
      .subscribe((val) => {
        if (this.isScrollAtBottom && this.currentSite) {
          this.reload(false);
        }
      });
  }

  startUp(newSiteId, newSiteName) {
    this.isScrollAtBottom = true;
    this.messageListStatus = '';
    this.siteName = newSiteName;

    if (this.currentSite) {
      this.currentEmployee = this.storageService.getLoggedInEmployee();
      this.senderName = this.currentEmployee.name + ' ' + this.currentEmployee.surname;
      this.reload(false);
    }
  }

  onScroll(event): void {
    let scrollView = event.target;
    this.isScrollAtBottom = Math.ceil(scrollView.scrollTop) === scrollView.scrollHeight - scrollView.clientHeight;
    if (this.isScrollAtBottom) {
      this.unseenMessagesCount = 0;
    }
  }

  ifImageShowPreview(message) {
    if (message.imageUrl) {
      this.openDialog(true, message.imageUrl);
    }
  }

  public scrollToBottom() {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
    this.isScrollAtBottom = true;
    this.unseenMessagesCount = 0;
    this.messageListStatus = '';
  }

  public scrollToTop() {
    this.scrollContainer.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.isScrollAtBottom = false;
  }

  getMessages(createdAt, shouldGetPrevious) {
    this.chatService.getChats(this.currentSite.id, createdAt).subscribe((result) => {
      const jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      let messagesReturned = jsonConvert.deserializeArray(result.body as any, ChatMessage);

      if (shouldGetPrevious) {
        let recentMessages = this.messageList;
        this.messageList = messagesReturned.concat(recentMessages);
        this.scrollToTop();
      } else {
        this.messageList = messagesReturned;
        this.messageListStatus = (this.messageList.length > 0) ? '' : '... No Chats ...';
        this.scrollToBottom();
      }

      this.messageListStatus = (this.maxMessagesCount == this.messageList.length) ? '... No Prior Chats ...' : '';
      this.isPriorMessages = !(this.messageListStatus === '... No Prior Chats ...' || this.messageListStatus === '... No Chats ...');

      this.maxMessagesCount = this.messageList.length;
    });
  }

  ngAfterViewInit(): void {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe(_ => {
      if (this.isScrollAtBottom) {
        this.scrollToBottom();
      }
    });
    this.scrollToBottom();
  }

  sendTextMessage() {
    let message = new ChatMessage();
    message.siteId = this.currentSite.id;
    message.senderName = this.senderName;
    message.message = this.message;
    message.userId = this.currentEmployee.id;

    if (message.message == ''){
      this.showMessage('Cannot send empty message.',MessageType.warning)
    }else {
      this.chatService.insertChat(message).subscribe(result => {
        this.message = '';
      }, error => {
        console.log(error);
        this.showMessage('Message not sent. Please try again.', MessageType.warning);
      });
    }
  }

  reload(shouldGetPrevious) {
    this.messageListStatus = '';
    let createdAt;

    let latestCreatedAt = this.messageList[0] ? this.messageList[0].createdAt : 0;

    createdAt = (shouldGetPrevious) ? latestCreatedAt : 0;

    this.getMessages(createdAt, shouldGetPrevious);
  }

  openDialog(isPreview, url = '') {
    let data;
    if (isPreview) {
      data = {
        url,
        isPreview
      };
    } else {
      data = {
        siteId: this.currentSite.id,
        senderName: this.currentEmployee.name + ' ' + this.currentEmployee.surname,
        userId: this.currentEmployee.id,
        isPreview
      };
    }

    let removalDialog = this.dialog.open(SendImageViewComponent, {
      disableClose: true,
      height: '80%',
      width: '75%',
      data
    });

    removalDialog.afterClosed().subscribe((result) => {
      if (result.message) {
        this.showMessage(result.message, result.messageType);
      }
    });
  }

  ngOnDestroy(): void {
    this.timer.unsubscribe();
  }

  typing($event) {
    // TODO we can implement this feature later
    // let value = $event.target.value;
    // this.isTyping = !!(value && value.trim());
    // this.socketService.userStartedTyping(this.siteId,this.senderName);
  }

  //Called from company-chat.component.ts
  newMessageFromSocket(message){
    const jsonConvert = new JsonConvert();

    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    const incomingMessage = jsonConvert.deserializeObject(message as any, ChatMessage);

    let stateOfScrollBeforeNewMessage = (this.scrollContainer.scrollHeight - this.scrollContainer.scrollTop) === this.scrollContainer.clientHeight;
    this.messageList.push(incomingMessage);

    this.unseenMessagesCount++;
    if (stateOfScrollBeforeNewMessage) {
      this.scrollToBottom();
    }
  }
}
