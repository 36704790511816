import {JsonObject, JsonProperty} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('Schedule')
export class Schedule {
  get localStartTimeDay(): string {
    const today = moment.utc(new Date());
    const momentResult = moment(new Date(`${today.format('YYYY-MM-DD')}T${this.startTimeDay.split(':')[0]}:${this.startTimeDay.split(':')[1]}:00Z`))
    const local = moment(momentResult).local().format('HH:mm');
    return local;
  }

  get localStartTimeNight(): string {
    const today = moment.utc(new Date());
    const momentResult = moment(new Date(`${today.format('YYYY-MM-DD')}T${this.startTimeNight.split(':')[0]}:${this.startTimeNight.split(':')[1]}:00Z`))
    const local = moment(momentResult).local().format('HH:mm');
    return local;
  }

  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('routeId', Number, true)
  public routeId: number = undefined;

  @JsonProperty('routeName', String, true)
  public routeName: string = undefined;

  @JsonProperty('siteId', Number, true)
  public siteId: number = undefined;

  @JsonProperty('repeatMinutes', Number, true)
  public repeatMinutes: number = undefined;

  @JsonProperty('sunday', Boolean, true)
  public sunday: Boolean = undefined;

  @JsonProperty('monday', Boolean, true)
  public monday: Boolean = undefined;

  @JsonProperty('tuesday', Boolean, true)
  public tuesday: Boolean = undefined;

  @JsonProperty('wednesday', Boolean, true)
  public wednesday: Boolean = undefined;

  @JsonProperty('thursday', Boolean, true)
  public thursday: Boolean = undefined;

  @JsonProperty('friday', Boolean, true)
  public friday: Boolean = undefined;

  @JsonProperty('saturday', Boolean, true)
  public saturday: Boolean = undefined;

  @JsonProperty('randomizeStart', Boolean, true)
  public randomizeStart: Boolean = undefined;

  @JsonProperty('startTimeDay', String, true)
  public startTimeDay: string = undefined;

  @JsonProperty('endTimeDay', String, true)
  public endTimeDay: string = undefined;

  @JsonProperty('startTimeNight', String, true)
  public startTimeNight: string = undefined;

  @JsonProperty('endTimeNight', String, true)
  public endTimeNight: string = undefined;

}
