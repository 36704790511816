<div class="card">
    <div class="card-header">
        <div class="row">         
            <label class="select-Styling">Select a Device:</label>

            <mat-form-field>
                <mat-select [(ngModel)]="selectedDevice">
                    <mat-option *ngFor="let device of devices" [value]="device">
                        {{device.deviceName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <span class="divide-text"></span>

            <label class="select-Styling">Select Report Date Range: </label>

            <div class="input-group" [matTooltip]="'Date starts at shift time (' + getShiftStartTimeString() + ')'">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="padding: 0"><mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle></span>
                </div>
                <input class="form-control" style="height: auto" [matDatepicker]="startDatePicker" [(ngModel)]="startDate">
                <mat-datepicker #startDatePicker></mat-datepicker>
            </div>

            <span style="margin: auto 10px">to</span>

            <div class="input-group" [matTooltip]="'Date starts at shift time (' + getShiftStartTimeString() + ')'">
                <div class="input-group-prepend">
                    <span class="input-group-text" style="padding: 0"><mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle></span>
                </div>
                <input class="form-control" style="height: auto" [matDatepicker]="endDatePicker" [(ngModel)]="endDate">
                <mat-datepicker #endDatePicker></mat-datepicker>
            </div>

            <span class="divide-text"></span>

            <button class="btn btn-icon mr-2 refresh-btn" (click)="RetrieveData()">
                <span class="ul-btn__text">Refresh</span>
                <span class="ul-btn__icon"><mat-icon>refresh</mat-icon></span>
            </button>

            <div class="playback-buttons">
                <button type="button" class="btn-primary" (click)="playbackForSelectedDevice('stop')" title="Stop">
                    <mat-icon class="action-btns">stop</mat-icon>
                </button>
                <button type="button" class="btn-primary" (click)="playbackForSelectedDevice('pause')" title="Pause">
                    <mat-icon class="action-btns">pause</mat-icon>
                </button>
                <button type="button" class="btn-primary" (click)="playbackForSelectedDevice('play')" title="Play">
                    <mat-icon class="action-btns">play_arrow</mat-icon>
                </button>
                <button type="button" class="btn-primary" (click)="playbackForSelectedDevice('fastForward')" title="Forward">
                    <mat-icon class="action-btns">fast_forward</mat-icon>
                </button>
                <button type="button" class="btn-primary" (click)="toggleLiveLocation()" title="Current live location">
                    <mat-icon class="action-btns">location_on</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div id="map" class="historyMap-Styling" #leafletmap></div>

    <table mat-table [dataSource]="dataSource" class="table-hover">
        <ng-container matColumnDef="sentAt">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element"> {{formatDateTime(element.sentAt)}} </td>
        </ng-container>
        <ng-container matColumnDef="latitude">
            <th mat-header-cell *matHeaderCellDef>Latitude</th>
            <td mat-cell *matCellDef="let element"> {{element.latitude}} </td>
        </ng-container>
        <ng-container matColumnDef="longitude">
            <th mat-header-cell *matHeaderCellDef>Longitude</th>
            <td mat-cell *matCellDef="let element"> {{element.longitude}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayHistoryColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayHistoryColumns; let i = index" (click)="showOnMap(deviceLocations[i])"></tr>
    </table>
</div>

<mat-paginator #DeviceLocation_paginator
    [length] = "deviceLocations?.length"
    [pageSize] = "10"
    [pageSizeOptions] = "[10, 25, 100]">
</mat-paginator>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>

<span class="bottom-padding"></span>