import { Component, OnInit } from '@angular/core';
import {Route} from '../../models/Route';
import {StorageService} from '../../services/storage-service';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {RouteTag} from '../../models/RouteTag';
import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {RouteService} from '../../services/route-service';
import {ComponentBaseComponent} from '../../component-base';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Site} from "../../models/Site";

@Component({
  selector: 'app-route-summary',
  templateUrl: './route-summary.component.html',
  styleUrls: ['./route-summary.component.scss']
})
export class RouteSummaryComponent extends ComponentBaseComponent implements OnInit {
  routes: Route[] = [];
  site: Site;
  tags: RouteTag[] = [];
  markers: any[] = [];

  displayedColumns = ['name', 'duration', 'image'];

  promises: Observable<HttpResponse<Object>>[] = [];

  duration: number = 0.0;

  center: google.maps.LatLngLiteral;

  isBusy: boolean = false;
  checked: boolean = false;
  zoom = 12;
  currEmployeeRole: string;

  constructor(
    protected storageService: StorageService,
    public routeService: RouteService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    public router: Router
  ) {
    super(snackbar, dialog, router,storageService);
    this.site = this.storageService.getCurrentSite();
    this.getAllRoutesBySite();
  }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
  }

  getAllRoutesBySite() {
    this.isBusy = true;
    this.routeService.getAllRoutesBySiteId(this.site.id).subscribe(res => {
      let jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.routes = jsonConvert.deserializeArray(res.body as any, Route);
      this.isBusy = false;
    }, error => {
      this.isBusy = false;
      this.handleError(error);
    });
  }

  selectRoute(route: Route) {
    const selectedRoute: Route = (route) ? route : null;

    this.storageService.setSelectedRoute(selectedRoute);

    if (selectedRoute) {
      this.router.navigate(['company/site/xtra/route/' + selectedRoute.id]);
    } else {
      this.router.navigate(['company/site/xtra/route/new']);
    }
  }

}
