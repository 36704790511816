import {Injectable} from '@angular/core';
import {CommonService} from './commonService';
import {Area} from '../models/Area';

@Injectable({
  providedIn: 'root'
})

export class AreaService {
  constructor(
    private commonService: CommonService
  ) {}

  getAreasByCompany(companyId: number) {
    return this.commonService.getData('/api/v1/area/byCompany/' + companyId);
  }

  upsertArea(area: Area) {
    return this.commonService.postData('/api/v1/area', area);
  }
}
