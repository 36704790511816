export const validEmail = (emailAddress: string) => {
  if (emailAddress) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(emailAddress);
  }
  return false;
}

export const validNumber = (number: string) => {
  if  (number.startsWith('27') ||
      number.startsWith('+27') || 
      number.startsWith('264') || 
      number.startsWith('+264') || 
      number.startsWith('267') || 
      number.startsWith('+267') || 
      number.startsWith('268') || 
      number.startsWith('+268') || 
      number.startsWith('233') || 
      number.startsWith('+233')) {
    return true
  }
  return false
}
