import {Injectable} from '@angular/core';
import {CommonService} from './commonService';


@Injectable({
  providedIn: 'root'
})

export class AttendanceService {
    constructor(
        private commonService: CommonService
      ) {
      }

    getAttendanceDataBySite(siteId, startDate, endDate) {
      return this.commonService.postData('api/v1/attendance/retrievedatabysiteid', {siteId, startDate, endDate});
    }
}