import {Component, OnInit} from '@angular/core';
import {ComponentBaseComponent, MessageType} from "../../component-base";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StorageService} from "../../services/storage-service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {CompanyService} from "../../services/company-service";
import {Company} from "../../models/Company";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent extends ComponentBaseComponent implements OnInit {
  companyForm: FormGroup;
  companyId: number;
  selectedCompany: Company;

  isBusy = false;

  PREVIOUS_PAGE = 'companies';

  constructor(
    private companyService: CompanyService,
    protected storageService: StorageService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialog,
    public snackbar: MatSnackBar,
    public router: Router,
  ) {
    super(snackbar, dialogRef, router,storageService)
  }

  ngOnInit(): void {
    this.prepareForm();
    this.getCompany();
  }

  prepareForm() {
    this.companyForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(128)]],
      address: ['', [Validators.minLength(10), Validators.maxLength(1024)]],
      contactPerson: ['', [Validators.maxLength(256), Validators.minLength(3)]],
      contactNumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(11)]],
      contactEmail: ['', Validators.required]
    });
  }

  getCompany() {
    this.isBusy = true;
    this.selectedCompany = this.storageService.getSelectedCompany();
    let isUpdatingCompany = this.selectedCompany != null;

    if (isUpdatingCompany) {
      this.companyId = this.selectedCompany.id;

      this.companyForm.patchValue({
        name: this.selectedCompany.name,
        address: this.selectedCompany.address,
        contactPerson: this.selectedCompany.contactPerson,
        contactNumber: this.selectedCompany.contactNumber,
        contactEmail: this.selectedCompany.contactEmail
      });

    }
    this.isBusy = false;
  }

  formToObj() {
    return {
      id: this.companyId,
      name: this.companyForm.value.name,
      address: this.companyForm.value.address,
      contactPerson: this.companyForm.value.contactPerson,
      contactNumber: this.companyForm.value.contactNumber,
      contactEmail: this.companyForm.value.contactEmail
    };
  }

  updateCompany() {
    if (this.companyForm.valid) {
      const actionText = (this.companyId) ? 'save your changes' : 'create a new company';

      let dialogRef = this.showConfirmationDialog(actionText);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.isBusy = true;

          const newCompany = this.formToObj();
          this.companyService.upsertCompany(newCompany).subscribe((result) => {
            this.showMessage('Company successfully added/updated', MessageType.success);
            this.isBusy = false;
            this.router.navigate([this.PREVIOUS_PAGE]);
          }, error => {
            this.isBusy = false;
            this.handleError(error)
          });
        }
      });
    } else {
      const newCompany = this.formToObj();
      if (newCompany.name.length > 128) {
        this.showMessage('Company name too long.', MessageType.error);
      } else if (newCompany.name.length <= 3) {
        this.showMessage('Company name too short.', MessageType.error);
      } else if (newCompany.address.length > 1024) {
        this.showMessage('Company address too long..', MessageType.error);
      } else if (newCompany.address.length < 10) {
        this.showMessage('Company address too short.', MessageType.error);
      } else if (newCompany.contactNumber.length != 10) {
        this.showMessage('Invalid Contact Number.', MessageType.error);
      } else if (newCompany.contactPerson.length <= 3) {
        this.showMessage('The Contact Person name to short.', MessageType.error);
      } else if (newCompany.contactPerson.length > 256) {
        this.showMessage('The Contact Person name too long', MessageType.error);
      }
    }
  }

  companyDelete() {
    let dialogRef = this.showConfirmationDialog('delete that company', null, 'delete');

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isBusy = true;

        this.companyService.deleteCompany(this.companyId).subscribe(() => {
          this.showMessage('Company successfully removed', MessageType.success);
          this.isBusy = false;
          this.router.navigate([this.PREVIOUS_PAGE]);
        }, error => {
          this.handleError(error);
          this.isBusy = false;
        });
      }
    });
  }

}
