import { Component, OnInit } from '@angular/core';
import {DeviceService} from '../../services/device-service';
import {Device} from '../../models/Device';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {StorageService} from '../../services/storage-service';
import {Router} from '@angular/router';
import {Site} from '../../models/Site';
import {ComponentBaseComponent} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-device-summary',
  templateUrl: './device-summary.component.html',
  styleUrls: ['./device-summary.component.scss']
})
export class DeviceSummaryComponent extends ComponentBaseComponent implements OnInit {
  PREVIOUS_PAGE = '';

  displayedColumns = ['deviceName' ,'serialNumber', 'contactNumber', 'site', 'isActivated', 'image'];

  devices: Device[];
  siteName: string;
  currEmployeeRole: string;
  isBusy = false;

  constructor(
    private deviceService: DeviceService,
    protected storageService: StorageService,
    public router: Router,
    public snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { super(snackbar, dialog, router,storageService) }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.getDevices();
  }

  getDevices() {
    this.isBusy = true;

    const siteId = this.storageService.getCurrentSite().id;

    this.deviceService.getAllDevicesBySite(siteId).subscribe((result) => {

      // this.siteName = (result.body as any)[0].siteName;

      const jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.devices = [];
      for(let data of result.body as any) {
        let device: Device = new Device();
        device = jsonConvert.deserialize(data, Device) as Device;
        device.site = new Site();
        device.site.id = data.siteId;
        device.site.name = data.siteName;

        this.devices.push(device);
      }
      this.isBusy = false;
    }, error => {
      this.isBusy = false;
      this.handleError(error);
    });
  }

  selectDevice(device) {
    const selDevice = (device) ? device : new Device();
    this.storageService.setSelectedDevice(selDevice);
    const id = (device) ? device.id : 'new';
    this.router.navigate(['company/site/xtra/device/' + id]);
  }

}
