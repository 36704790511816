<div class="app-admin-wrap layout-sidebar-large">
  <!-- TOP BAR -->
  <div class="main-header">
    <div class="logo">
      <img src="../../../assets/dist-assets/images/Navigation.png" alt="" style="height: 50px; width: auto; margin-left: 15px">
    </div>

    <div style="margin: auto"></div>
    <div class="header-part-right">
      <button class="btn mr-2 btnSignOut" (click)="logout()" matTooltip="Sign Out">
      <span>
      <mat-icon class="ul-btn__icon" aria-hidden="false"  aria-label="Sign Out">exit_to_app</mat-icon>
      </span>
      <span class="ul-btn__text">Sign Out</span>
      </button>
    </div>
  </div>
  <!-- =============== Left side End ================-->
  <div class="main-content-wrap  d-flex flex-column">
    <!-- ============ Body content start ============= -->
    <div class="main-content">
      <router-outlet></router-outlet>
      <!-- end of main-content -->
    </div>
  </div>
</div>
