import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Boundaries')
export class Boundaries {
    @JsonProperty('id', Number, true)
    public id: number = undefined;

    @JsonProperty('latitude', Number, true)
    public latitude: number = undefined;

    @JsonProperty('longitude', Number, true)
    public longitude: number = undefined;
}
