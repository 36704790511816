import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('RouteTag')
export class RouteTag {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('name', String, true)
  public name: string = undefined;

  @JsonProperty('serialNumber', String, true)
  public serialNumber: string = undefined;

  @JsonProperty('longitude', Number, true)
  public longitude: number = undefined;

  @JsonProperty('latitude', Number, true)
  public latitude: number = undefined;

  @JsonProperty('siteId', Number, true)
  public siteId: number = undefined;

  @JsonProperty('position', Number, true)
  public position: number = undefined;

  @JsonProperty('tagToRouteId', Number, true)
  public tagToRouteId: number = undefined;

  @JsonProperty('isPanic', Boolean, true)
  public isPanic: boolean = undefined;
}
