<div class="action-bar" *ngIf="currEmployeeRole !== 'controller'">
  <button class="btn btn-icon mr-2" (click)="siteSelect(null)">
    <span class="ul-btn__text">Add a new Site</span>
    <span class="ul-btn__icon"><mat-icon>add</mat-icon></span>
  </button>
</div>

<div class="card">
  <div class=" card-header">
    <input #searchInput (keyup)="onSearchKeyUp(searchInput.value)" placeholder="Search site name" class="form-control " style="width: 20%">
  </div>
  <table mat-table [dataSource]="dataSource" class="table-hover">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"><img src="../../../assets/dist-assets/images/Sites.png"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> Address</th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef> Company Name</th>
      <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <mat-icon class="icon">chevron_right</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"
        (click)="siteSelect(row)"></tr>
  </table>

</div>

<mat-paginator #MatPaginator
               [length] = "sites?.length"
               [pageSize] = "10"
               [pageSizeOptions] = "[10, 25, 100]">
</mat-paginator>


<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
