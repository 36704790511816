import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBaseComponent, MessageType } from '../../component-base';
import { SiteServiceService } from '../../services/site.service';
import { Site } from '../../models/Site';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteContact } from '../../models/SiteContact';
import { StorageService } from '../../services/storage-service';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { Area } from '../../models/Area';
import { AreaService } from '../../services/area.service';
import { Employee } from '../../models/Employee';
import { validEmail, validNumber } from "../../logic/commonLogic";
import * as L from 'leaflet';
import 'leaflet-draw';
import { BehaviorSubject } from 'rxjs';
import { Geometry } from 'ngx-google-places-autocomplete/objects/geometry';
import { Boundaries } from '../../models/Boundaries';

@Component({
    selector: 'app-site-detail',
    templateUrl: './site-detail.component.html',
    styleUrls: ['./site-detail.component.scss']
})
export class SiteDetailComponent extends ComponentBaseComponent implements OnInit, AfterViewInit {
    PREVIOUS_PAGE = 'company/sites';
    DEFAULT_PAGE_AFTER_SAVE = 'company/site/xtra/details';
    DEFAULT_MAP_LOCATION = {
        //Default to 10 South Blvd, Bruma, Johannesburg, 2026, South Africa
        latitude: -26.179272010742256,
        longitude: 28.110825061012296
    };

    @ViewChild('searchAddress', { static: false })
    public addressSearchElementRef: ElementRef;
    @ViewChild('leafletmap')
    private mapElement: ElementRef;

    currentSite: Site;
    isBusy: boolean = false;
    siteFormGroup: FormGroup;
    siteContacts: SiteContact[] = new Array();
    branchOptions = [];
    divisionOptions = [];
    areas: Area[] = [];
    companyId: number;
    mapMarker;
    mapCenter;
    currEmployeeRole: string;
    loggedInEmployee: Employee;

    map: any;
    deviceCurrentLocation = {
        latitude: 0,
        longitude: 0
    };
    polygonPoints: string = "";
    polygonPointsGeoJSON: string = "";
    mapmarkersPin: any;
    drawnItems: any;
    _mapSize = new BehaviorSubject<any>({});
    polygonType: string;
    shapes = [];
    Geostring: string = "";
    polygon: any;
    radius: any;
    polyLayer: any;
    addressBusy: boolean = true;
    circleLayer: any;
    baseLayer: any;
    icons = L.icon({
        iconUrl: '../../../assets/images/maps/map-marker.png',
        iconSize: [50, 50],
        iconAnchor: [25, 30],
        popupAnchor: [0, -41],
    });
    constructor(
        protected router: Router,
        protected matSnackBar: MatSnackBar,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        public siteService: SiteServiceService,
        private fb: FormBuilder,
        protected storageService: StorageService,
        private ngZone: NgZone,
        private areaService: AreaService
    ) {
        super(matSnackBar, dialog, router, storageService);
    }

    ngOnInit(): void {
        this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
        this.companyId = this.storageService.getSelectedCompany().id;
        this.currentSite = this.storageService.getCurrentSite();
        this.prepareForms();

        this.getAreas();
        this.getDivisionOptions();
        this.getBranchOptions();
    }

    ngAfterViewInit(): void {
        this.loadLeafletMap();
        this.getAllDataAndPrepareForm();
        //----Note: Only Commented out for now, because unsure if needed down the line.----
        //this.setupMaps();
    }

    getAreas() {
        this.isBusy = true;
        const companyId = this.storageService.getSelectedCompany().id;

        this.areaService.getAreasByCompany(companyId).subscribe(result => {
            let jsonConvert = new JsonConvert();
            jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

            this.areas = jsonConvert.deserializeArray((result as any).body, Area);
            this.isBusy = false;
        }, error => {
            this.handleError(error);
            this.isBusy = false;
        });
    }

    getBranchOptions() {
        this.isBusy = true;

        this.siteService.getSiteBranchOptions(this.companyId).subscribe(result => {
            let branchOptions = result.body as [];

            for (let branchOption of branchOptions) {
                this.branchOptions.push((branchOption as any).branch);
            }
        }, error => {
            this.handleError(error);
        });
    }

    getDivisionOptions() {
        this.isBusy = true;

        this.siteService.getSiteDivisionOptions(this.companyId).subscribe(result => {
            let divisionOptions = result.body as [];

            for (let divisionOption of divisionOptions) {
                this.divisionOptions.push((divisionOption as any).division);
            }
        }, error => {
            this.handleError(error);
        });
    }

    getAllDataAndPrepareForm() {
        if (this.currentSite.id) {
            this.siteService.getSiteById(this.currentSite.id).subscribe(result => {
                let jsonConvert = new JsonConvert();
                jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

                this.currentSite = jsonConvert.deserializeObject(result.body, Site);
                this.siteContacts = this.currentSite.contacts;

                this.siteFormGroup.patchValue({
                    name: this.currentSite.name,
                    address: this.currentSite.address,
                    latitude: (this.currentSite.latitude) ? this.currentSite.latitude : this.DEFAULT_MAP_LOCATION.latitude,
                    longitude: (this.currentSite.longitude) ? this.currentSite.longitude : this.DEFAULT_MAP_LOCATION.longitude,
                    areaId: this.currentSite.areaId,
                    branch: this.currentSite.branch,
                    division: this.currentSite.division
                });
                if ((this.currentSite.poligonPoints) && (this.currentSite.poligonPoints.length > 0)) {
                    this.Geostring = this.currentSite.shapes;
                    this.polygonPointsGeoJSON = this.currentSite.poligonPoints;
                    this.polygonType = this.currentSite.polygonType;
                    this.polygonPoints = this.currentSite.points;
                    this.setPolygonsOnMap(this.Geostring);
                }
                 this.setMapCenter();
                    this.setMapMarker();
                 }, error => {
                this.handleError(error);
            });
        }
    }

    private prepareForms() {
        this.siteFormGroup = this.fb.group({
            name: ['', Validators.required],
            address: ['', Validators.required],
            latitude: [this.DEFAULT_MAP_LOCATION.latitude, Validators.required],
            longitude: [this.DEFAULT_MAP_LOCATION.longitude, Validators.required],
            division: ['', Validators.required],
            branch: ['', Validators.required],
            areaId: ['', Validators.required]
        });
    }

    updateSite() {
        if (this.validatePage()) {
            const actionText = (this.currentSite.id) ? 'save your changes' : 'create a new site';

            let dialogRef = this.showConfirmationDialog(actionText);

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.isBusy = true;
                    const siteData = this.formToObject();
                    this.storageService.setCurrentSite(siteData);
                    this.siteService.upsertSite(siteData).subscribe(res => {
                        this.isBusy = false;
                        this.showMessage('Site successfully added/updated', MessageType.success);
                        this.router.navigate([this.DEFAULT_PAGE_AFTER_SAVE]);
                    }, error => {
                        this.isBusy = false;
                        this.showMessage('Error occurred .Please try again', MessageType.error);
                        this.handleError(error);
                    });
                }
            });
        }
    }

    private formToObject(): Site {
        const newSite = new Site();

        const siteId = this.currentSite.id;

        newSite.id = (siteId) ? siteId : null;
        newSite.name = this.siteFormGroup.get('name').value;
        newSite.address = this.siteFormGroup.get('address').value;
        newSite.latitude = this.siteFormGroup.get('latitude').value;
        newSite.longitude = this.siteFormGroup.get('longitude').value;
        newSite.division = this.siteFormGroup.get('division').value;
        newSite.branch = this.siteFormGroup.get('branch').value;
        newSite.areaId = this.siteFormGroup.get('areaId').value;
        newSite.companyId = this.companyId;
        newSite.contacts = this.siteContacts;
        newSite.poligonPoints = JSON.stringify(JSON.parse(this.polygonPointsGeoJSON));
        newSite.polygonType = this.polygonType;
        newSite.shapes = JSON.stringify(JSON.parse(this.Geostring));
        newSite.points = JSON.stringify(JSON.parse(this.polygonPoints));
        return newSite;
    }

    private validatePage() {
        let isValid = true;
        if (!this.currentSite.latitude) {
            this.showMessage(`The map does not have any latitude value`, MessageType.error);
            isValid = false;
        }
        if (!this.currentSite.longitude) {
            this.showMessage(`The map does not have any longitude value`, MessageType.error);
            isValid = false;
        }

        if (!this.polygonPoints) {
            this.showMessage(`The map does not have any polygon items.`, MessageType.error);
            isValid = false;
        }

        if (!this.siteFormGroup.invalid) {
            for (let i = 0; i < this.siteContacts.length; i++) {
                let currentSiteContact = this.siteContacts[i];

                if (!currentSiteContact.name || !currentSiteContact.contactNumber) {
                    this.showMessage(`Site contact ${i + 1} has invalid information (name and contact number is required)`, MessageType.error);
                    isValid = false;
                    break;
                } else if (!validEmail(currentSiteContact.emailAddress)) {
                    this.showMessage(`Site contact ${i + 1} has invalid information (email address not valid)`, MessageType.error);
                    isValid = false;
                    break;
                }else if (!validNumber(currentSiteContact.contactNumber)) {
                    this.showMessage(`Site contact ${i + 1} has an invalid contact number`, MessageType.error);
                    isValid = false;
                    break;
                }
            }
        } else {
            this.showMessage('Please fill in all fields', MessageType.success);
        }

        return isValid;
    }

    setMapCenter() {
        this.mapCenter = {
            lat: Number(this.siteFormGroup.controls['latitude'].value),
            lng: Number(this.siteFormGroup.controls['longitude'].value)
        };
    }

    setMapMarker() {
        this.mapMarker = {
            position: {
                lat: Number(this.siteFormGroup.controls['latitude'].value),
                lng: Number(this.siteFormGroup.controls['longitude'].value)
            },
            options: { draggable: false },
        };
    }

    // ----Note: Only Commented out for now, because unsure if needed down the line.----
    // setupMaps() {
    //   let autocomplete = new google.maps.places.Autocomplete(this.addressSearchElementRef.nativeElement, {
    //     types: ['address']
    //   });

    //   autocomplete.addListener('place_changed', () => {
    //     this.ngZone.run(() => {
    //       const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       this.siteFormGroup.patchValue({
    //         longitude: place.geometry.location.lng(),
    //         latitude: place.geometry.location.lat(),
    //         address: place.formatted_address
    //       });
    //       this.setMapCenter();
    //       this.setMapMarker();
    //       this.siteFormGroup.value.address = place.formatted_address;
    //     });
    //   });
    // }

    updateSiteLocation(event) {
        const newLatitude = event.latLng.lat();
        const newLongitude = event.latLng.lng();

        this.siteFormGroup.patchValue({
            longitude: newLongitude,
            latitude: newLatitude
        });
    }

    addSiteContact() {
        if (typeof this.siteContacts == "undefined") {
            this.siteContacts = new Array();
        }
        this.siteContacts.push(new SiteContact());
    }

    removeSiteContact(index) {
        this.siteContacts = this.siteContacts.filter((currentSiteContact, i) => i !== index);
    }

    deleteSite() {
        const actionText = 'Delete site ' + this.currentSite.name;
        let dialogRef = this.showConfirmationDialog(actionText);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const siteId = this.currentSite.id;
                this.deleteSiteServiceCall(siteId);
            }
        });
    }

    deleteSiteServiceCall(siteId) {
        this.siteService.deleteSite(siteId).subscribe((result) => {
            this.showMessage('Site is successfully deleted', MessageType.success);
            this.isBusy = false;
            this.router.navigate(['/company/sites']);
        }, error => {
            this.isBusy = false;
            this.showMessage('An error occurred deleting the site', MessageType.error);
            this.handleError(error);
        });
    }

    /*LeafLet map components*/
    loadLeafletMap(): void {
        if (!this.currentSite.id) {
            this.currentSite.latitude = this.DEFAULT_MAP_LOCATION.latitude;
            this.currentSite.longitude = this.DEFAULT_MAP_LOCATION.longitude;
        }
        if (this.map != undefined) {
            this.map.remove();
        }
        var position = L.latLng([Number(this.currentSite.latitude), Number(this.currentSite.longitude)]);
        this.map = L.map(this.mapElement.nativeElement, { trackResize: true }).setView([Number(this.currentSite.latitude), Number(this.currentSite.longitude)], 16);
        this.deviceCurrentLocation.latitude = this.currentSite.latitude;
        this.deviceCurrentLocation.longitude = this.currentSite.longitude;

        this.baseLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 18,
            tileSize: 512,
            zoomOffset: -1,
        }).addTo(this.map);
        this.drawnItems = new L.FeatureGroup();
        this.map.addLayer(this.drawnItems);
        var drawControl = new L.Control.Draw({
            draw: {
                polyline: false,
                rectangle: false,
                circlemarker: false,
                marker: {
                    icon: this.icons,
                }
            },
            edit: {
                featureGroup: this.drawnItems,
            },
        });

        this.map.addControl(drawControl);
        this.map.flyTo([Number(this.currentSite.latitude), Number(this.currentSite.longitude)], 16);
        this.setMapMarkerPin(new L.LatLng(Number(this.currentSite.latitude), Number(this.currentSite.longitude)));
        var app = this;

        this.map.on(L.Draw.Event.CREATED, function (e) {
            var type = e.layerType,
                layer = e.layer;
            app.drawnItems.addLayer(layer);
            switch (type) {
                case 'marker': {
                    app.checkBoundries(layer._latlng.lat, layer._latlng.lng);
                    layer.bindPopup('A popup!');
                    app.mapmarkersPin.bindPopup("<b>Current point:" + layer._latlng.lat, layer._latlng.lng + " !</b><br>I am a popup.").openPopup();
                    break;
                }
                case 'polygon': {
                    if (app.currentSite.poligonPoints) {
                        let CoordinatesDiffer = app.checkIfPoligonChanged(app.currentSite.poligonPoints, JSON.stringify(layer.toGeoJSON()));
                        if (!CoordinatesDiffer) {
                            let ConfirmMessage = confirm("There are already polygon points saved. Do you want to override them?");
                            if (ConfirmMessage) {
                                let shape = layer.toGeoJSON();
                                let shape_for_db = JSON.stringify(shape);
                                app.Geostring = JSON.stringify(shape.geometry);
                                app.polygonPointsGeoJSON = JSON.stringify(layer.toGeoJSON());
                                app.polygonType = type;
                                app.polygonPoints = JSON.stringify(layer.getLatLngs());
                            }
                        } else {
                            let shape = layer.toGeoJSON();
                            let shape_for_db = JSON.stringify(shape);
                            app.Geostring = JSON.stringify(shape.geometry);
                            app.polygonPointsGeoJSON = JSON.stringify(layer.toGeoJSON());
                            app.polygonType = type;
                            app.polygonPoints = JSON.stringify(layer.getLatLngs());
                        }
                    }else {
                            let shape = layer.toGeoJSON();
                            let shape_for_db = JSON.stringify(shape);
                            app.Geostring = JSON.stringify(shape.geometry);
                            app.polygonPointsGeoJSON = JSON.stringify(layer.toGeoJSON());
                            app.polygonType = type;
                            app.polygonPoints = JSON.stringify(layer.getLatLngs());
                        }
                    break;
                }
                case 'circle':
                    {
                        let shape = layer.toGeoJSON();
                        let shape_for_db = JSON.stringify(shape);
                        app.Geostring = JSON.stringify(shape.geometry);
                        app.polygonType = type;
                        app.polygonPoints = JSON.stringify(layer.getLatLng());
                        var radius = layer.getRadius();
                        var geoJSON = layer.toGeoJSON();
                        geoJSON.properties.radius = radius;
                        app.polygonPointsGeoJSON = JSON.stringify(geoJSON);
                        break;
                    }
                default:
                    { break; }
            }
        });

        this.map.on(L.Draw.Event.EDITED, function (event) {
            var layers = event.layers;
            event.layers.eachLayer(function (layer) {
                let shape = layer.toGeoJSON();
                let shape_for_db = JSON.stringify(shape);
                app.Geostring = JSON.stringify(shape.geometry);
                app.polygonPointsGeoJSON = JSON.stringify(layer.toGeoJSON());
                app.polygonPoints = JSON.stringify(layer.getLatLngs());
            });
        });
    }

    //Display the GeoJSON polygon points to map
    displayPolygonOnMap(polygons) {
        var selectedFeature = null;
        let JsonObject = JSON.parse(polygons);

        this.polyLayer = L.geoJson(JsonObject, {
        }).addTo(this.map);
        this.drawnItems.addLayer(this.polyLayer);
    }

    displayPolygonCircleOnMap(polygons) {
        let JsonObject = JSON.parse(polygons);
        this.circleLayer = L.geoJson(JsonObject, {
            pointToLayer: function (feature, latlng) {
                if (feature.properties.radius) {
                    return new L.Circle(latlng, feature.properties.radius);
                }
                return;
            }
        }).addTo(this.map);
        this.drawnItems.addLayer(this.circleLayer);
    }

    setMapMarkerPin(position: L.LatLng) {
        if (this.mapmarkersPin) {
            this.mapmarkersPin.setLatLng(position);
        }
        else {
            this.mapmarkersPin = L.marker(position, {
                icon: this.icons,
                draggable: false
            });
            this.mapmarkersPin.addTo(this.map);
        }
    }

    editPologon(): void {
        alert("The Edit is not implemented");
    }

    savePologon(): void {
        alert("The save is not implemented");
    }

    getMapSize(elementRef: ElementRef) {
        const mapElement = elementRef.nativeElement.querySelector('.map-container');

        if (mapElement) {
            const width = mapElement.offsetWidth;
            const height = mapElement.offsetHeight;
            this.setMapSize({ width, height });
        }
    }

    setMapSize(val: any) {
        this._mapSize.next(val);
    }

    //Checks if the polygon is outside the defined radius by looping through its vertices and calculating their distances from the center.
    checkPolygon() {
        const center = this.map.getCenter();
        const polygonVertices = this.polygon.getLatLngs(); // Get polygon vertices

        for (const vertex of polygonVertices) {
            const distance = this.calculateDistance(center, vertex);
            if (distance > this.radius) {
                this.showMessage('Polygon is outside the radius', MessageType.warning);
                return;
            }
        }
        this.showMessage('Polygon is within the radius', MessageType.warning);
    }

    //calculates the distance between two geographical points using the Haversine formula.
    calculateDistance(point1, point2) {
        const R = 6371000; // Radius of the Earth in meters
        const lat1 = point1.lat;
        const lon1 = point1.lng;
        const lat2 = point2.lat;
        const lon2 = point2.lng;

        const latPoints1 = this.toRadians(lat1);
        const latPoints2 = this.toRadians(lat2);
        const distanceLat2Lat1 = this.toRadians(lat2 - lat1);
        const distanceLon2Lat2 = this.toRadians(lon2 - lon1);

        const fulldiameterRadius = Math.sin(distanceLat2Lat1 / 2) * Math.sin(distanceLat2Lat1 / 2) +
            Math.cos(latPoints1) * Math.cos(latPoints2) * Math.sin(distanceLon2Lat2 / 2) * Math.sin(distanceLon2Lat2 / 2);

        const c = 2 * Math.atan2(Math.sqrt(fulldiameterRadius), Math.sqrt(1 - fulldiameterRadius));

        return R * c;
    }

    //converts degrees to radians
    toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }

    checkBoundries(latitude, longitude) {
        this.isBusy = true;
        let layoutPoints = new L.LatLng(Number(latitude), Number(longitude));
        const newBoundries = new Boundaries();

        newBoundries.id = this.currentSite.id;
        newBoundries.latitude = layoutPoints.lat;
        newBoundries.longitude = layoutPoints.lng;

        this.siteService.checkBoundries(newBoundries).subscribe(res => {
            this.isBusy = false;
            let inBoundries = res.body[0].inBoundry;
            if (inBoundries == false) {
                this.showMessage('The current coordinates is outside of the boundaries of the current borders set.', MessageType.warning);
            } else {
                this.showMessage('The current coordinates is inside of the boundaries of the current borders set.', MessageType.warning);
            }
        }, error => {
            this.isBusy = false;
            this.showMessage('Error occurred .Please try again', MessageType.error);
            this.handleError(error);
        });
    }

    getMapDetails() {
        this.addressBusy = true;
       
        var Address = this.siteFormGroup.get('address').value;
        if (Address) {
           
            this.getAddressLeafletMap(Address);
        } else {
            this.showMessage("Please enter a address", MessageType.warning);
        }
        this.addressBusy = false;
          }

    getAddressLeafletMap(address: string) {
        // Use Nominatim to get latitude and longitude of address
        fetch('https://nominatim.openstreetmap.org/search?format=json&q=' + encodeURIComponent(address))
            .then(response => response.json())
            .then(data => {
                if (data[0]) {
                    var lat = parseFloat(data[0].lat);
                    var lon = parseFloat(data[0].lon);
                    this.setMapMarkerPin(new L.LatLng(Number(lat), Number(lon)));
                    this.deviceCurrentLocation.latitude = lat;
                    this.deviceCurrentLocation.longitude = lon;
                    this.currentSite.latitude = lat;
                    this.currentSite.longitude = lon;
                    this.map.flyTo([Number(lat), Number(lon)], 16);

                    this.siteFormGroup.patchValue({
                        longitude: this.currentSite.longitude,
                        latitude: this.currentSite.latitude,
                    });
                    this.showMessage("Address and coordinates have been set.", MessageType.success);
                }
                else {
                    this.showMessage("Can not find address entered coordinates.", MessageType.warning);
                }
            })
            .catch(error => {
                console.error('Error:', error)
                this.showMessage("Set leaflet map with error.", MessageType.error);
            });
    }

    checkIfPoligonChanged(Saved, New) {
        return Saved === New;
    }

    setPolygonsOnMap(polygonJSON: any) {
        if (this.polygonType == 'polygon') {
            this.displayPolygonOnMap(polygonJSON);
        } else if (this.polygonType == 'circle') {
            this.displayPolygonCircleOnMap(polygonJSON);
        } else {
            this.showMessage("No polygon items created.", MessageType.warning);
        }
    }
}
