<h2 mat-dialog-title>Reset Password</h2>
<mat-dialog-content>
  <div class="column">
    <mat-form-field class="full-width-input">
      <input matInput placeholder="Enter Your Email Address/Username" [(ngModel)]="email">
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="vertical-align: center">
  <button class="btn btn-primary btn-rounded mt-4" (click)="resetPassword()">
    <span class="ul-btn__text">Send Reset Email</span>
    <span class="ul-btn__icon"><mat-icon>email</mat-icon></span>
  </button>

  <button id="btn-close" class="btn btn-rounded btn-gray-400" (click)="closeModal()">
    <span class="ul-btn__text">Close</span>
    <span class="ul-btn__icon"><mat-icon>close</mat-icon></span>
  </button>
</mat-dialog-actions>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
