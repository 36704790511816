import {Component, OnInit, ViewChild} from '@angular/core';
import {Employee} from '../../models/Employee';
import {EmployeeService} from '../../services/employee-service';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {Site} from '../../models/Site';
import {StorageService} from '../../services/storage-service';
import {Router} from '@angular/router';
import {ComponentBaseComponent} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-employee-summary',
  templateUrl: './employee-summary.component.html',
  styleUrls: ['./employee-summary.component.scss']
})
export class EmployeeSummaryComponent extends ComponentBaseComponent implements OnInit {
  displayedColumns = ['Icon','name', 'contactNumber', 'emailAddress', 'site', 'image'];
  isBusy = false;

  employees: Employee[];

  dataSource = new MatTableDataSource<Employee>(this.employees);

  @ViewChild(MatPaginator,{static: true}) paginator : MatPaginator;

  currEmployeeRole: string;

  constructor(
    private employeeService: EmployeeService,
    public storageService: StorageService,
    public router: Router,
    public snackbar: MatSnackBar,
    public dialog: MatDialog
  ) { super(snackbar, dialog, router,storageService) }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.getEmployees();
  }

  getEmployees() {
    this.isBusy = true;
    const companyId = this.storageService.getSelectedCompany().id;
    this.employeeService.getEmployees(companyId).subscribe((result) => {
      let jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.employees = [];
      for (let data of result.body as any) {
          let employee: Employee = jsonConvert.deserialize(data, Employee) as Employee;
          employee.site = new Site();
          employee.site.id = data.siteId;
          employee.site.name = data.siteName;

          this.employees.push(employee);

      }
      this.dataSource = new MatTableDataSource<Employee>(this.employees);
      this.dataSource.paginator = this.paginator;
      this.isBusy = false;
    }, error => {
      this.isBusy = false;
      this.handleError(error);
    });
  }

  selectEmployee(employee) {
    const selEmployee = (employee) ? employee : new Employee();
    this.storageService.setSelectedEmployee(selEmployee);

    this.router.navigate(['company/employee/' + selEmployee.id]);
  }



}
