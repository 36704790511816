import { Injectable } from '@angular/core';
import { CommonService } from './commonService';
import { Device } from '../models/Device';

@Injectable({
    providedIn: 'root'
})

export class TrackingService {

    constructor(private commonService: CommonService) { }

    apiV1TrackingGetDeviceLocation(deviceId: Number, startDate: Date, endDate: Date) {
        return this.commonService.postData('/api/v1/tracking/GetDeviceLocation', { deviceId, startDate, endDate });
    }

    apiV1TrackingGetCurrentDeviceLocation(id: Number) {
        return this.commonService.postData('/api/v1/tracking/GetCurrentDeviceLocation', { id });
    }

    apiV1TrackingGetSelectedDevicePlayback(deviceId: Number, selectedOption: string, startDate: string, endDate: string) {
        return this.commonService.postData('/api/v1/tracking/GetSelectedDevicePlayback', { deviceId, startDate, endDate, selectedOption });
    }

    GetHistoryDeviceLocationsByDate(deviceId: Number, startDate: Number, endDate: Number) {
        return this.commonService.postData('/api/v1/tracking/GetHistoryDeviceLocationsByDate', { deviceId, startDate, endDate });
    }
}