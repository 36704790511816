import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CompanyNavbarComponent} from './components/company-navbar/company-navbar.component';
import {SitesSummaryComponent} from './components/sites-summary/sites-summary.component';
import {AlertsSummaryComponent} from './components/alerts-summary/alerts-summary.component';
import {ReportsComponent} from './components/reports/reports.component';
import {CompanySummaryComponent} from './components/company-summary/company-summary.component';
import {CompanyDetailsComponent} from './components/company-details/company-details.component';
import {EmployeeSummaryComponent} from './components/employee-summary/employee-summary.component';
import {EmployeeDetailComponent} from './components/employee-detail/employee-detail.component';
import {SiteDetailComponent} from './components/site-detail/site-detail.component';
import {RouteSummaryComponent} from './components/route-summary/route-summary.component';
import {RouteDetailComponent} from './components/route-detail/route-detail.component';
import {DeviceSummaryComponent} from './components/device-summary/device-summary.component';
import {DeviceDetailComponent} from './components/device-detail/device-detail.component';
import {TagSummaryComponent} from './components/tag-summary/tag-summary.component';
import {TagDetailComponent} from './components/tag-detail/tag-detail.component';
import {ScheduleSummaryComponent} from './components/schedule-summary/schedule-summary.component';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {SysAdminNavbarComponent} from './components/sys-admin-navbar/sys-admin-navbar.component';
import {RoleGuard} from './guards/role.guard';
import {ROLE_TYPES} from './enums/RoleTypes';
import {SiteextradetailComponent} from './components/siteextradetail/siteextradetail.component';
import {ScheduleDetailsComponent} from './components/schedule-details/schedule-details.component';
import {ResetPasswordComponent} from "./components/reset-password/reset-password.component";
import {CompanyChatComponent} from './components/company-chat/company-chat.component';
import {AreaDetailComponent} from './components/area-detail/area-detail.component';
import {AreaSummaryComponent} from './components/area-summary/area-summary.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {TagEnrollmentComponent} from './components/tag-enrollment/tag-enrollment.component';
import { AttendanceComponent } from './components/attendance-summary/attendance-summary.component';
import { TrackingDetailComponent } from './components/tracking-detail/tracking-detail.component';

const routes: Routes = [
  {
    path: 'companies',
    component: SysAdminNavbarComponent,
    canActivate: [RoleGuard],
    data: {roles: [ROLE_TYPES.SYS_ADMIN]},
    children: [
      {path: '', component: CompanySummaryComponent},
      {path: 'edit', component: CompanyDetailsComponent},
    ]
  },
  {
    path: 'company',
    component: CompanyNavbarComponent,
    canActivate: [RoleGuard],
    data: {roles: [ROLE_TYPES.SYS_ADMIN, ROLE_TYPES.ADMIN, ROLE_TYPES.CONTROLLER]},
    children: [
      {path: 'sites', component: SitesSummaryComponent},
      {path: 'chats', component: CompanyChatComponent, data: {roles: [ROLE_TYPES.ADMIN, ROLE_TYPES.CONTROLLER]} },
      {path: 'alerts', component: AlertsSummaryComponent, data: {roles: [ROLE_TYPES.ADMIN, ROLE_TYPES.CONTROLLER]}},
      {path: 'reports', component: ReportsComponent},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'employees', component: EmployeeSummaryComponent},
      {path: 'employee/:id', component: EmployeeDetailComponent},
      {path: 'areas', component: AreaSummaryComponent},
      {path: 'area/:id', component: AreaDetailComponent},
      {path: 'site/details', component: SiteDetailComponent},
      {path: 'tag/enrollment', component: TagEnrollmentComponent},
      {path: 'attendance', component: AttendanceComponent},
      {
        path: 'site/xtra',
        component: SiteextradetailComponent,
        children: [
          {path: 'details', component: SiteDetailComponent},
          {path: 'routes', component: RouteSummaryComponent},
          {path: 'route/:id', component: RouteDetailComponent},
          {path: 'devices', component: DeviceSummaryComponent},
          {path: 'device/:id', component: DeviceDetailComponent},
          {path: 'tags', component: TagSummaryComponent},
          {path: 'tag/:id', component: TagDetailComponent},
          {path: 'schedule', component: ScheduleSummaryComponent},
          {path: 'schedule/:id', component: ScheduleDetailsComponent },
          { path: 'tracking', component: TrackingDetailComponent },
          { path: 'tracking/:id', component: TrackingDetailComponent },
        ]
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'resetPassword/:code',
    component: ResetPasswordComponent
  },
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
