import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, GoogleMap, MapMarker } from '@angular/google-maps';
import { ComponentBaseComponent, MessageType } from '../../component-base';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RouteService } from '../../services/route-service';
import { StorageService } from '../../services/storage-service';
import { RouteTag } from '../../models/RouteTag';
import { MatSelectionList } from '@angular/material/list';
import { Route } from '../../models/Route';
import { TagToRoute } from '../../models/TagToRoute';
import { forkJoin, Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { SiteServiceService } from '../../services/site.service';
import { ScheduleService } from '../../services/schedule-service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-create-route',
  templateUrl: './route-detail.component.html',
  styleUrls: ['./route-detail.component.scss']
})
export class RouteDetailComponent extends ComponentBaseComponent implements OnInit {
  PREVIOUS_PAGE = '/company/site/xtra/routes';

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
  @ViewChild('routeTags') routeTags: MatSelectionList;

  route: Route = new Route();

  tags: RouteTag[] = [];
  savedTags: RouteTag[] = [];
  selectedTags: RouteTag[] = [];
  markers: any[] = [];

  routes: Route[] = [];

  duration = 0.0;
  siteId: number;


  center: google.maps.LatLngLiteral;

  upsertRouteCardTitle = 'Create New Route';
  upcomingScheduleAheadCount: number = 0;
  isBusy = false;
  checked = false;
  infoContent = '';
  zoom = 18;
  currEmployeeRole: string;
  upcommingSchedualPlanIdArray: any[];
  routeSchedulesCount: number;

  constructor(
    protected router: Router,
    protected snackBar: MatSnackBar,
    public dialog: MatDialog,
    public routeService: RouteService,
    protected storageService: StorageService,
    public siteService: SiteServiceService,
    private scheduleService: ScheduleService,
  ) {
    super(snackBar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.siteId = this.storageService.getCurrentSite().id;
    this.getAllRouteTagsBySite(this.siteId);
    this.getSiteLocation();
  }

  getAllRouteTagsBySite(siteId) {
    this.isBusy = true;
    this.tags = [];
    this.routeService.getAllRouteTagsBySiteId(siteId).subscribe(data => {
      const jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
      this.tags = jsonConvert.deserializeArray(data.body as any, RouteTag);

      this.loadAllTagsOnMap();
      this.getRoute();
    }, error => {
      this.isBusy = false;
      this.handleError(error);
    });
  }

  loadAllTagsOnMap() {
    this.markers = [];
    const tagIdArray = [];
    let animation;
    let icon;
    this.savedTags.forEach(tag => {
      tagIdArray.push(tag.id);
    });
    this.tags.forEach(tag => {
      animation = (tagIdArray.includes(tag.id)) ? google.maps.Animation.BOUNCE : google.maps.Animation.DROP;
      icon = (tagIdArray.includes(tag.id)) ? '../../../assets/selectedPin.png' : '../../../assets/unselectedPin.png';

      this.markers.push({
        position: {
          lat: tag.latitude,
          lng: tag.longitude,
        },
        label: {
          color: 'red',
          text: tag.name,
        },
        info: { tagId: tag.id, serialNumber: tag.serialNumber },
        title: tag.serialNumber,
        options: { icon, animation },
      });
    });
  }

  swapPosition(tagIndex: number, shouldSwapUp: boolean) {
    const swapWithIndex = (shouldSwapUp) ? tagIndex - 1 : tagIndex + 1;

    let temp = this.selectedTags[swapWithIndex];

    if (temp) {
      this.selectedTags[swapWithIndex] = this.selectedTags[tagIndex];
      this.selectedTags[tagIndex] = temp;
    }
  }

  isRoutesValid() {
    let valid = true;
    this.selectedTags.forEach(tag => {
      if (tag.position === null) {
        valid = false;
      }
    });
    if (!(this.route.name.length > 0)) {
      valid = false;
    }

    return valid;
  }

  upsertRoute() {
    if (this.isRoutesValid()) {
      let actionText = (this.route.id) ? 'save your changes' : 'create a new route';

      const hasUpcomingSchedules = (this.route.id) && (this.upcomingScheduleAheadCount > 0);

      if (hasUpcomingSchedules) {
        actionText = 'edit this route as has up-coming schedules planned. If you continue it will result in the loss of all schedule plans made after this change has been made.';
        this.upsertDialog(actionText);
      } else {
        this.upsertDialog(actionText);
      }
    } else {
      this.showMessage('Missing data', MessageType.error);
    }
  }

  upsertDialog(actionText) {
    let dialogRef = this.showConfirmationDialog(actionText);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isBusy = true;

        const newRoute = new Route();
        newRoute.id = this.route.id;
        newRoute.siteId = this.siteId;
        newRoute.durationMinutes = this.route.durationMinutes;
        newRoute.name = this.route.name;

        this.upsertRouteServiceCall(newRoute);
      }
    });
  }

  deleteRoute() {
    let actionText = 'delete ' + this.route.name;
    const hasSchedules = (this.route.id) && (this.routeSchedulesCount > 0);
    if (hasSchedules) {
      const hasUpcomingSchedules = (this.upcomingScheduleAheadCount > 0);
      if (hasUpcomingSchedules) {
        let batchData = {
          scheduleData: this.upcommingSchedualPlanIdArray
        };
        actionText = 'delete ' + this.route.name + ' as it has ' + this.routeSchedulesCount + ' schedules and up-coming schedules planned. If you continue it will result in the loss of all schedules and schedule plans made with ' + this.route.name + '.';
        this.deleteDialog(actionText, batchData);
      } else {
        actionText = 'delete ' + this.route.name + ' as it has ' + this.routeSchedulesCount + ' schedules. If you continue it will result in the loss of all schedules made with ' + this.route.name + '.';
        this.deleteDialog(actionText);
      }
    } else {
      this.deleteDialog(actionText);
    }
  }

  deleteDialog(actionText, batchData = null) {
    let dialogRef = this.showConfirmationDialog(actionText);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isBusy = true;
        const routeId = this.route.id;
        this.deleteRouteServiceCall(routeId);
      }
    });
  }

  deleteRouteServiceCall(routeId) {
    this.routeService.deleteRoute(routeId).subscribe((result) => {
      this.router.navigate([this.PREVIOUS_PAGE]);
      this.showMessage('Route is successfully deleted', MessageType.success);
      this.isBusy = false;
    }, error => {
      this.isBusy = false;
      this.showMessage('An error occurred deleting the route', MessageType.error);
      this.handleError(error);
    });
  }

  upsertRouteServiceCall(newRoute) {

    const tagToRouteList = [];
    this.selectedTags.forEach((tag, index) => {
      const tagToRoute = new TagToRoute();
      tagToRoute.id = tag.tagToRouteId;
      tagToRoute.tagId = tag.id;
      tagToRoute.position = index + 1;
      tagToRouteList.push(tagToRoute);
    });
    newRoute.tagToRouteList = tagToRouteList;

    const tagToRouteDeleteIdsList = [];
    const tagsToRemove = this.savedTags.filter((curr) => !this.selectedTags.includes(curr));
    tagsToRemove.forEach((tag) => {
      tagToRouteDeleteIdsList.push(tag.tagToRouteId);
    });

    newRoute.tagToRouteDeleteIdsList = tagToRouteDeleteIdsList;
    this.routeService.upsertRoute(newRoute).subscribe(res => {
      this.router.navigate([this.PREVIOUS_PAGE]);
      this.showMessage('All tags uploaded to route', MessageType.success);
      this.isBusy = false;
    }, error => {
      this.isBusy = false;
      this.showMessage('An error occurred uploading the tags', MessageType.error);
      this.handleError(error);
    });
  }

  getRoute() {
    this.upsertRouteCardTitle = 'Edit Existing Route';

    this.savedTags = [];

    this.route = this.storageService.getSelectedRoute();
    if (this.route) {
      this.routeService.getAllRouteTagsByRouteId(this.route.id).subscribe(res => {
        const body = res.body as any[];
        let routeTagsReceived = body[0];
        this.upcommingSchedualPlanIdArray = body[1];
        this.routeSchedulesCount = body[2][0].routeSchedulesCount;
        this.upcomingScheduleAheadCount = this.upcommingSchedualPlanIdArray.length;
        this.selectedTags = [];

        routeTagsReceived.forEach(tagData => {
          this.tags.forEach(currTag => {
            if (tagData.tagId === currTag.id) {
              currTag.position = tagData.position;
              currTag.tagToRouteId = tagData.id;
              this.selectedTags.push(currTag);
              this.savedTags.push(currTag);
            }
          });
        });

        this.selectedTags.sort((current, next) => current.position - next.position);

        this.loadAllTagsOnMap();
        this.isBusy = false;
      }, error => {
        this.handleError(error);
        this.isBusy = false;
      });
    } else {
      this.refreshForms();
      this.isBusy = false;
    }
  }

  refreshForms() {
    this.upsertRouteCardTitle = 'Create New Route';
    this.route = new Route();
    this.savedTags = [];
    this.selectedTags = [];
    this.upcomingScheduleAheadCount = 0;
  }

  // openInfo(marker: MapMarker, info) {
  //   this.infoContent = 'Serial number : ' + info.serialNumber + ' --> Tag Id : ' + info.tagId;
  //   for (let m = 0; m < this.markers.length; m++) {
  //     if (this.markers[m].info.tagId === info.tagId) {
  //       if (this.markers[m].options.icon === '../../../assets/unselectedPin.png') {
  //         marker._marker.setIcon('../../../assets/selectedPin.png');
  //         marker._marker.setAnimation(google.maps.Animation.BOUNCE);
  //         const tagFromMarker = this.tags.find((tag) => tag.id === this.markers[m].info.tagId);
  //         this.selectedTags.push(tagFromMarker);
  //         this.markers[m].options.icon = '../../../assets/selectedPin.png';
  //       } else {
  //         marker._marker.setIcon('../../../assets/unselectedPin.png');
  //         marker._marker.setAnimation(google.maps.Animation.DROP);
  //         this.selectedTags = this.selectedTags.filter(tag => tag.id !== this.markers[m].info.tagId);
  //         this.markers[m].options.icon = '../../../assets/unselectedPin.png';
  //       }
  //       break;
  //     }
  //   }
  //   this.info.open(marker);
  // }

  getCurrentPageName() {
    return (this.route && this.route.id) ? this.route.name : 'New Route';
  }

  getSiteLocation() {
    this.siteService.getSiteById(this.siteId).subscribe((result) => {
      const siteData = result.body as any;
      this.center = {
        lat: siteData.latitude,
        lng: siteData.longitude,
      };
    }, (error => {
      this.handleError(error);
    }));
  }

  tagClick(tag) {
    for (let m = 0; m < this.markers.length; m++) {
      if (this.markers[m].info.tagId === tag.id) {
        if (this.markers[m].options.icon === '../../../assets/unselectedPin.png') {
          this.markers[m].options.animation = google.maps.Animation.BOUNCE;
          this.markers[m].options.icon = '../../../assets/selectedPin.png';
        } else {
          this.markers[m].options.animation = google.maps.Animation.DROP;
          this.markers[m].options.icon = '../../../assets/unselectedPin.png';
        }
        break;
      }
    }
    this.tagSelected(tag);
    this.selectedTags.sort((current, next) => current.position - next.position);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedTags, event.previousIndex, event.currentIndex);
  }

  tagSelected(tag) {
    const tagIndex = this.selectedTags.indexOf(tag);
    if (tagIndex > -1) {
      this.selectedTags.splice(tagIndex, 1);
    } else {
      this.selectedTags.push(tag);
    }
  }

  isTagSelected(tag) {
    const tagIndex = this.selectedTags.indexOf(tag);
    if (tagIndex > -1) {
      return true;
    }
    return false;
  }

}
