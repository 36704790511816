<div class="card">
    <div class="card-body">
      <table mat-table [dataSource]="enrollingTags" class="table-hover">
        <!-- Position Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Tag Name</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>Serial Number</th>
            <td mat-cell *matCellDef="let element"> {{element.serialNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="siteName">
            <th mat-header-cell *matHeaderCellDef>Site </th>
            <td mat-cell *matCellDef="let element"> {{element.siteName}} </td>
        </ng-container>
        <ng-container matColumnDef="routeName">
            <th mat-header-cell *matHeaderCellDef>Route</th>
            <td mat-cell *matCellDef="let element"> {{element.routeName}} </td>
        </ng-container>
        <ng-container matColumnDef="tagPosition">
            <th mat-header-cell *matHeaderCellDef>Position</th>
            <td mat-cell *matCellDef="let element"> {{element.tagPosition}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.status != 'Pending'; else elseBlock">
                    {{element.status}}
                </div>
                <ng-template #elseBlock>
                    <button id="acceptStatusBtn" (click)="acceptTag(element.serialNumber)">
                        Accept
                    </button>
                    <button id="declineStatusBtn" (click)="declineTag(element.serialNumber)">
                        Decline
                    </button>
                </ng-template>
            </td>            
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
      </table>
    </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>