import {JsonObject, JsonProperty} from 'json2typescript';
import {EVENT_TYPES} from '../enums/EventTypes';
import {Device} from './Device';
import {RouteTaken} from './RouteTaken';
import {Site} from './Site';

@JsonObject ('Attendance')
export class Attendance {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('eventGuid', String, true)
  public eventGuid: string = undefined;

  @JsonProperty('clockType', String, true)
  public clockType: string = undefined;

  @JsonProperty('type', String, true)
  public type: string = EVENT_TYPES.ATTENDANCE;

  @JsonProperty('clockTime', Number, true)
  public clockTime: number = undefined;

  @JsonProperty('companyId', Number, true)
  public companyId: number = undefined;

  @JsonProperty('employeeId', Number, true)
  public employeeId: number = undefined;

  @JsonProperty('deviceId', Number, true)
  public deviceId: number = undefined;

  @JsonProperty('device', Device, true)
  public device: Device = undefined;

  @JsonProperty('site', Site, true)
  public site: Site = undefined;

  @JsonProperty('alternateIdentification', String, true)
  public alternateIdentification: string = undefined;

  @JsonProperty('imageUrl', String, true)
  public imageUrl: string = undefined;

}
