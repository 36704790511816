import { Component, OnInit } from '@angular/core';
import {RouteTag} from '../../models/RouteTag';
import {RouteService} from '../../services/route-service';
import {StorageService} from '../../services/storage-service';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tag-summary',
  templateUrl: './tag-summary.component.html',
  styleUrls: ['./tag-summary.component.scss']
})
export class TagSummaryComponent implements OnInit {
  PREVIOUS_PAGE = '/company/site/xtra';

  isBusy = false;

  tags: RouteTag[] = [];

  displayedColumns = ['name', 'serialNumber', 'isPanic', 'image'];

  currEmployeeRole: string;

  constructor(
    private routeService: RouteService,
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getTags();
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
  }

  selectTag(tag: RouteTag) {
    const selectedTag: RouteTag = (tag) ? tag : null;

    this.storageService.setSelectedTag(selectedTag);
    if (selectedTag) {
      this.router.navigate(['company/site/xtra/tag/' + tag.id]);
    } else {
      this.router.navigate(['company/site/xtra/tag/new']);
    }
  }

  getTags() {
    const siteId = this.storageService.getCurrentSite().id;
    this.routeService.getAllRouteTagsBySiteId(siteId).subscribe((result) => {
      let jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.tags = jsonConvert.deserializeArray(result.body as any, RouteTag);
    });
  }

}
