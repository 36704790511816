import { JsonObject, JsonProperty } from 'json2typescript';
import { SiteContact } from './SiteContact';
import { Area } from './Area';

@JsonObject('Site')
export class Site {

  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('name', String, true)
  public name: string = undefined;

  @JsonProperty('address', String, true)
  public address: string = undefined;

  @JsonProperty('companyName', String, true)
  public companyName: string = undefined;

  @JsonProperty('companyId', Number, true)
  public companyId: number = undefined;

  @JsonProperty('latitude', Number, true)
  public latitude: number = undefined;

  @JsonProperty('longitude', Number, true)
  public longitude: number = undefined;

  @JsonProperty('contacts', [], true)
  public contacts: SiteContact[] = undefined;

  @JsonProperty('division', String, true)
  public division: string = undefined;

  @JsonProperty('branch', String, true)
  public branch: string = undefined;

  @JsonProperty('areaId', Number, true)
  public areaId: number = undefined;

  @JsonProperty('area', Area, true)
  public area: Area = undefined;

  public selected = false;

  public messagesCounter = 0;

  @JsonProperty('poligonPoints', String, true)
  public poligonPoints: string = undefined;

  @JsonProperty('polygonType', String, true)
  public polygonType: string = undefined;

  @JsonProperty('points', String, true)
  public shapes: string = undefined;

  @JsonProperty('pointList', String, true)
  public points: string = undefined;
}
