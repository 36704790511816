import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('SiteContact')
export class SiteContact {
    @JsonProperty('id', Number, true)
    public id: number = undefined;

    @JsonProperty('name', String, true)
    public name: string = undefined;

    @JsonProperty('address', String, true)
    public address: string = undefined;

    @JsonProperty('contactNumber', String, true)
    public contactNumber: string = undefined;

    @JsonProperty('emailAddress', String, true)
    public emailAddress: string = undefined;

    @JsonProperty('shouldReceiveReportsEmail', Boolean, true)
    public shouldReceiveReportsEmail: boolean = undefined;

    @JsonProperty('shouldReceivePanicsEmail', Boolean, true)
    public shouldReceivePanicsEmail: boolean = undefined;

    @JsonProperty('shouldReceivePleaseCallsEmail', Boolean, true)
    public shouldReceivePleaseCallsEmail: boolean = undefined;

    @JsonProperty('shouldReceiveFailedPatrolsEmail', Boolean, true)
    public shouldReceiveFailedPatrolsEmail: boolean = undefined;

    @JsonProperty('shouldReceiveBatteryLowEmail', Boolean, true)
    public shouldReceiveBatteryLowEmail: boolean = undefined;

    @JsonProperty('shouldReceiveReportsTelegram', Boolean, true)
    public shouldReceiveReportsTelegram: boolean = undefined;

    @JsonProperty('shouldReceivePanicsTelegram', Boolean, true)
    public shouldReceivePanicsTelegram: boolean = undefined;

    @JsonProperty('shouldReceivePleaseCallsTelegram', Boolean, true)
    public shouldReceivePleaseCallsTelegram: boolean = undefined;

    @JsonProperty('shouldReceiveFailedPatrolsTelegram', Boolean, true)
    public shouldReceiveFailedPatrolsTelegram: boolean = undefined;

    @JsonProperty('shouldReceiveBatteryLowTelegram', Boolean, true)
    public shouldReceiveBatteryLowTelegram: boolean = undefined;

    @JsonProperty('shouldReceiveReportsPhone', Boolean, true)
    public shouldReceiveReportsPhone: boolean = undefined;

    @JsonProperty('shouldReceivePanicsPhone', Boolean, true)
    public shouldReceivePanicsPhone: boolean = undefined;

    @JsonProperty('shouldReceivePleaseCallsPhone', Boolean, true)
    public shouldReceivePleaseCallsPhone: boolean = undefined;

    @JsonProperty('shouldReceiveFailedPatrolsPhone', Boolean, true)
    public shouldReceiveFailedPatrolsPhone: boolean = undefined;

    @JsonProperty('shouldReceiveBatteryLowPhone', Boolean, true)
    public shouldReceiveBatteryLowPhone: boolean = undefined;

    @JsonProperty('shouldReceiveBoundryEmail', Boolean, true)
    public shouldReceiveBoundryEmail: boolean = undefined;

    @JsonProperty('shouldReceiveBoundryTelegram', Boolean, true)
    public shouldReceiveBoundryTelegram: boolean = undefined;

    @JsonProperty('shouldReceiveBoundryPhone', Boolean, true)
    public shouldReceiveBoundryPhone: boolean = undefined;
}
