<div class="action-bar">
  <button class="btn btn-icon mr-2" *ngIf="currEmployeeRole !== 'controller'" (click)="selectRoute(null)">
    <span class="ul-btn__text">Add a new Route</span>
    <span class="ul-btn__icon">
      <mat-icon>add</mat-icon>
    </span>
  </button>
</div>

<div class="card">
  <div class="card-body">
    <table mat-table [dataSource]="routes" class="table-hover">
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef> Duration</th>
        <td mat-cell *matCellDef="let element"> {{element.durationMinutes}} </td>
      </ng-container>

      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon>chevron_right</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (click)="selectRoute(routes[i])"></tr>
    </table>
  </div>
</div>

<br>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>