import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {Router} from '@angular/router';
import {Site} from '../../models/Site';
import {SiteServiceService} from '../../services/site.service';
import {StorageService} from '../../services/storage-service';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {EmployeeClockTimes} from 'src/app/models/EmployeeClockTimes';
import * as moment from 'moment';
import { AttendanceService } from 'src/app/services/attendance-service';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from '@angular/material/table';

  @Component({
    selector: 'attendance-summary',
    templateUrl: './attendance-summary.component.html',
    styleUrls: ['./attendance-summary.component.scss']
  })

  export class AttendanceComponent extends ComponentBaseComponent implements OnInit, AfterViewInit {
    isBusy = false; 

    employeeClockTimes: EmployeeClockTimes[] = [];
    dataSource = new MatTableDataSource<EmployeeClockTimes>(this.employeeClockTimes);
    @ViewChild('Attendance_paginator', { static: true }) Attendance_paginator: MatPaginator;

    displayedColumns = ['employeeName', 'employeeSurname', 'siteName', 'userRole', 'clockInTimeEpoch', 'clockOutTimeEpoch'];

    companyId: number;

    sites: Site[] = [];
    selectedSite: any;

    UTC_SHIFT_START_HOUR = 4;
    UTC_SHIFT_END_HOUR = 21;
    UTC_SHIFT_END_MINUTE = 59;
    today;
    startDate;
    endDate;

    constructor(
        private siteService: SiteServiceService,
        private attendanceService: AttendanceService,
        protected storageService: StorageService,
        protected router: Router,
        public snackBar: MatSnackBar,
        public dialog: MatDialog) {
        super(snackBar, dialog, router, storageService);
      }

    ngOnInit(): void { 
      this.getSites();
      this.today = moment(new Date(`${moment(new Date()).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`));
      this.resetDate();
    }

    ngAfterViewInit(): void {
      this.getClockInAndOutData();
    }

    getSites() {
      this.companyId = this.storageService.getSelectedCompany().id;
      this.siteService.getAllSitesByCompany(this.companyId).subscribe(result => {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  
        this.sites = jsonConvert.deserializeArray(result.body as any, Site) as Site[];
        if (this.sites && this.sites.length > 0) {
          this.selectedSite = this.sites[0];
        }
      }, error => {
        this.handleError(error);
      });
    }

    validateDateRange(startDate, endDate): {isValid: boolean, message: string} {
      let startDateEpoch = startDate.getTime();
      let endDateEpoch = endDate.getTime();
  
      if (startDateEpoch === endDateEpoch) {
          return {isValid: false, message: 'Start and end date can\'t be the same.'}
      } else if (startDateEpoch > endDateEpoch) {
          return {isValid: false, message: 'Start date can\'t be greater than the end date.'}
      }else if (this.endDate > this.today.add(1, 'days').toDate()) {
          return {isValid: false, message: 'End date can\'t be greater than today.'}
      }else if((endDateEpoch - startDateEpoch) > 626400000) {
          return {isValid: false, message: 'Date range can\'t be greater than 7 days.'}
      }
  
      return {isValid: true, message: null}
    }

    onParametersChanged(){
      let utcStartDate = new Date(`${moment(this.startDate).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`);
      let utcEndDate = new Date(`${moment(this.endDate).format('YYYY-MM-DD')}T${this.UTC_SHIFT_END_HOUR}:${this.UTC_SHIFT_END_MINUTE}:00Z`);

      let validationResponse = this.validateDateRange(utcStartDate, utcEndDate);

      if  (validationResponse.isValid) {
        this.getClockInAndOutData()
      } 
      else {
         this.showMessage(validationResponse.message, MessageType.error);
      }
    }

    getClockInAndOutData(){
      if (this.selectedSite && this.selectedSite.id) {
        this.isBusy = true;

        let utcStartDate = new Date(`${moment(this.startDate).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`);
        let utcEndDate = new Date(`${moment(this.endDate).format('YYYY-MM-DD')}T${this.UTC_SHIFT_END_HOUR}:${this.UTC_SHIFT_END_MINUTE}:00Z`);

        let startDateEpoch = utcStartDate.getTime() / 1000;
        let endDateEpoch = utcEndDate.getTime() / 1000;
        
        this.attendanceService.getAttendanceDataBySite(this.selectedSite.id, startDateEpoch, endDateEpoch).subscribe((result) => {
          let jsonConvert = new JsonConvert();
          jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

          this.employeeClockTimes = jsonConvert.deserializeArray(result.body as any, EmployeeClockTimes);
          this.dataSource = new MatTableDataSource<EmployeeClockTimes>(this.employeeClockTimes);

          if (this.employeeClockTimes.length == 0) {
            this.showMessage("No History found.", MessageType.warning);
          }
          else {
              this.dataSource.paginator = this.Attendance_paginator;
          }

          this.isBusy = false;
        }, error => {
          this.isBusy = false;
          this.handleError(error);
        });
      }
    }

    formatDateTime(time: number) {
      return moment.unix(time).format('YYYY-MM-DD HH:mm');
    }

    getShiftStartTimeString(): string {
      let date = moment(new Date(`1970-01-01T0${this.UTC_SHIFT_START_HOUR}:00:00Z`));
  
      return date.format('HH:mm');
    }

    resetDate() {    
      this.endDate = this.today.toDate();    
      this.startDate = this.today.subtract(1, 'days').toDate();
    }
  }