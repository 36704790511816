import {JsonObject, JsonProperty} from 'json2typescript';
import {Site} from './Site';
import {Company} from './Company';

@JsonObject('Employee')
export class Employee {

  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('userId', Number, true)
  public userId: number = undefined;

  @JsonProperty('name', String, true)
  public name: string = undefined;

  @JsonProperty('surname', String, true)
  public surname: string = undefined;

  @JsonProperty('contactNumber', String, true)
  public contactNumber: string = undefined;

  @JsonProperty('emailAddress', String, true)
  public emailAddress: string = undefined;

  @JsonProperty('role', String, true)
  public role: string = undefined;

  @JsonProperty('company', Company, true)
  public company: Company = undefined;

  @JsonProperty('site', Site, true)
  public site: Site = undefined;

  @JsonProperty('password', String, true)
  public password: string = undefined;
}
