import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {CompanyService} from '../../services/company-service';
import {StorageService} from '../../services/storage-service';
import {validEmail} from '../../../app/logic/commonLogic';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends ComponentBaseComponent implements OnInit {
  registrationForm: FormGroup;
  isBusy = false;

  constructor(
    private companyService: CompanyService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected storageService: StorageService,
  ) { super(snackBar, dialog, router,storageService) }

  ngOnInit(): void {
    this.prepareForm();
  }

  prepareForm() {
    this.registrationForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      address: ['', Validators.required],
      contactPerson: ['', Validators.required],
      contactNumber: ['', Validators.required],
      contactEmail: ['', Validators.required],
      userName: ['', Validators.required],
      surname: ['', Validators.required],
      emailAddress: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  formToObject() {
    return {
      companyName: this.registrationForm.value.companyName,
      address: this.registrationForm.value.address,
      contactPerson: this.registrationForm.value.contactPerson,
      contactNumber: this.registrationForm.value.contactNumber,
      contactEmail: this.registrationForm.value.contactEmail,
      userName: this.registrationForm.value.userName,
      surname: this.registrationForm.value.surname,
      emailAddress: this.registrationForm.value.emailAddress,
      password: this.registrationForm.value.password,
      confirmPassword: this.registrationForm.value.confirmPassword,
      role: 'admin'
    }
}

  register() {
    this.isBusy = true;
    if (this.registrationForm.valid) {
      if (!validEmail(this.registrationForm.value.emailAddress)) {
        this.showMessage('Invalid admin email address.', MessageType.error);
        this.isBusy = false;
        return;
      }
      if (!validEmail(this.registrationForm.value.contactEmail)) {
        this.showMessage('Invalid contact email address.', MessageType.error);
        this.isBusy = false;
        return;
      }
      let dialogRef = this.showConfirmationDialog(`register company ${this.registrationForm.value.companyName}`);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const formObject = this.formToObject();

          if (formObject.password === formObject.confirmPassword) {
            delete formObject.confirmPassword;

            this.companyService.registerCompany(formObject).subscribe((result) => {
              this.showMessage('Registration successful', MessageType.success);
              this.router.navigate(['companies']);
            }, error => {
              this.handleError(error);
            });
          } else {
            this.showMessage('Passwords don\'t match.', MessageType.error);
          }
        }
        this.isBusy = false;
      });

    } else {
      this.showMessage('Invalid Fields', MessageType.warning);
      this.isBusy = false;
    }
  }

}
