<breadcrumb previousPageName="Employees"
            [previousPageLink]="PREVIOUS_PAGE"
            [currentPageName]="getEmployeeFullName()"
            [shouldShow]=true>
</breadcrumb>

<div class="card">
  <div class="card-body">
    <form [formGroup]="employeeForm" class="column">
      <div class="form-group">
        <label for="name">Name</label>
        <input class="form-control form-control-rounded" formControlName="name" placeholder="Name" id="name">
      </div>

      <div class="form-group">
        <label for="surname">Surname</label>
        <input class="form-control form-control-rounded" formControlName="surname" placeholder="Surname" id="surname">
      </div>

      <div class="form-group">
        <label for="emailAddress">Email Address</label>
        <input class="form-control form-control-rounded" formControlName="emailAddress" placeholder="Email Address"
               type="email" id="emailAddress">
      </div>

      <div class="form-group">
        <label for="contactNumber">Contact Number</label>
        <input class="form-control form-control-rounded" formControlName="contactNumber" placeholder="Contact Number"
               type="tel" id="contactNumber">
      </div>

      <div class="form-group" *ngIf="!employeeId">
        <label for="password">Password</label>
        <input class="form-control form-control-rounded" formControlName="password" placeholder="Password"
               type="password" id="password">
      </div>

      <div class="form-group" *ngIf="!employeeId">
        <label for="confirmPassword">Confirm Password</label>
        <input class="form-control form-control-rounded" formControlName="confirmPassword"
               placeholder="Confirm Password" type="password" id="confirmPassword">
      </div>

    </form>
    <div class="column">
      <div class="form-group">
        <label>Site</label>
        <mat-select class="d-block form-control form-control-rounded" [(ngModel)]="siteValue"
                    placeholder="Choose a site">
          <mat-option [value]="EMPLOYEE_NOT_LINKED_TO_SITE_OPTION">None</mat-option>
          <mat-option *ngFor="let site of sites" [value]="site">{{site.name}}</mat-option>
        </mat-select>
      </div>

      <div class="form-group">
        <label>Role</label>
        <mat-select class=" d-block form-control form-control-rounded" [(ngModel)]="role" placeholder="Choose a role">
          <mat-option *ngFor="let role of roles" [value]="role">{{role.replace('-', ' ')}}</mat-option>
        </mat-select>
      </div>

      <div class="form-group" *ngIf="employeeId">
        <label>QR code</label>
        <qrcode [qrdata]="qrCodeData" [width]="150" [errorCorrectionLevel]="'M'" id="qr_code"></qrcode>

        <button *ngIf="employeeId > 0" class="btn btn-primary btn-icon ml-0" (click)="downloadEmployeeData()">
          <span class="ul-btn__icon"><mat-icon>download</mat-icon></span>
          <span class="ul-btn__text">Download Employee Info</span>
        </button>
      </div>
    </div>

    <ng-container *ngIf="currEmployeeRole !== 'controller'">
      <hr>
      <div class="row">
        <button *ngIf="employeeId > 0" class="btn btn-delete btn-primary btn-icon mr-2" (click)="deleteEmployee()">
          <span class="ul-btn__icon"><mat-icon>delete</mat-icon></span>
          <span class="ul-btn__text">Delete Employee</span>
        </button>
        <button class="btn btn-primary btn-icon mr-2" (click)="upsertEmployee()">
          <span class="ul-btn__icon"><mat-icon>check</mat-icon></span>
          <span class="ul-btn__text">Save Employee</span>
        </button>
      </div>
    </ng-container>
  </div>

</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
