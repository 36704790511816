import {Component, OnInit, ViewChild} from '@angular/core';
import {ComponentBaseComponent} from '../../component-base';
import {StorageService} from '../../services/storage-service';
import {Router} from '@angular/router';
import {ScheduleService} from '../../services/schedule-service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Route} from '../../models/Route';
import {RouteService} from '../../services/route-service';
import {Schedule} from '../../models/Schedule';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {SchedulePlanTag} from '../../models/SchedulePlanTag';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';


@Component({
  selector: 'app-schedule-summary',
  templateUrl: './schedule-summary.component.html',
  styleUrls: ['./schedule-summary.component.scss']
})
export class ScheduleSummaryComponent extends ComponentBaseComponent implements OnInit {
  PREVIOUS_PAGE = '';

  displayedColumns = ['routeId', 'repeatMinutes', 'startTimeDay', 'startTimeNight', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'image'];
  genSchedulesTagDisplayedColumns = ['routeName', 'id', 'startTime', 'endTime', 'name', 'estimatedScanEpoch'];

  routes: Route[];
  schedule: Schedule[];
  schedulePlanTags: SchedulePlanTag[];
  siteId: number;
  isBusy = false;
  dataSource = new MatTableDataSource<Schedule>(this.schedule);
  schedulePlanTagsDatasource = new MatTableDataSource<SchedulePlanTag>(this.schedulePlanTags);
  currEmployeeRole: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  constructor(
    protected storageService: StorageService,
    public router: Router,
    private scheduleService: ScheduleService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {
    super(snackBar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.currEmployeeRole = this.storageService.getLoggedInEmployee().role;
    this.siteId = this.storageService.getCurrentSite().id;
    this.getSchedule();
  }

  getSchedule() {
    this.isBusy = true;

    forkJoin(this.scheduleService.getSchedule(this.storageService.getCurrentSite().id),
      this.scheduleService.getSchedulePlansGeneratedBySiteId(this.siteId))
      .subscribe((result) => {
        const jsonConvert = new JsonConvert();
        jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

        this.schedule = jsonConvert.deserializeArray(result[0].body as any, Schedule);
        this.dataSource = new MatTableDataSource<Schedule>(this.schedule);
        this.dataSource.paginator = this.paginator;

        this.schedulePlanTags = jsonConvert.deserializeArray(result[1].body as any, SchedulePlanTag);
        this.schedulePlanTagsDatasource = new MatTableDataSource<SchedulePlanTag>(this.schedulePlanTags);

        this.isBusy = false;
      }, error => {
        this.isBusy = false;
        this.handleError(error);
      });
  }

  selectSchedule(schedule) {
    this.isBusy = true;
    const selSchedule = (schedule) ? schedule : new Schedule();

    this.storageService.setSelectedSchedule(selSchedule);
    this.isBusy = false;
    this.router.navigate(['company/site/xtra/schedule/' + selSchedule.id]);

  }

  formatDateTime(time: number) {
    return moment.unix(time).format('YYYY-MM-DD HH:mm');
  }
}
