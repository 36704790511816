import {Component, OnInit, ViewChild} from '@angular/core';
import {ComponentBaseComponent} from '../../component-base';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StorageService} from '../../services/storage-service';
import {Router} from '@angular/router';
import {CompanyService} from '../../services/company-service';
import {Company} from '../../models/Company';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MessageService, MessageTypes} from '../../message.service';


@Component({
  selector: 'app-company-summary',
  templateUrl: './company-summary.component.html',
  styleUrls: ['./company-summary.component.scss']
})
export class CompanySummaryComponent extends ComponentBaseComponent implements OnInit {
  displayedColumns = ['shield', 'name', 'address', 'profileIcon', 'contactPerson', 'edit'];

  companies: Company[];
  dataSource = new MatTableDataSource<Company>(this.companies);
  isBusy = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    protected storageService: StorageService,
    public router: Router,
    private companyService: CompanyService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private messageService: MessageService
  ) {
    super(snackBar, dialog, router,storageService);
  }

  ngOnInit() {
    this.getCompany();
  }

  getCompany() {
    this.isBusy = true;
    this.companyService.getCompany().subscribe((result) => {
      const jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.companies = jsonConvert.deserializeArray(result.body as any, Company);

      this.dataSource = new MatTableDataSource<Company>(this.companies);
      this.dataSource.paginator = this.paginator;

      this.isBusy = false;
    }, error => {
      this.isBusy = false;
      this.handleError(error);
    });

  }

  selectCompany(company) {
    this.isBusy = true;
    const selCompany = (company) ? company : new Company();

    this.storageService.setSelectedCompany(selCompany);
    this.messageService.sendMessage(MessageTypes.CompanySelected, selCompany);

    this.isBusy = false;
    this.router.navigate(['company/sites']);
  }

  registerCompany() {
    this.router.navigate(['register']);
  }

  editCompany(company) {
    this.storageService.setSelectedCompany(company);
    this.router.navigate(['companies/edit']);
  }
}

