<div class="row company-chats">
  <div class="col-3">
    <mat-action-list class="chats-sites-list">
      <mat-card class="card-container" *ngFor="let site of sites;index as i" (click)="siteChange(site)" [ngClass]="{'card-container-new-messages':(site.messagesCounter > 0),'selected-site':site.selected}">
        <span>{{site.name}}</span>
        <div class="pull-right" *ngIf="site.messagesCounter > 0" class="alert-count bg-alert-green">
          <span class="alert-count-inner">{{site.messagesCounter}}</span>
        </div>
      </mat-card>
    </mat-action-list>
  </div>
  <div class="col-9">
    <div class="site-chats-wrap">
      <app-site-chat #siteChatComponent *ngIf="isSelected" [resetFormSubject]="resetFormSubject.asObservable()"></app-site-chat>
    </div>
  </div>
</div>
<mat-spinner class="centered-spinner" *ngIf="!isSelected"></mat-spinner>

