import {JsonObject, JsonProperty} from 'json2typescript';
import {EVENT_TYPES} from '../enums/EventTypes';
import {Device} from './Device';

@JsonObject('DeviceLocation')
export class DeviceLocation {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('type', String, true)
  public type: string = EVENT_TYPES.DEVICE_LOCATION;

  @JsonProperty('deviceId', Number, true)
  public deviceId: number = undefined;

  @JsonProperty('device', Device, true)
  public device: Device = undefined;

  @JsonProperty('longitude', Number, true)
  public longitude: number = undefined;

  @JsonProperty('latitude', Number, true)
  public latitude: number = undefined;

  @JsonProperty('companyId', Number, true)
  public companyId: number = undefined;

  @JsonProperty('sentAt', Number, true)
  public sentAt: number = undefined;
}
