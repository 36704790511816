// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// export const environment = {
//   baseUrl: 'https://backend.fuziontracksecurity.co.za',
//   eventMicroserviceBaseUrl: 'https://servicebus.fuziontracksecurity.co.za',
//   socketBaseUrl: 'https://websocket.fuziontracksecurity.co.za',
//   reportBaseUrl: 'https://reporting.fuziontracksecurity.co.za',
//   production: true,
//   mapsAPIKey: 'AIzaSyDUQSCvHM2mpoQUHs_tjxngA9LLrKHcTCY',
//   TOPIC_SUFFIX: 'qa'
// };


export const environment = {
  baseUrl: 'https://fuzionapi.pepla.co.za',
  eventMicroserviceBaseUrl: 'https://fuzionservice.pepla.co.za',
  socketBaseUrl: 'https://fuzionsocket.pepla.co.za',
  reportBaseUrl: 'https://fuzionreporting.pepla.co.za',
  production: false,
  mapsAPIKey: 'AIzaSyDsgiH4vowj8jVCAW6ZvV5y5Ak7C-aqNYA',
  TOPIC_SUFFIX: 'peplaqa',
  name:'qa environment',
  code: 1002,
};