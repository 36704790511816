import {JsonObject, JsonProperty} from 'json2typescript';
import {EVENT_TYPES} from '../enums/EventTypes';
import {Employee} from './Employee';

@JsonObject ('AlertAlarmResponse')
export class AlertAlarmResponse {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('eventGuid', String, true)
  public eventGuid: string = undefined;

  @JsonProperty('type', String, true)
  public type: string = EVENT_TYPES.ALERT_ALARM_RESPONSE;

  @JsonProperty('message', String, true)
  public message: string = undefined;

  @JsonProperty('companyId', Number, true)
  public companyId: number = undefined;

  @JsonProperty('employeeId', Number, true)
  public employeeId: number = undefined;

  @JsonProperty('employee', Employee, true)
  public employee: number = undefined;

  @JsonProperty('respondedToGuid', String, true)
  public respondedToGuid: string = undefined;

  @JsonProperty('respondedToType', String, true)
  public respondedToType: string = undefined;

  @JsonProperty('topic', String, true)
  public topic: string = undefined;

  @JsonProperty('respondedAt', Number, true)
  public respondedAt: number = undefined;

}
