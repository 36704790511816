import {environment} from '../../environments/environment';

export const EVENT_TYPES = {
  PANIC: 'panic',
  ATTENDANCE: 'attendance',
  NFC_TAG_SCAN: 'nfcTagScan',
  NFC_TAG_SCAN_UNALLOCATED: 'nfcTagScanUnallocated',
  PATROL_NOTIFICATION: 'patrolNotification',
  PATROL_SCAN_ALERT: 'patrolScanAlert',
  PATROL_ENDED: 'patrolEnded',
  ALERT_ALARM_RESPONSE: 'alertAlarmResponse',
  DEVICE_LOCATION: 'deviceLocation',
  MESSAGE: 'message',
  DEVICE_LOCATION_CONFIG: 'deviceLocationConfig',
  PLEASE_CALL_ME: 'pleaseCallMe',
  DEVICE_OFFLINE: 'deviceOffline',
  DEVICE_BATTERY_LOW: 'deviceBatteryLow'
};

export const PATROL_ALERTS = {
  ONTIME: 'onTime',
  EARLY: 'early',
  LATE: 'late',
  FAILED: 'failed',
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
  ACTIVE: 'active',
  QUICK: 'quick',
  FAILED_TO_START: 'failedToStart'
};

export const ALERT_COLOR_CLASSIFICATIONS = {
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  MISC: 'misc'
};

export const GREEN_ALERTS = [EVENT_TYPES.PATROL_SCAN_ALERT, EVENT_TYPES.ATTENDANCE, EVENT_TYPES.NFC_TAG_SCAN, EVENT_TYPES.NFC_TAG_SCAN_UNALLOCATED];
export const YELLOW_ALERTS = [EVENT_TYPES.PATROL_ENDED];
export const RED_ALERTS = [EVENT_TYPES.PANIC];
export const MISC_ALERTS = [EVENT_TYPES.PLEASE_CALL_ME, EVENT_TYPES.DEVICE_BATTERY_LOW, EVENT_TYPES.DEVICE_OFFLINE];

export const EVENT_TOPICS = {
  CRITICAL: 'critical-' + environment.TOPIC_SUFFIX,
  PATROL: 'patrol-' + environment.TOPIC_SUFFIX,
  DEVICE_LOCATION: 'deviceLocation-' + environment.TOPIC_SUFFIX,
  CHAT: 'chat-' + environment.TOPIC_SUFFIX,
  ATTENDANCE: 'attendance-' + environment.TOPIC_SUFFIX,
  DEVICE_STATUS: 'devicestatus-' + environment.TOPIC_SUFFIX
};

export const CRITICAL_TOPIC_EVENTS = [EVENT_TYPES.PANIC];
export const PATROL_TOPIC_EVENTS = [EVENT_TYPES.NFC_TAG_SCAN, EVENT_TYPES.NFC_TAG_SCAN_UNALLOCATED, EVENT_TYPES.PATROL_SCAN_ALERT, EVENT_TYPES.PATROL_ENDED];
export const DEVICE_LOCATION_TOPIC_EVENTS = [EVENT_TYPES.DEVICE_LOCATION];
export const CHAT_TOPIC_EVENTS = [EVENT_TYPES.MESSAGE, EVENT_TYPES.PLEASE_CALL_ME, EVENT_TYPES.DEVICE_BATTERY_LOW];
export const ATTENDANCE_TOPIC_EVENTS = [EVENT_TYPES.ATTENDANCE];
export const DEVICE_STATUS_TOPIC_EVENTS = [EVENT_TYPES.DEVICE_BATTERY_LOW, EVENT_TYPES.DEVICE_OFFLINE];
