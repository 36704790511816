import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('RouteTagEnrollment')
export class RouteTagEnrollment {
  @JsonProperty('name', String, true)
  public name: string = undefined;

  @JsonProperty('serialNumber', String, true)
  public serialNumber: string = undefined;

  @JsonProperty('siteName', String, true)
  public siteName: string = undefined;

  @JsonProperty('routeName', String, true)
  public routeName: string = undefined;

  @JsonProperty('tagPosition', Number, true)
  public tagPosition: number = undefined;

  @JsonProperty('status', String, true)
  public status: string = undefined;
}
