import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('Area')
export class Area {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('name', String, true)
  public name: string = undefined;

  @JsonProperty('contactPerson', String, true)
  public contactPerson: string = undefined;

  @JsonProperty('contactEmail', String, true)
  public contactEmail: string = undefined;

  @JsonProperty('contactNumber', String, true)
  public contactNumber: string = undefined;

  @JsonProperty('companyId', Number, true)
  public companyId: number = undefined;

  @JsonProperty('shouldReceiveReports', Boolean, true)
  public shouldReceiveReports: boolean = undefined;
}
