import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {StorageService} from './storage-service';

@Injectable({
  providedIn: 'root'
})

export class ProfileService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  login(emailAddress: string, password: string) {
    const headers = this.getHeaders();

    const obj = {
      emailAddress: emailAddress,
      password
    };

    return this.http.post(`${environment.baseUrl}/api/v1/login`, obj, {
      headers,
      observe: 'response'
    });
  }

  getCurrentUser() {
    const headers = new HttpHeaders()
      .set('x-access-token', this.storageService.getAccessToken())
      .set('Content-Type', 'application/json');

    return this.http.get(`${environment.baseUrl}/api/v1/employee/me`, {
      headers,
      observe: 'response'
    });
  }

  resetPassword(email: string) {
    const headers = this.getHeaders();

    return this.http.post(`${environment.baseUrl}/api/v1/profile/resetPassword`, {emailAddress: email}, {
      headers,
      observe: 'response'
    });
  }

  updatePassword(code, password){
    const headers = this.getHeaders();
    return this.http.post(`${environment.baseUrl}/api/v1/profile/resetPasswordWeb`, {code: code, password: password}, {
      headers,
      observe: 'response'
    });
  }

  private getHeaders() {
    return new HttpHeaders()
      .set('Content-Type', 'application/json');
  }

}
