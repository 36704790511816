import {Injectable} from '@angular/core';
import {CommonService} from "./commonService";


@Injectable({
  providedIn: 'root'
})

export class ChatService {


  constructor(
    private commonService: CommonService
  ) {
  }

  getChats(siteId,createdAt) {
    return this.commonService.getData(`/api/v1/chats?siteId=${siteId}&createdAt=${createdAt}`,'');
  }

  insertChat(chatMessage) {
    return this.commonService.postDataServiceBus('/api/v1/chats', chatMessage);
  }

  uploadImage(image,extensionType,fileName){
    return this.commonService.postFile(`/api/v1/chats/image-web?name=${fileName}&extensionType=${extensionType}`, image);
  }

}
