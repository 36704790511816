<div class="breadcrumb">
  <ul *ngIf="previousPageName">
    <li class="clickable" (click)="goToPreviousPage()">{{previousPageName}}</li>
    <li>{{currentPageName}}</li>
  </ul>

  <button *ngIf="previousPageLink && shouldShow" class="btn btn-outline-dark btnBack" (click)="goToPreviousPage()">
    <span style="vertical-align: middle">Back</span>
  </button>
</div>

<div class="separator-breadcrumb"></div>
