import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('SchedulePlan')
export class SchedulePlan {

  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('routeId', Number, true)
  public routeId: number = undefined;

  @JsonProperty('siteId', Number, true)
  public siteId: number = undefined;

  @JsonProperty('scheduleId', Number, true)
  public scheduleId: number = undefined;

  @JsonProperty('startPositionTagId', Number, true)
  public startPositionTagId: number = undefined;

  @JsonProperty('randomizeStart', Boolean, true)
  public randomizeStart: Boolean = undefined;

  @JsonProperty('startTime', String, true)
  public startTime: string = undefined;

  @JsonProperty('endTime', String, true)
  public endTime: string = undefined;

}
