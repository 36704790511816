import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {Site} from '../../models/Site';
import {SiteServiceService} from '../../services/site.service';
import {StorageService} from '../../services/storage-service';
import * as moment from 'moment';
import {ReportService} from '../../services/report.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Area} from '../../models/Area';
import {AreaService} from '../../services/area.service';

@Pipe({name: 'safe'})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent extends ComponentBaseComponent implements OnInit {
  UTC_SHIFT_START_HOUR = 4;

  REPORT_PERIODS = {
    DAILY: 'Daily',
    MONTHLY: 'Monthly'
  };
  REPORT_TYPES = {
    SITE: 'site',
    AREA: 'area'
  };

  isBusy = false;

  companyId: number;
  reports = [];

  sites: Site[] = [];
  selectedSite: any;

  areas: Area[] = [];
  selectedArea;

  startDate: moment.Moment;
  endDate: moment.Moment;
  selectedReportPeriod = this.REPORT_PERIODS.DAILY;
  selectedReportType = this.REPORT_TYPES.SITE;
  isNextDateDisabled = true;

  constructor(
    private siteService: SiteServiceService,
    private areaService: AreaService,
    protected storageService: StorageService,
    private reportService: ReportService,
    public router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog) {
    super(snackBar, dialog, router, storageService);
  }

  ngOnInit(): void {
    this.resetDate();
    this.getSites();
    this.getAreas();
  }

  getReports() {
    this.isBusy = true;

    if (!this.companyId)
      this.companyId = this.storageService.getSelectedCompany().id;

    let startDateEpoch = this.startDate.toDate().getTime() / 1000;
    let endDateEpoch = this.endDate.toDate().getTime() / 1000;

    let serviceCall = null;

    if (this.selectedReportType === this.REPORT_TYPES.SITE) {
      if (this.selectedSite && this.selectedSite.id) {
        this.isBusy = true;
        serviceCall = this.reportService.getSiteReports(this.selectedSite.id, startDateEpoch, endDateEpoch);
      }
    } else {
      if (this.selectedArea && this.selectedArea.id) {
        this.isBusy = true;
        serviceCall = this.reportService.getAreaReports(this.selectedArea.id, startDateEpoch, endDateEpoch);
      }
    }

    if (serviceCall) {
      serviceCall.subscribe(result => {
        this.reports = result.body;

        this.isBusy = false;
      }, error => {
        this.isBusy = false;
        this.handleError(error);
      });
    }
  }

  getSites() {
    this.companyId = this.storageService.getSelectedCompany().id;
    this.selectedReportType = this.REPORT_TYPES.SITE;
    this.siteService.getAllSitesByCompany(this.companyId).subscribe(result => {
      const jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.sites = jsonConvert.deserializeArray(result.body as any, Site) as Site[];
      if (this.sites && this.sites.length > 0) {
        this.selectedSite = this.sites[0];
        this.getReports();
      }
    }, error => {
      this.handleError(error);
    });
  }

  getAreas() {
    if (!this.companyId) {
      this.companyId = this.storageService.getSelectedCompany().id;
    }

    this.areaService.getAreasByCompany(this.companyId).subscribe(result => {
      const jsonConvert = new JsonConvert();
      jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

      this.areas = jsonConvert.deserializeArray(result.body as any, Area) as Area[];

      this.selectedArea = this.areas[0];
    }, error => {
      this.handleError(error);
    });
  }

  getDateRange() {
    const startDateString = this.startDate.format('YYYY-MM-DD(Ha)');
    const endDateString = this.endDate.format('YYYY-MM-DD(Ha)');
    return startDateString + ' to ' + endDateString;
  }

  previousTimePeriod() {
    if (this.selectedReportPeriod === this.REPORT_PERIODS.DAILY) {
      this.startDate = this.startDate.subtract(1, 'days');
      this.endDate = this.endDate.subtract(1, 'days');
    } else {
      const date = this.startDate.toDate();
      date.setDate(1);

      this.startDate = moment(date).subtract(1, 'months');
      this.endDate = moment(this.startDate).add(1, 'months');
    }
    this.isNextDateDisabled = this.endDate.isSame(moment(), 'day');

    this.getReports();
  }

  nextTimePeriod() {
    if (this.selectedReportPeriod === this.REPORT_PERIODS.DAILY) {
      this.startDate = this.startDate.add(1, 'days');
      this.endDate = this.endDate.add(1, 'days');
    } else {
      const date = this.startDate.toDate();
      date.setDate(1);

      this.startDate = moment(date).add(1, 'months');
      this.endDate = moment(this.startDate).add(1, 'months');
    }
    this.isNextDateDisabled = this.endDate.isSame(moment(), 'day');

    this.getReports();
  }

  resetDate() {
    const currentDate = new Date(`${moment(new Date()).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`);

    if (this.selectedReportPeriod === this.REPORT_PERIODS.DAILY) {
      this.startDate = moment(currentDate).subtract(1, 'days');
      this.endDate = moment(this.startDate).add(1, 'days');
    } else {
      currentDate.setDate(1);

      this.startDate = moment(currentDate).add(1, 'months');
      this.endDate = moment(this.startDate).add(1, 'months');
    }
    this.isNextDateDisabled = this.endDate.isSame(moment(), 'day');

    this.getReports();
  }

  generateAndEmailReport() {
    const company = this.storageService.getSelectedCompany();
    const startDateEpoch = this.startDate.toDate().getTime() / 1000;
    const endDateEpoch = this.endDate.toDate().getTime() / 1000;

    let serviceCall;
    if (this.selectedReportType === this.REPORT_TYPES.AREA) {
      if (this.selectedArea && this.selectedArea.id) {
        serviceCall = this.reportService.generateAndEmailAreaReport(startDateEpoch, endDateEpoch, company, this.selectedArea.id);
      }
    } else {
      if (this.selectedSite && this.selectedSite.id) {
        serviceCall = this.reportService.generateAndEmailSiteReport(startDateEpoch, endDateEpoch, company, this.selectedSite.id);
      }
    }
    if (serviceCall) {
      this.isBusy = true;
      serviceCall.subscribe(result => {
        this.isBusy = false;
        this.showMessage('Report generated and emailed', MessageType.success);
        this.getReports();
      }, error => {
        this.isBusy = false;
        this.handleError(error);
      });
    } else {
      this.showMessage('Select a site or area', MessageType.warning);
    }
  }

  setIsBusy(value) {
    this.isBusy = value;
  }

}
