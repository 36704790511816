import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProfileService} from "../../services/profile-service";
import {StorageService} from "../../services/storage-service";
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends ComponentBaseComponent implements OnInit {

  email = '';
  isBusy = false;
  constructor(
    public dialog: MatDialog,
    protected router: Router,
    protected matSnackBar: MatSnackBar,
    private profileService: ProfileService,
    protected storageService: StorageService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>
  ) {
    super(matSnackBar, dialog, router, storageService);
  }

  ngOnInit() {
  }

  resetPassword() {
    this.isBusy = true;
    this.profileService.resetPassword(this.email).subscribe(() => {
      this.showMessage('Reset Link sent to Your email', MessageType.success);
      this.isBusy = false;
      this.dialogRef.close();
    }, error => {
      this.showMessage('Invalid Email Address', MessageType.error);
      this.isBusy = false;
      this.dialogRef.close();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

}
