import {JsonObject, JsonProperty} from 'json2typescript';
import {Site} from './Site';

@JsonObject ('Device')
export class Device {

  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('deviceName', String, true)
  public deviceName : string = undefined;

  @JsonProperty('contactNumber', String, true)
  public contactNumber: string = undefined;

  @JsonProperty('serialNumber', String, true)
  public serialNumber: string = undefined;

  @JsonProperty('appInstanceId', String, true)
  public appInstanceId: string = undefined;

  @JsonProperty('site', Site, true)
  public site: Site = new Site();

  public color: string = undefined;

  public selected = false;
}
