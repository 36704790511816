<breadcrumb previousPageName="Tags" [previousPageLink]="PREVIOUS_PAGE" [currentPageName]="getTagName()"
  [shouldShow]=true>
</breadcrumb>

<div class="card">
  <div class="card-body row">
    <div class="column" style="flex: 1; margin-right: 25px">
      <form [formGroup]="tagForm">
        <div class="form-group">
          <label>Tag name</label>
          <input formControlName="name" class="form-control form-control-rounded">
        </div>
        <div class="form-group">
          <label>Serial Number</label>
          <input formControlName="serialNumber" class="form-control form-control-rounded"
            [matAutocomplete]="autoTagSerial">
          <mat-autocomplete #autoTagSerial="matAutocomplete" (optionSelected)="unallocatedTagSelected($event)">
            <mat-option *ngFor="let tag of unallocatedTags" [value]="tag.serialNumber">
              {{tag.serialNumber}}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="form-group" style="margin-top: 15px;">
          <mat-checkbox formControlName="isPanic">Panic Tag</mat-checkbox>
        </div>
      </form>

      <div style="margin-top: auto" *ngIf="currEmployeeRole !== 'controller'">
        <hr>

        <div class="row">

          <button *ngIf="tagId > 0" class="btn btnDel btn-primary btn-icon mr-2" (click)="deleteTag()">
            <span class="ul-btn__icon">
              <mat-icon>delete</mat-icon>
            </span>
            <span class="ul-btn__text">Delete</span>
          </button>

          <button class="btn btn-primary btn-icon mr-2" (click)="upsertTag()">
            <span class="ul-btn__icon">
              <mat-icon>check</mat-icon>
            </span>
            <span class="ul-btn__text">Save</span>
          </button>

        </div>
      </div>

    </div>

    <!-- <div class="column" style="flex: 1">
      <google-map
        [center]="mapCenter"
        [zoom]="18"
        style="margin: auto"
      >

        <map-marker
          [position]="mapMarker.position"
          [options]="mapMarker.options"
          (mapDragend)="updateTagLocation($event)"
        >
        </map-marker>

      </google-map>
    </div> -->
  </div>

</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>