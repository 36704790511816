<head>
    <link rel="stylesheet" href="https://unpkg.com/leaflet-draw@latest/dist/leaflet.draw-src.css" />
</head>
<div>
    <div class="column card" style="margin-bottom: 15px;">
        <div class="card-body row">
            <div class="column flex-1">
                <div class="row">
                    <div class="col-6">
                        <form *ngIf="siteFormGroup" [formGroup]="siteFormGroup" style="margin-bottom: 32px">
                            <div class="form-group">
                                <label>Name</label>
                                <input class="form-control form-control-rounded" formControlName="name" placeholder="name">
                            </div>

                            <div class="form-group">
                                <label>Address</label>
                                <input placeholder="address" class="form-control form-control-rounded padding" formControlName="address" type="text" #searchAddress id="addressValueForMap">
                                <button type="button" class="btn padding padding-Address" (click)="getMapDetails()">Set address location on map</button>
                                <div class="loaderpadding padding-Address" id="addressLoader" style="display:none;"></div>
                            </div>

                            <div class="form-group">
                                <label>Division</label>
                                <input type="text" class="form-control form-control-rounded" formControlName="division"
                                       [matAutocomplete]="divisionAutoComplete" placeholder="division">

                                <mat-autocomplete #divisionAutoComplete>
                                    <mat-option *ngFor="let divisionOption of divisionOptions" [value]="divisionOption">
                                        {{divisionOption}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                            <div class="form-group">
                                <label>Branch</label>
                                <input type="text" class="form-control form-control-rounded" formControlName="branch" placeholder="branch"
                                       [matAutocomplete]="branchAutoComplete">

                                <mat-autocomplete #branchAutoComplete="matAutocomplete">
                                    <mat-option *ngFor="let branchOption of branchOptions" [value]="branchOption">{{branchOption}}</mat-option>
                                </mat-autocomplete>
                            </div>

                            <div class="form-group">
                                <label>Area</label>
                                <mat-select formControlName="areaId" class="form-control form-control-rounded">
                                    <mat-option *ngFor="let area of areas" [value]="area.id">{{area.name}}</mat-option>
                                </mat-select>
                            </div>

                        </form>
                    </div>
                    <div class="col-6">
                        <div id="siteLocation" class="siteLocation">
                            <div class="row">
                                <div class="col-12">
                                    <div id="map" #leafletmap class="map-styling padding-Address"></div>
                                </div>
                            </div>
                            <div class="row hidden">
                                <div class="col-4">
                                    <button class="btn map-polygon-button mr-2" (click)="editPologon()">
                                        <span class="ul-btn__text">Edit</span>
                                        <span class="ul-btn__icon">
                                            <mat-icon>edit</mat-icon>
                                        </span>
                                    </button>
                                    <button class="btn map-polygon-button mr-2" (click)="savePologon()">
                                        <span class="ul-btn__text">Save</span>
                                        <span class="ul-btn__icon">
                                            <mat-icon>save</mat-icon>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h4>Contacts</h4>
                    <H5>** Please take Note: Contact Number should start with valid country code, examples: +27(SA), 27(SA), 264(NAM), +267(BOT) etc. The system does not support SMS to Ghana. **</H5>
                    <div id="site-contact-container">
                        <div class="site-contact" id="site-contact-header">
                            <div class="contact-header-extra-splitter">
                                <span style="width: 15px">#</span>
                                <span>Name</span>
                                <span>Contact Number</span>
                                <span>Email Address</span>
                            </div>
                            <span style="width: 1px">&nbsp;</span>
                            <span>Receive Reports</span>
                            <span>Receive Panics</span>
                            <span>Receive Please Call</span>
                            <span>Receive Failed Patrols</span>
                            <span>Receive Battery Low</span>
                            <span>Receive Boundary Alert</span>
                            <span style="width: 1px"></span>
                        </div>
                        <div *ngFor="let siteContact of siteContacts; let i = index" class="site-contact">
                            <div style="width: 15px; display: flex">
                                <span style="margin-top: auto; margin-bottom: auto">{{i + 1}}</span>
                            </div>
                            <input type="text" class="form-control" [(ngModel)]="siteContacts[i].name"
                                   style="margin-top: auto; margin-bottom: auto" placeholder="Full Name">
                            <input type="tel" class="form-control" [(ngModel)]="siteContacts[i].contactNumber"
                                   placeholder="Contact Number" style="margin-top: auto; margin-bottom: auto;">
                            <input type="email" class="form-control" [(ngModel)]="siteContacts[i].emailAddress"
                                   placeholder="Email Address" style="margin-top: auto; margin-bottom: auto">
                            <div class="col">
                                <div class="row">
                                    <mat-icon aria-label="email" title="Email">email</mat-icon>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveReportsEmail"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceivePanicsEmail"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceivePleaseCallsEmail"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveFailedPatrolsEmail"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveBatteryLowEmail"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveBoundryEmail"></mat-checkbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <mat-icon title="Telegram">message</mat-icon>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveReportsTelegram"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceivePanicsTelegram"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceivePleaseCallsTelegram"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveFailedPatrolsTelegram"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveBatteryLowTelegram"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveBoundryTelegram"></mat-checkbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <mat-icon title="SMS">send</mat-icon>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveReportsPhone" disabled></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceivePanicsPhone"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceivePleaseCallsPhone"></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveFailedPatrolsPhone" disabled></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveBatteryLowPhone" disabled></mat-checkbox>
                                    </div>
                                    <div class="col should-receive-report-wrapper">
                                        <mat-checkbox [(ngModel)]="siteContacts[i].shouldReceiveBoundryPhone"></mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <mat-icon (click)="removeSiteContact(i)" class="clickable">clear</mat-icon>
                        </div>
                        <div id="site-contact-add" (click)="addSiteContact()">Add Contact</div>
                    </div>
                </div>
            </div>

        </div>
        <ng-container *ngIf="currEmployeeRole !== 'controller'">
            <hr>
            <div class="row">
                <button *ngIf="currentSite.id" class="btn btnDel btn-icon mr-2" (click)="deleteSite()">
                    <span class="ul-btn__icon">
                        <mat-icon>delete</mat-icon>
                    </span>
                    <span class="ul-btn__text">Delete</span>
                </button>
                <button class="btn btn-icon mr-2" (click)="updateSite()">
                    <span class="ul-btn__text">Save</span>
                    <span class="ul-btn__icon">
                        <mat-icon>save</mat-icon>
                    </span>
                </button>
            </div>
        </ng-container>
    </div>

    <mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
</div>