import {JsonObject, JsonProperty} from 'json2typescript';
import {Route} from './Route';
import {Device} from './Device';
import {Site} from './Site';

@JsonObject('RouteTaken')
export class RouteTaken {
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('type', String, true)
  public type: string = undefined;

  @JsonProperty('completedEventGuid', String, true)
  public completedEventGuid: string = undefined;

  @JsonProperty('completedEventTime', Number, true)
  public completedEventTime: string = undefined;

  @JsonProperty('routeId', Number, true)
  public routeId: number = undefined;

  @JsonProperty('route', Route, true)
  public route: Route = undefined;

  @JsonProperty('deviceId', Number, true)
  public deviceId: number = undefined;

  @JsonProperty('device', Device, true)
  public device: Device = undefined;

  @JsonProperty('siteId', Number, true)
  public siteId: number = undefined;

  @JsonProperty('site', Site, true)
  public site: Site = undefined;

  @JsonProperty('startPositionTagId', Number, true)
  public startPositionTagId: number = undefined;

  @JsonProperty('status', String, true)
  public status: string = undefined;

}
