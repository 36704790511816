import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Site} from '../../models/Site';
import {FormBuilder} from '@angular/forms';
import {CompanyService} from '../../services/company-service';
import {StorageService} from '../../services/storage-service';
import {RouteTagScanService} from '../../services/route-tag-scan.service';
import {Device} from '../../models/Device';
import {DeviceService} from '../../services/device-service';
import {RouteService} from '../../services/route-service';
import {RouteTag} from '../../models/RouteTag';
import {Route} from '../../models/Route';
import {DeviceLocation} from '../../models/DeviceLocation';
import {forkJoin} from 'rxjs';
import {EVENT_TOPICS, EVENT_TYPES} from "../../enums/EventTypes";
import {SocketService} from "../../services/socketService";
import {ComponentBaseComponent, MessageType} from "../../component-base";
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-site-map-routes',
  templateUrl: './site-map-routes.component.html',
  styleUrls: ['./site-map-routes.component.scss']
})
export class SiteMapRoutesComponent extends ComponentBaseComponent implements OnInit, OnDestroy {

  @Input() currentSite: Site;
  routeTags: RouteTag[] = [];
  devices: Device[] = [];
  selectedDevicesRoutes = [];
  selectedDevicesGpsLocations = [];
  routes: Route[] = [];
  routesCoords = [];
  isBusy = false;
  mapDate = new Date();
  nextDate = new Date();
  scrollWheel = null;
  routesMapZoom = 18;
  deviceCircleRadius = 10;
  deviceColors: string[] = [
    '#990000',
    '#576675',
    '#e9ffd5',
    '#183969',
    '#daa520',
    '#660066',
    '#e59e86',
    '#3bb44a',
    '#66cdaa',
    '#cfcac1',
    '#edfd00',
    '#00f6ff',
    '#2a391c'
  ];

  routeColors: string[] = [
    '#373742',
    '#794044',
    '#fe7d6a',
    '#9f7a42',
  ];

  deviceCircleRadii: number[] = [
    2000000,
    1000000,
    170000,
    120000,
    90000,
    70000,
    50000,
    20000,
    12000,
    8000,
    3000,
    1000,
    500,
    200,
    100,
    60,
    30,
    20,
    10,
    8,
    4,
    2,
    1]
  constructor(
    protected router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    protected storageService: StorageService,
    private companyService: CompanyService,
    private deviceService: DeviceService,
    private ngZone: NgZone,
    private routeTagScanService: RouteTagScanService,
    private routeService: RouteService,
    private socketService: SocketService,
    protected matSnackBar: MatSnackBar,
  ) { super(matSnackBar, dialog, router, storageService); }

  ngOnInit(): void {
    this.getAndShowSiteRouteTags();
    this.getSiteRoutes();
    this.getSiteDevices();
    this.dateSetRange();
    this.listenForSelectedDevicesGpsTracking();
    this.deviceCircleRadius = this.deviceCircleRadii[this.routesMapZoom];
  }

  getSiteDevices() {
    this.deviceService.getAllDevicesBySite(this.currentSite.id).subscribe((devices) => {
      const devicesData = devices.body as any;
      devicesData.forEach((data, index) => {
        const device = new Device();
        device.id = data.id;
        device.deviceName = data.deviceName;
        device.contactNumber = data.contactNumber;
        device.serialNumber = data.serialNumber;
        device.color = this.deviceColors[index];
        this.devices.push(device);
      });
    });
  }

  getSiteRoutes() {
    this.routeService.getAllRoutesBySiteId(this.currentSite.id).subscribe((result) => {
      this.isBusy = false;
      const routesData = result.body as any;

      routesData.forEach((routeData, index) => {
        const route = new Route();
        route.id = routeData.id;
        route.name = routeData.name;
        route.color = this.routeColors[index];
        this.routes.push(route);
      });
    });
  }

  getAndShowSiteRouteTags() {
    this.routeService.getAllRouteTagsBySiteId(this.currentSite.id).subscribe((results) => {
      this.isBusy = false;

      const tagsData = results.body as any;
      tagsData.forEach((tag) => {
        const routeTag = new RouteTag();
        routeTag.latitude = tag.latitude;
        routeTag.longitude = tag.longitude;
        routeTag.serialNumber = tag.serialNumber;
        routeTag.id = tag.id;
        routeTag.name = tag.name;
        this.routeTags.push(routeTag);
      });

    });
  }

  getTagsToRouteAndDraw(selectedRouteId, routeColor) {
    if (!this.routeSelected(selectedRouteId)) {
      this.isBusy = true;
      this.routes.filter((r) => r.id === selectedRouteId)[0].selected = true;
      this.routeService.getAllTagsToRouteByRouteId(selectedRouteId).subscribe((result) => {
        this.isBusy = false;

        const tagsToRouteData = (result.body as any)[0];
        const routeCoords = [];
        tagsToRouteData.forEach((tagToRouteData) => {
          const routeTag = this.routeTags.filter((tag) => tag.id === tagToRouteData.tagId)[0];
          if(routeTag){
            routeCoords.push({
              lat: routeTag.latitude,
              lon: routeTag.longitude,
            });
          }
        });

        this.routesCoords.push({
          routeId: selectedRouteId,
          color: routeColor,
          coordinates: routeCoords
        });
      });
    }
  }

  routeSelected(routeId): boolean {
    const route = this.routesCoords.filter((routeCoords) => routeCoords.routeId === routeId)[0];
    const routeIndex = this.routesCoords.indexOf(route);
    if (routeIndex > -1) {
      this.routesCoords.splice(routeIndex, 1);
      this.routes.filter((r) => r.id === routeId)[0].selected = false;
      return true;
    } else {
      return false;
    }
  }

  fetchDeviceLocations(device) {
    if (!this.deviceSelected(device.id)) {
      this.isBusy = true;
      this.devices.filter((d) => d.id === device.id)[0].selected = true;
      forkJoin([this.deviceService.getDeviceLastLocation(device.id), this.deviceService.getDeviceLocationsByDate(device.id, this.mapDate.toDateString())]).subscribe((results) => {
        this.isBusy = false;
        const deviceLastLocationData = results[0].body as any;

        if(deviceLastLocationData != null){
          this.selectedDevicesGpsLocations.push({
            deviceId: device.id,
            coordinates: {
              lat: deviceLastLocationData.latitude,
              lng: deviceLastLocationData.longitude
            },
            sentAt: deviceLastLocationData.sentAt,
            strokeColor: '#000000',
            strokeWeight: 2,
            fillColor: device.color,
            radius: this.deviceCircleRadii[this.routesMapZoom]
          });
        } else {
          this.showMessage(`${device.deviceName} has no gps location available`, MessageType.warning)
        }

        const deviceLocationsData = results[1].body as any;
        const deviceLocations: DeviceLocation[] = [];
        if (deviceLocationsData != null && deviceLocationsData.length > 0) {
          deviceLocationsData.forEach((deviceLocationData) => {
            const deviceLocation = new DeviceLocation();
            deviceLocation.latitude = deviceLocationData.latitude;
            deviceLocation.longitude = deviceLocationData.longitude;
            deviceLocation.sentAt = deviceLocationData.sentAt;
            deviceLocations.push(deviceLocation);
          });
        } else {
          this.showMessage(`${device.deviceName} has no routes for ${this.getDateRangeString()}`, MessageType.warning)
        }
        this.selectedDevicesRoutes.push({
          deviceId: device.id,
          color: device.color,
          locations: deviceLocations,
        });

        if ((deviceLocationsData == null || deviceLocationsData.length == 0) && deviceLastLocationData == null){
          this.showMessage(`${device.deviceName} has no location or routes for ${this.getDateRangeString()}`, MessageType.warning)
        }
      });
    }
  }

  deviceSelected(deviceId): boolean {
    const selectedDevice = this.devices.filter((d) => d.id === deviceId && d.selected === true)[0];
    const selectedDeviceIndex = this.devices.indexOf(selectedDevice);
    if (selectedDeviceIndex > -1) {
      this.devices[selectedDeviceIndex].selected = false;
      this.checkSelectedDevicesRoutes(deviceId);
      this.checkSelectedDevicesGpsLocations(deviceId);
      return true;
    } else {
      return false;
    }
  }

  checkSelectedDevicesRoutes(deviceId) {
    const device = this.selectedDevicesRoutes.filter((deviceRoutes) => deviceRoutes.deviceId === deviceId)[0];
    const deviceIndex = this.selectedDevicesRoutes.indexOf(device);
    if (deviceIndex > -1) {
      this.selectedDevicesRoutes.splice(deviceIndex, 1);
    }
  }

  checkSelectedDevicesGpsLocations(deviceId) {
    const device = this.selectedDevicesGpsLocations.filter((deviceLocations) => deviceLocations.deviceId === deviceId)[0];
    const deviceIndex = this.selectedDevicesGpsLocations.indexOf(device);
    if (deviceIndex > -1) {
      this.selectedDevicesGpsLocations.splice(deviceIndex, 1);
    }
  }

  dateSetRange(){
    const day = new Date(this.mapDate);
    const nextDate = new Date(day);
    nextDate.setDate(day.getDate() + 1);
    this.nextDate = nextDate;
    this.resetSelectedDevices();
  }

  getDateRangeString(){
    return this.mapDate.toDateString()+ ", 6AM - "+ this.nextDate.toDateString()+ ", 6AM";
  }

  resetSelectedDevices(){
    this.selectedDevicesRoutes = [];
    this.selectedDevicesGpsLocations = [];
    const selectedDevices = this.devices.filter((d) => d.selected === true);
    selectedDevices.forEach((device) => {
      device.selected = false;
    })
  }

  listenForSelectedDevicesGpsTracking() {
    this.socketService.getSocket().on(`${EVENT_TOPICS.DEVICE_LOCATION}.${EVENT_TYPES.DEVICE_LOCATION}`, (event) => {
      if (!event) {
        console.error('GOT EMPTY DATA PAYLOAD ON SOCKET FOR ' + `${EVENT_TOPICS.DEVICE_LOCATION}.${EVENT_TYPES.DEVICE_LOCATION}`);
        return;
      }
      const device = this.selectedDevicesGpsLocations.filter((deviceLocations) => deviceLocations.deviceId === Number(event.deviceId))[0];
      const deviceIndex = this.selectedDevicesGpsLocations.indexOf(device);
      if (deviceIndex > -1) {
        this.selectedDevicesGpsLocations[deviceIndex].coordinates.lat = event.latitude;
        this.selectedDevicesGpsLocations[deviceIndex].coordinates.lng = event.longitude;
      }
    });
  }

  ngOnDestroy(): void {
    this.socketService.getSocket().off(`${EVENT_TOPICS.DEVICE_LOCATION}.${EVENT_TYPES.DEVICE_LOCATION}`);
  }

  routesMapZoomChange(zoom) {
    this.routesMapZoom = zoom;
    this.selectedDevicesGpsLocations.forEach((device) => {
      device.radius = this.deviceCircleRadii[zoom];
    })
  }

}
