<div class="action-bar" *ngIf="currEmployeeRole !== 'controller'">
  <button class="btn btn-icon mr-2"  (click)="selectTag(null)">
    <span class="ul-btn__text">Add a new Tag</span>
    <span class="ul-btn__icon"><mat-icon>add</mat-icon></span>
  </button>
</div>

<div class="card">
  <div class="card-body">
    <table mat-table [dataSource]="tags" class="table-hover">
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
      <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef> Serial Number</th>
        <td mat-cell *matCellDef="let element"> {{element.serialNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="isPanic">
        <th mat-header-cell *matHeaderCellDef>Is Panic</th>
        <td mat-cell *matCellDef="let element" class="">
          <mat-icon *ngIf="element.isPanic" matTooltip="This is a panic tag." matTooltipPosition="above" class="panic_styling">check_circle_outline</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon>chevron_right</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          (click)="selectTag(tags[i])"></tr>
    </table>
  </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
