<breadcrumb previousPageName="Companies"
            [previousPageLink]="PREVIOUS_PAGE"
            [currentPageName]="companyForm.value.name">
</breadcrumb>

<div class="card">
  <div class="card-header">Company info</div>

  <div class="card-body">
    <form [formGroup]="companyForm" class="column">
      <div class="form-group">
        <label for="companyName">Company Name</label>
        <input class="form-control form-control-rounded" formControlName="name" placeholder="Company Name" id="companyName">
      </div>

      <div class="form-group">
        <label for="address">Address</label>
        <input class="form-control form-control-rounded" formControlName="address" placeholder="Address" id="address">
      </div>

      <div class="form-group">
        <label for="contactPerson">Contact Person</label>
        <input class="form-control form-control-rounded" formControlName="contactPerson" placeholder="Contact Person" id="contactPerson">
      </div>

      <div class="form-group">
        <label for="contactNumber">Contact Number</label>
        <input class="form-control form-control-rounded" formControlName="contactNumber" placeholder="Contact Number" type="tel" id="contactNumber">
      </div>

      <div class="form-group">
        <label for="contactNumber">Contact Email Address</label>
        <input class="form-control form-control-rounded" formControlName="contactEmail" placeholder="Contact Number" type="tel" id="contactEmail">
      </div>
    </form>

    <hr>
    <div class="row">
      <button class="btn btnDel btn-primary btn-icon" (click)="companyDelete()">
        <span class="ul-btn__icon"><mat-icon>delete</mat-icon></span>
        <span class="ul-btn__text">Delete</span>
      </button>
      <button class="btn btn-primary btn-icon mr-2" (click)="updateCompany()">
        <span class="ul-btn__icon"><mat-icon>check</mat-icon></span>
        <span class="ul-btn__text">Save Company</span>
      </button>
    </div>
  </div>

</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
