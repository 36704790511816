<breadcrumb [previousPageName]="'Routes'" [previousPageLink]="PREVIOUS_PAGE" [currentPageName]="getCurrentPageName()"
  [shouldShow]=true>
</breadcrumb>

<div class="row" style="margin: 0">

  <div class="column" style="flex: 1; margin-right: 25px">
    <div class="card" style="height: 100%; padding: 0">
      <div class="card-header">
        <span style="display:block">{{upsertRouteCardTitle}}</span>
        <span *ngIf="upcomingScheduleAheadCount != 0" style="display:block">Existing scheduled plans :
          {{upcomingScheduleAheadCount}}</span>
        <p class="card-subtitle">click on a tag on the right to add/remove it from the the route</p>
      </div>

      <div class="card-body">
        <div class="row" style="width: 100%; margin: auto;">
          <div class="form-group" style="flex: 3">
            <label>Route Name</label>
            <input class="form-control form-control-rounded" type="text" placeholder="Route Name"
              [(ngModel)]="route.name" />
          </div>

          <div style="flex: 1"></div>

          <div class="form-group" style="flex: 3">
            <label>Patrol Duration (minutes)</label>
            <input class="form-control form-control-rounded" type="number" placeholder="Estimated Duration"
              [(ngModel)]="route.durationMinutes" />
          </div>
        </div>

        <div class="row" id="createRouteTableHeader">
          <span>Tag</span>
          <span>Position</span>
        </div>

        <div cdkDropList (cdkDropListDropped)="drop($event)">
          <div cdkDrag class="card row tag-card" *ngFor="let tag of selectedTags; let i = index;">
            <p class="tag-title">{{tag.name}}</p>
            <mat-icon>drag_handle</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="column" style="flex: 1">
    <!-- <section class="slider-section">
       <mat-slide-toggle class="slider-margin" [(ngModel)]="checked">
        Map View
      </mat-slide-toggle>
    </section> -->
    <div class="card" *ngIf="!checked" style="height: 100%; padding: 0">
      <div class="card-header">Tags for this site</div>
      <div class="card-body">
        <mat-selection-list>
          <mat-list-option *ngFor="let tag of tags" [value]="tag" class="hoverable tag-title" (click)="tagClick(tag)"
            [selected]="isTagSelected(tag)">
            {{tag.name}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!-- <div class="card" *ngIf="checked" style="padding: 0">
      <div class="card-header">Tags for this site - Map View</div>
      <div class="card-body">
        <google-map
          [center]="center"
          [zoom]="zoom"
        >
          <map-marker
            #markerElem
            *ngFor="let marker of markers"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
            (mapClick)="openInfo(markerElem, marker.info)"
          >
          </map-marker>
          <map-info-window>
            {{infoContent}}
          </map-info-window>
        </google-map>
      </div>
    </div>
  </div>
</div> -->

    <ng-container *ngIf="(selectedTags.length > 0) && !(currEmployeeRole === 'controller')">
      <hr>
      <div class="row" style="margin-bottom: 15px">
        <button *ngIf="route.id > 0" class="btn btnDel btn-primary btn-icon mr-2" (click)="deleteRoute()">
          <span class="ul-btn__icon">
            <mat-icon>delete</mat-icon>
          </span>
          <span class="ul-btn__text">Delete Route</span>
        </button>
        <button class="btn btn-primary btn-icon mr-2" (click)="upsertRoute()">
          <span class="ul-btn__icon">
            <mat-icon>check</mat-icon>
          </span>
          <span class="ul-btn__text">Save Route</span>
        </button>
      </div>
    </ng-container>

    <mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>