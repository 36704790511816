
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {SitesSummaryComponent} from './components/sites-summary/sites-summary.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RegisterComponent} from './components/register/register.component';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SiteDetailComponent} from './components/site-detail/site-detail.component';
import {RouteDetailComponent} from './components/route-detail/route-detail.component';
import {MatListModule} from '@angular/material/list';
import {DeviceSummaryComponent} from './components/device-summary/device-summary.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DeviceDetailComponent} from './components/device-detail/device-detail.component';
import {MatSelectModule} from '@angular/material/select';
import {CompanyNavbarComponent} from './components/company-navbar/company-navbar.component';
import {EmployeeSummaryComponent} from './components/employee-summary/employee-summary.component';
import {EmployeeDetailComponent} from './components/employee-detail/employee-detail.component';
import {CompanySummaryComponent} from './components/company-summary/company-summary.component';
import {CompanyDetailsComponent} from './components/company-details/company-details.component';
import {GoogleMapsModule} from '@angular/google-maps';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouteSummaryComponent } from './components/route-summary/route-summary.component';
import { TagSummaryComponent } from './components/tag-summary/tag-summary.component';
import { TagDetailComponent } from './components/tag-detail/tag-detail.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {AgmCoreModule, MarkerManager} from '@agm/core';
import {SiteChatComponent} from './components/site-chat/site-chat.component';
import {MessageItemComponent} from './components/message-item/message-item.component';
import {SendImageViewComponent} from './components/send-image-view/send-image-view.component';
import { AlertsSummaryComponent } from './components/alerts-summary/alerts-summary.component';
import { AlertItemComponent } from './components/alert-item/alert-item.component';
import { AlertNotificationsComponent } from './components/alert-notifications/alert-notifications.component';
import {environment} from '../environments/environment';
import { ScheduleSummaryComponent } from './components/schedule-summary/schedule-summary.component';
import { SiteMapRoutesComponent } from './components/site-map-routes/site-map-routes.component';
import { AlertDetailsComponent } from './components/alert-details/alert-details.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {ReportsComponent, SafePipe} from './components/reports/reports.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ScheduleDetailsComponent } from './components/schedule-details/schedule-details.component';
import { SysAdminNavbarComponent } from './components/sys-admin-navbar/sys-admin-navbar.component';
import {RoleGuard} from './guards/role.guard';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SiteextradetailComponent } from './components/siteextradetail/siteextradetail.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CompanyChatComponent } from './components/company-chat/company-chat.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { AreaDetailComponent } from './components/area-detail/area-detail.component';
import { AreaSummaryComponent } from './components/area-summary/area-summary.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import { ReportsCsvComponent } from './components/reports-csv/reports-csv.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TagEnrollmentComponent } from './components/tag-enrollment/tag-enrollment.component';
import { AttendanceComponent } from './components/attendance-summary/attendance-summary.component';
import { CommonModule } from '@angular/common';
import { TrackingDetailComponent } from './components/tracking-detail/tracking-detail.component';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    SitesSummaryComponent,
    RegisterComponent,
    SiteDetailComponent,
    RouteDetailComponent,
    DeviceSummaryComponent,
    DeviceDetailComponent,
    EmployeeSummaryComponent,
    EmployeeDetailComponent,
    DeviceDetailComponent,
    CompanySummaryComponent,
    CompanyDetailsComponent,
    CompanyNavbarComponent,
    ConfirmDialogComponent,
    BreadcrumbComponent,
    RouteSummaryComponent,
    TagSummaryComponent,
    TagDetailComponent,
    AlertsSummaryComponent,
    AlertItemComponent,
    AlertNotificationsComponent,
    SiteChatComponent,
    MessageItemComponent,
    SendImageViewComponent,
    SiteMapRoutesComponent,
    ScheduleSummaryComponent,
    ScheduleDetailsComponent,
    AlertDetailsComponent,
    ReportsComponent,
    SysAdminNavbarComponent,
    SidebarComponent,
    SiteextradetailComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CompanyChatComponent,
    AreaDetailComponent,
    AreaSummaryComponent,
    ReportsCsvComponent,
    DashboardComponent,
    TagEnrollmentComponent,
    AttendanceComponent,
    TrackingDetailComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatToolbarModule,
        MatIconModule,
        MatTabsModule,
        MatTooltipModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        HttpClientModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDialogModule,
        MatListModule,
        GoogleMapsModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        CommonModule,
        AgmCoreModule.forRoot({
            apiKey: environment.mapsAPIKey
        }),
        MatExpansionModule,
        GooglePlaceModule,
        DragDropModule,
        MatAutocompleteModule,
        MatRadioModule,
        QRCodeModule
    ],
  entryComponents: [
    SendImageViewComponent
  ],
  providers: [
    MarkerManager,
    RoleGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
