import { Injectable } from '@angular/core';
import { CommonService} from './commonService';

@Injectable({
  providedIn: 'root'
})
export class RouteTagScanService {

  constructor(private commonService: CommonService) { }

  retrieveTagScans(date, deviceId) {
    return this.commonService.postData('/api/v1/routeTagScan/retrieve', {date, deviceId});
  }
}
