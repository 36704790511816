import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('SchedulePlanTag')
export class SchedulePlanTag {

  @JsonProperty('routeId', Number, true)
  public routeId: number = undefined;

  @JsonProperty('startTime', Number, true)
  public startTime: number = undefined;

  @JsonProperty('endTime', Number, true)
  public endTime: number = undefined;

  @JsonProperty('startPositionTagId', Number, true)
  public startPositionTagId: number = undefined;

  @JsonProperty('routeTakenId', Number, true)
  public routeTakenId: number = undefined;

  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('name', String, true)
  public name: string = undefined;

  @JsonProperty('routeName', String, true)
  public routeName: string = undefined;

  @JsonProperty('estimatedScanEpoch', Number, true)
  public estimatedScanEpoch: number = undefined;
}
