<div class="page-title">
  <p>CSV Reports</p>
  <hr>
</div>

<div class="card row" id="query-bar">
  <div class="row">
    <div class="row">
      <label>Select a Site:</label>

      <mat-form-field>
        <mat-select [(ngModel)]="selectedSite">
          <mat-option *ngFor="let site of sites" [value]="site">
            {{site.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>

  <div>
  </div>

  <div class="row">
    <label>Select Report Date Range: </label>

    <div class="input-group" [matTooltip]="'Date starts at shift time (' + getShiftStartTimeString() + ')'">
      <div class="input-group-prepend">
        <span class="input-group-text" style="padding: 0"><mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle></span>
      </div>
      <input class="form-control" style="height: auto" [matDatepicker]="startDatePicker" [(ngModel)]="startDate">

      <mat-datepicker #startDatePicker></mat-datepicker>
    </div>

    <span style="margin: auto 10px">to</span>

    <div class="input-group" [matTooltip]="'Date starts at shift time (' + getShiftStartTimeString() + ')'">
      <div class="input-group-prepend">
        <span class="input-group-text" style="padding: 0"><mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle></span>
      </div>
      <input class="form-control" style="height: auto" [matDatepicker]="endDatePicker" [(ngModel)]="endDate">

      <mat-datepicker #endDatePicker></mat-datepicker>
    </div>
  </div>

</div>

<div class="row">
  <button class="btn btn-primary" style="margin-right: 10px" [disabled]="!selectedSite" (click)="downloadGeneralReport()">Download General Report</button>
  <button class="btn btn-primary" [disabled]="!selectedSite || !startDate || !endDate" (click)="downloadActivityReport()">Download Activity Report</button>
</div>
