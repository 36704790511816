import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('TagToRoute')
export class TagToRoute
{
  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('tagId', Number, true)
  public tagId: number = undefined;

  @JsonProperty('routeId', Number, true)
  public routeId: number = undefined;

  @JsonProperty('position', Number, true)
  public position: number = undefined;
}
