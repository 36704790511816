import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject ('Company')
export class Company {

  @JsonProperty('id', Number, true)
  public id: number = undefined;

  @JsonProperty('name', String, true)
  public name: string = undefined;

  @JsonProperty('address', String, true)
  public address: string = undefined;

  @JsonProperty('contactPerson', String, true)
  public contactPerson: string = undefined;

  @JsonProperty('contactNumber', String, true)
  public contactNumber: string = undefined;

  @JsonProperty('contactEmail', String, true)
  public contactEmail: string = undefined;

}
