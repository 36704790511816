import {Component, Input, OnInit} from '@angular/core';
import {ChatMessage} from '../../models/ChatMessage';
import * as moment from 'moment';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.css']
})
export class MessageItemComponent implements OnInit {
  @Input() message: ChatMessage;
  @Input() currentUserId: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  isMyMessage(message) {
    if (message.deviceId) {
      return false;
    } else {
      return message.userId == this.currentUserId;
    }
  }


  formatDate(createdAt: number) {
    let formattedDate = moment.unix(createdAt).format('HH:mm - MM/DD');
    return formattedDate !== 'Invalid date' ? formattedDate : 'sent now ...';
  }
}
