<breadcrumb [currentPageName]="currentSite.name" [previousPageLink]="PREVIOUS_PAGE" [previousPageName]="'Sites'">
</breadcrumb>

<div class="row">

    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4" routerLinkActive="nav-selected"
         routerLink="/company/site/xtra/details">
        <a class="nav-item-hold" routerLinkActive="nav-selected">
            <div class="card-body text-center">
                <div class="content">
                    <p class="mt-2 mb-0">Site Detail</p>
                </div>
            </div>
        </a>
    </div>

    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4" routerLink="/company/site/xtra/devices"
         routerLinkActive="nav-selected">
        <a class="nav-item-hold" routerLinkActive="nav-selected">
            <div class="card-body text-center">
                <div class="content">
                    <p class="mt-2 mb-0">Devices</p>
                    <!--          <p class="text-primary text-24 line-height-1 mb-2">22</p>-->
                </div>
            </div>
        </a>
    </div>

    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4" routerLink="/company/site/xtra/tags"
         routerLinkActive="nav-selected">
        <a class="nav-item-hold" routerLinkActive="nav-selected">
            <div class="card-body text-center">
                <div class="content">
                    <p class="mt-2 mb-0" style="margin-top: 0; padding: 0">Tags</p>
                    <!--          <p class="text-primary text-24 line-height-1 mb-2">25</p>-->
                </div>
            </div>
        </a>
    </div>

    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4" routerLink="/company/site/xtra/routes"
         routerLinkActive="nav-selected">
        <a class="nav-item-hold" routerLinkActive="nav-selected">
            <div class="card-body text-center">
                <div class="content">
                    <p class="mt-2 mb-0">Routes</p>
                    <!--          <p class="text-primary text-24 line-height-1 mb-2">4</p>-->
                </div>
            </div>
        </a>
    </div>


    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4" routerLink="/company/site/xtra/schedule"
         routerLinkActive="nav-selected">
        <a class="nav-item-hold" routerLinkActive="nav-selected">
            <div class="card-body text-center">
                <div class="content">
                    <p class="mt-2 mb-0">Schedules</p>
                </div>
            </div>
        </a>
    </div>

    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4" routerLink="/company/site/xtra/tracking" routerLinkActive="nav-selected">
        <a class="nav-item-hold" routerLinkActive="nav-selected">
            <div class="card-body text-center">
                <div class="content">
                    <p class="mt-2 mb-0">Tracking</p>
                </div>
            </div>
        </a>
    </div>
</div>

<router-outlet></router-outlet>