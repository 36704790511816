<div class="auth-layout-wrap" style="background-image: url(../../../assets/dist-assets/images/photo-wide-4.jpg)">
  <div class="auth-content">
    <div class="card o-hidden">
      <form [formGroup]="registrationForm">
        <div class="card-header">
        <h1 class="text-26 mb-0">Register Company</h1>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="p-4">
              <h1 class="mb-3 text-18">User Details</h1>
              <div class="form-group">
                <label>First Name</label>
                <input class="form-control form-control-rounded" placeholder="First Name" formControlName="userName">
              </div>

              <div class="form-group">
                <label>Surname</label>
                <input class="form-control form-control-rounded" placeholder="Surname" formControlName="surname">
              </div>

              <div class="form-group">
                <label>Email Address</label>
                <input class="form-control form-control-rounded" placeholder="Email Address"
                       formControlName="emailAddress">
              </div>

              <div class="form-group">
                <label>Password</label>
                <input class="form-control form-control-rounded" placeholder="Password" type="password"
                       formControlName="password">
              </div>

              <div class="form-group">
                <label>Confirm Password</label>
                <input class="form-control form-control-rounded" placeholder="Confirm Password" type="password"
                       formControlName="confirmPassword">
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-4">
              <h1 class="mb-3 text-18">Company Details</h1>
              <div class="form-group">
                <label>Company Name</label>
                <input class="form-control form-control-rounded" placeholder="Company Name"
                       formControlName="companyName">
              </div>
              <div class="form-group">
                <label>Address</label>
                <input class="form-control form-control-rounded" placeholder="Address" formControlName="address">
              </div>
              <div class="form-group">
                <label>Contact Person</label>
                <input class="form-control form-control-rounded" placeholder="Contact Person"
                       formControlName="contactPerson">
              </div>
              <div class="form-group">
                <label>Contact Number</label>
                <input class="form-control form-control-rounded" placeholder="Contact Number"
                       formControlName="contactNumber">
              </div>
              <div class="form-group">
                <label>Contact Email Address</label>
                <input class="form-control form-control-rounded" placeholder="Contact Email Address"
                       formControlName="contactEmail">
              </div>
            </div>
          </div>
          <button class="btn btn-primary btn-block btn-rounded mt-3" (click)="register()">Register</button>
          <button class="btn btn-primary btn-block btn-rounded mt-3" routerLink="/companies">Back</button>

        </div>
      </form>
    </div>
  </div>
</div>
<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
