<breadcrumb previousPageName="Schedules"
            [previousPageLink]="PREVIOUS_PAGE"
            [currentPageName]="getCurrentPageName()"
            [shouldShow]=true>
</breadcrumb>

<div *ngIf="userTimezoneOffset !== REQUIRED_SCHEDULE_TIMEZONE_OFFSET" id="timezone-warning">Schedules must be setup with South African time (UTC/GMT +02:00). Your device is in UTC/GMT {{getTimezoneOffsetString(userTimezoneOffset)}}</div>

<div class="card">
  <div class="card-header">
    Schedule info
  </div>

  <div *ngIf="scheduleForm" class="card-body">
    <form [formGroup]="scheduleForm" class="column">

      <div class="form-group">
        <mat-form-field>
          <mat-label>Route</mat-label>
          <mat-select formControlName="routeId" required>
            <mat-option *ngFor="let route of routes" [value]="route">
              {{route.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group">
        <label for="startTimeDay">
          Start Time Day
          <mat-checkbox [checked]="selectedDayTimes" (change)="selectedDayTimesChange($event)"></mat-checkbox>
        </label>
        <input class="form-control form-control-rounded" formControlName="startTimeDay" placeholder="HH:mm"
               id="startTimeDay">
      </div>

      <div class="form-group">
        <label for="endTimeDay">
          End Time Day
        </label>
        <input class="form-control form-control-rounded" formControlName="endTimeDay" placeholder="18:00"
               id="endTimeDay">
      </div>

      <div class="form-group">
        <label for="startTimeNight">
          Start Time Night
          <mat-checkbox [checked]="selectedNightTimes" (change)="selectedNightTimesChange($event)"></mat-checkbox>
        </label>
        <input class="form-control form-control-rounded" formControlName="startTimeNight" placeholder="19:00"
               id="startTimeNight">
      </div>

      <div class="form-group">
        <label for="endTimeNight">
          End Time Night
        </label>
        <input class="form-control form-control-rounded" formControlName="endTimeNight" placeholder="5:00"
               id="endTimeNight">
      </div>

      <div class="form-group">
        <label for="repeatMinutes">Repeat Every</label>
        <input class="form-control form-control-rounded" formControlName="repeatMinutes" placeholder="20"
               id="repeatMinutes">
      </div>

      <div class="form-group">
        <mat-checkbox formControlName="randomizeStart" id="randomizeStart"
                      [checked]="scheduleForm.value.randomizeStart">Randomize Starting Point</mat-checkbox>
      </div>

      <div class="form-group">
        <mat-checkbox formControlName="monday" id="monday"
                      [checked]="scheduleForm.value.monday">Monday</mat-checkbox>
      </div>

      <div class="form-group">
        <mat-checkbox formControlName="tuesday" id="tuesday"
                      [checked]="scheduleForm.value.tuesday">Tuesday</mat-checkbox>
      </div>

      <div class="form-group">
        <mat-checkbox formControlName="wednesday" id="wednesday"
                      [checked]="scheduleForm.value.wednesday">Wednesday</mat-checkbox>
      </div>

      <div class="form-group">
        <mat-checkbox formControlName="thursday" id="thursday"
                      [checked]="scheduleForm.value.thursday">Thursday</mat-checkbox>
      </div>

      <div class="form-group">
        <mat-checkbox formControlName="friday" id="friday"
                      [checked]="scheduleForm.value.friday">Friday</mat-checkbox>
      </div>

      <div class="form-group">
        <mat-checkbox formControlName="saturday" id="saturday"
                      [checked]="scheduleForm.value.saturday">Saturday</mat-checkbox>
      </div>

      <div class="form-group">
        <mat-checkbox formControlName="sunday" id="sunday"
                      [checked]="scheduleForm.value.sunday">
          Sunday
        </mat-checkbox>
      </div>
    </form>

    <ng-container *ngIf="currEmployeeRole !== 'controller'">
      <hr>
      <div class="row" style="margin-bottom: 16px">
        <button *ngIf="selectedSchedule.id > 0" class="btn btnDel btn-primary btn-icon mr-2" (click)="deleteSchedule()">
          <span class="ul-btn__icon"><mat-icon>delete</mat-icon></span>
          <span class="ul-btn__text">Delete Schedule</span>
        </button>
        <button class="btn btn-primary btn-icon mr-2" (click)="updateSchedule()">
          <span class="ul-btn__icon"><mat-icon>check</mat-icon></span>
          <span class="ul-btn__text">Save Schedule</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
