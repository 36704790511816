<div class="auth-layout-wrap" style="background-image: url(../../../assets/dist-assets/images/photo-wide-4.jpg)">
  <div class="auth-content">
    <div class="card o-hidden">
      <div class="row">
        <div class="col-md-12">
          <div class="p-4">
            <div class="auth-logo text-center mb-4"><img src="../../../assets/images/logo.png" alt=""></div>
            <h1 class="mb-3 text-18">Sign In</h1>
            <div class="form-group">
              <label for="email">Email address</label>
              <input class="form-control form-control-rounded" id="email" type="email" [(ngModel)]="emailAddress">
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input (keydown.enter)="onPasswordEnter()" class="form-control form-control-rounded" id="password" type="password" [(ngModel)]="password">
            </div>
            <button class="btn btn-rounded btn-primary btn-block mt-2" (click)="login()">Sign In</button>
            <br/>
            <a (click)="showForgotPassword()" id="forgot-password">Forgot your password?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
