import {Component, OnInit} from '@angular/core';
import {RouteTagEnrollment } from 'src/app/models/RouteTagEnrollment';
import {RouteService} from '../../services/route-service';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';
import {Router} from '@angular/router';

  @Component({
    selector: 'tag-enrollment',
    templateUrl: './tag-enrollment.component.html',
    styleUrls: ['./tag-enrollment.component.scss']
  })

  export class TagEnrollmentComponent implements OnInit {
    isBusy = false;

    enrollingTags: RouteTagEnrollment[] = [];

    displayedColumns = ['name', 'serialNumber', 'siteName', 'routeName', 'tagPosition', 'status'];

    constructor(
      private routeService: RouteService, 
      protected router: Router)
      { }

    ngOnInit(): void { 
        this.getEnrollingTags();
    }

    getEnrollingTags() {
        this.routeService.getAllEnrollingTags().subscribe((result) => {
          let jsonConvert = new JsonConvert();
          jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  
          this.enrollingTags = jsonConvert.deserializeArray(result.body as any, RouteTagEnrollment);
    });
    }

    declineTag(tag: RouteTagEnrollment) {
      const selectedTag: RouteTagEnrollment = (tag) ? tag : null;
  
      this.routeService.declineTagEnrollment(selectedTag).subscribe((result) => {
        if(result.status == 200){
          location.reload()
        }
      });
    }

    acceptTag(tag: RouteTagEnrollment) {
      const selectedTag: RouteTagEnrollment = (tag) ? tag : null;
  
      this.routeService.acceptTagEnrollment(selectedTag).subscribe((result) => {
        if(result.status == 200){
          location.reload()
        }
      });
    }
  }