import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('EmployeeClockTimes')
export class EmployeeClockTimes {
  @JsonProperty('employeeName', String, true)
  public employeeName: string = undefined;

  @JsonProperty('employeeSurname', String, true)
  public employeeSurname: string = undefined;

  @JsonProperty('siteName', String, true)
  public siteName: string = undefined;

  @JsonProperty('userRole', String, true)
  public userRole: string = undefined;

  @JsonProperty('clockInTimeEpoch', Number, true)
  public clockInTimeEpoch: number = undefined;

  @JsonProperty('clockOutTimeEpoch', Number, true)
  public clockOutTimeEpoch: number = undefined;
}