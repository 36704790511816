<div class="action-bar" *ngIf="currEmployeeRole !== 'controller'">
  <button class="btn btn-icon mr-2" (click)="selectSchedule(0)">
    <span class="ul-btn__text">Add a new Schedule</span>
    <span class="ul-btn__icon"><mat-icon>add</mat-icon></span>
  </button>
</div>

<div class="card">
  <table mat-table [dataSource]="dataSource" class="table-hover">
    <ng-container matColumnDef="routeId">
      <th mat-header-cell *matHeaderCellDef> Route </th>
      <td mat-cell *matCellDef="let element"> {{element.routeName}} </td>
    </ng-container>

    <ng-container matColumnDef="startTimeDay">
      <th mat-header-cell *matHeaderCellDef> Time Start Day</th>
      <td mat-cell *matCellDef="let element"><span *ngIf="element.startTimeDay !== element.endTimeDay">{{element.localStartTimeDay}}</span></td>
    </ng-container>

    <ng-container matColumnDef="startTimeNight">
      <th mat-header-cell *matHeaderCellDef> Time Start Night</th>
      <td mat-cell *matCellDef="let element"><span *ngIf="element.startTimeNight !== element.endTimeNight">{{element.localStartTimeNight}}</span></td>
    </ng-container>

    <ng-container matColumnDef="repeatMinutes">
      <th mat-header-cell *matHeaderCellDef> Repeat Every </th>
      <td mat-cell *matCellDef="let element"> {{element.repeatMinutes}} </td>
    </ng-container>

    <ng-container matColumnDef="monday">
      <th mat-header-cell *matHeaderCellDef> M </th>
      <td mat-cell *matCellDef="let element"><mat-checkbox [disabled]="true" [checked] = element.monday></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="tuesday">
      <th mat-header-cell *matHeaderCellDef> T </th>
      <td mat-cell *matCellDef="let element"><mat-checkbox [disabled]="true" [checked] = element.tuesday></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="wednesday">
      <th mat-header-cell *matHeaderCellDef> W </th>
      <td mat-cell *matCellDef="let element"><mat-checkbox [disabled]="true" [checked] = element.wednesday></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="thursday">
      <th mat-header-cell *matHeaderCellDef> T </th>
      <td mat-cell *matCellDef="let element"><mat-checkbox [disabled]="true" [checked] = element.thursday></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="friday">
      <th mat-header-cell *matHeaderCellDef> F </th>
      <td mat-cell *matCellDef="let element"><mat-checkbox [disabled]="true" [checked] = element.friday></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="saturday">
      <th mat-header-cell *matHeaderCellDef> S </th>
      <td mat-cell *matCellDef="let element"><mat-checkbox [disabled]="true" [checked] = element.saturday></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="sunday">
      <th mat-header-cell *matHeaderCellDef> S </th>
      <td mat-cell *matCellDef="let element"><mat-checkbox [disabled]="true" [checked] = element.sunday></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element"><mat-icon>chevron_right</mat-icon></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (click)="selectSchedule(schedule[i])">></tr>
  </table>
</div>

<mat-paginator #MatPaginator
               [length] = "schedule?.length"
               [pageSize] = "10"
               [pageSizeOptions] = "[10, 25, 100]">
</mat-paginator>

<h3 style="margin-top: 40px; margin-bottom: 15px">Generated schedule plans</h3>
<div class="card" style="margin-bottom: 15px">
  <table mat-table [dataSource]="schedulePlanTagsDatasource">

    <ng-container matColumnDef="routeName">
      <th mat-header-cell *matHeaderCellDef> Route </th>
      <td mat-cell *matCellDef="let element"> {{element.routeName}} </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Schedule Plan Id</th>
      <td mat-cell *matCellDef="let element"><span >{{element.id}}</span></td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef> Schedule Start</th>
      <td mat-cell *matCellDef="let element"><span >{{formatDateTime(element.startTime)}}</span></td>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <th mat-header-cell *matHeaderCellDef> Schedule End</th>
      <td mat-cell *matCellDef="let element"><span >{{formatDateTime(element.endTime)}}</span></td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Tag </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="estimatedScanEpoch">
      <th mat-header-cell *matHeaderCellDef> Tag scan time </th>
      <td mat-cell *matCellDef="let element"> {{formatDateTime(element.estimatedScanEpoch)}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="genSchedulesTagDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: genSchedulesTagDisplayedColumns; let i = index"></tr>
  </table>
</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
