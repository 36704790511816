import {Injectable} from '@angular/core';
import {StorageService} from './storage-service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
  }

  public getAuthHeaders() {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Max-Age', '86400')
      .set('x-access-token', this.storageService.getAccessToken());
  }

  private getHeadersForFileUpload() {
    return new HttpHeaders()
      .set('Content-Type', 'application/octet-stream')
      .set('x-access-token', this.storageService.getAccessToken());
  }

  private getUrl(url, variable = null) {
    let fullUrl = environment.baseUrl;

    (url[0] === '/') ? fullUrl += url : fullUrl += ('/' + url);

    if (fullUrl[fullUrl.length - 1] === '/') {
      fullUrl = fullUrl.slice(0, fullUrl.length - 1);
    }

    if (variable) {
      fullUrl += '/' + variable;
    }

    return fullUrl;
  }

  getData(url, variable = null) {
    const headers = this.getAuthHeaders();
    return this.http.get(this.getUrl(url, variable), {
      headers,
      observe: 'response'
    });
  }

  postData(url, variable = null) {
    const headers = this.getAuthHeaders();
    return this.http.post(this.getUrl(url), variable, {
      headers,
      observe: 'response'
    });
  }

  getReportUrl(url) {
    const headers = this.getAuthHeaders();
    return this.http.get(`${environment.reportBaseUrl}/${url}`, {
      headers,
      observe: 'response'
    });
  }

  postReportUrl(url, variable = null) {
    const headers = this.getAuthHeaders();
    return this.http.post(`${environment.reportBaseUrl}/${url}`, variable, {
      headers,
      observe: 'response'
    });
  }

  postDataServiceBus(url, variable = null) {
    const headers = this.getAuthHeaders();
    return this.http.post(environment.eventMicroserviceBaseUrl + url, variable, {
      headers,
      observe: 'response'
    });
  }

  postFile(url, variable = null) {
    const headers = this.getHeadersForFileUpload();
    return this.http.post(this.getUrl(url), variable, {
      headers,
      observe: 'response'
    });
  }

  deleteData(url, variable = null) {
    const headers = this.getAuthHeaders();
    return this.http.delete(this.getUrl(url, variable), {
      headers,
      observe: 'response'
    });
  }

}
