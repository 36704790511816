import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, destroyPlatform } from '@angular/core';
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { StorageService } from '../../services/storage-service';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentBaseComponent, MessageType } from '../../component-base';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Site } from '../../models/Site';
import { Device } from '../../models/Device';
import { DeviceService } from '../../services/device-service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, interval, of, } from 'rxjs';
import { MapDetailsService } from '../../services/map-details.service';
import { switchMap } from 'rxjs/operators';
import { TrackingService } from "../../services/TrackingService";
import { forEachChild } from 'typescript';
import { GpsLocation } from '../../models/GpsLocation';
import * as L from 'leaflet';
import { MatPaginator } from "@angular/material/paginator";
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as moment from 'moment';
import { DeviceLocation } from 'src/app/models/DeviceLocation';
@Component({
    selector: 'app-tracking-detail',
    templateUrl: './tracking-detail.component.html',
    styleUrls: ['./tracking-detail.component.scss']
})
export class TrackingDetailComponent extends ComponentBaseComponent implements OnInit, AfterViewInit, OnDestroy {
    devices: Device[]  = [];
    selectedDevice: any;

    UTC_SHIFT_START_HOUR = 4;
    UTC_SHIFT_END_HOUR = 21;
    UTC_SHIFT_END_MINUTE = 59;
    today;
    startDate;
    endDate;

    isBusy: boolean = false;

    map: L.Map;
    @ViewChild('leafletmap')
    private mapElement: ElementRef;
    private marker: L.Marker;
    private markerLive: any;

    deviceLocations: DeviceLocation[] = [];
    deviceLiveLocation: DeviceLocation;
    dataSource = new MatTableDataSource<DeviceLocation>(this.deviceLocations);
    displayHistoryColumns = ['sentAt', 'latitude', 'longitude']
    @ViewChild('DeviceLocation_paginator', { static: true }) DeviceLocation_paginator: MatPaginator;

    private _isPlaying = false;
    private _isPaused = false;
    private currentIndex = 0;
    private _polyline: L.Polyline;
    private timer = 0;
    private mapIcon = L.divIcon({
        className: 'custom-div-icon',
        html: `<div style='background-color: #D4082E; border: 1px solid #666; border-radius: 50%; width: 15px; 
                height: 15px; display: flex; justify-content: center; margin-left: 6px; margin-top: 22px;'>
                <i class='material-icons' style='font-size: 24px; color:Yellow'></i></div>`,
        iconSize: [30, 30],
        iconAnchor: [15, 30]
    });
    private deviceMarkers: { [deviceId: string]: L.Marker } = {};

    constructor(
        private deviceService: DeviceService,
        protected storageService: StorageService,
        public router: Router,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
        private _elementRef: ElementRef,
        private _renderer: Renderer2,
        private _fb: FormBuilder,
        private trackingService: TrackingService
    ) {
        super(snackbar, dialog, router, storageService);
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.today = moment(new Date(`${moment(new Date()).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`));
        this.getDevices();
        this.resetDate();
    }

    ngAfterViewInit(): void {
        this.leafletMap();
    }

    getDevices() {
        const siteId = this.storageService.getCurrentSite().id;
        if (siteId) {
            this.deviceService.getAllDevicesBySite(siteId).subscribe((result) => {
                const jsonConvert = new JsonConvert();
                jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

                this.devices = jsonConvert.deserializeArray(result.body as any, Device) as Device[];
                if  (this.devices && this.devices.length >0){
                    this.selectedDevice = this.devices[0];
                }
            }, error => {
                this.showMessage('Error found when getting devices.', MessageType.error);
                this.handleError(error);
            });
        } else {
            this.showMessage("Could not retrieve Devices.", MessageType.error);
        }
        this.isBusy = false;
    }

    validateDateRange(startDate, endDate): {isValid: boolean, message: string} {
        let startDateEpoch = startDate.getTime();
        let endDateEpoch = endDate.getTime();
    
        if (startDateEpoch === endDateEpoch) {
            return {isValid: false, message: 'Start and end date can\'t be the same.'}
        } else if (startDateEpoch > endDateEpoch) {
            return {isValid: false, message: 'Start date can\'t be greater than the end date.'}
        }else if (this.endDate > this.today.add(1, 'days').toDate()) {
            return {isValid: false, message: 'End date can\'t be greater than today.'}
        }else if((endDateEpoch - startDateEpoch) > 583200000) {
            return {isValid: false, message: 'Date range can\'t be greater than 7 days.'}
        }
    
        return {isValid: true, message: null}
    }
    
    getShiftStartTimeString(): string {
        let date = moment(new Date(`1970-01-01T0${this.UTC_SHIFT_START_HOUR}:00:00Z`));
    
        return date.format('HH:mm');
    }

    resetDate() {    
        this.endDate = this.today.toDate();    
        this.startDate = this.today.subtract(1, 'days').toDate();
    }

    leafletMap() {
        this.map = L.map(this.mapElement.nativeElement, { attributionControl: true, zoomControl: true }).setView([-25.747332504719942, 28.189201354980472], 18);

        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            maxZoom: 18,
            tileSize: 512,
            zoomOffset: -1,
        }).addTo(this.map);
    }

    formatDateTime(time: number) {
        return moment.unix(time).format('YYYY-MM-DD HH:mm');
    }

    RetrieveData() {
        this.refreshMap();

        let utcStartDate = new Date(`${moment(this.startDate).format('YYYY-MM-DD')}T0${this.UTC_SHIFT_START_HOUR}:00:00Z`);
        let utcEndDate = new Date(`${moment(this.endDate).format('YYYY-MM-DD')}T${this.UTC_SHIFT_END_HOUR}:${this.UTC_SHIFT_END_MINUTE}:00Z`);

        let startDateEpoch = utcStartDate.getTime() / 1000;
        let endDateEpoch = utcEndDate.getTime() / 1000;

        let deviceId = this.selectedDevice.id;

        let validationResponse = this.validateDateRange(utcStartDate, utcEndDate);

        if  (validationResponse.isValid) {
            this.isBusy = true;
            this.getDeviceLocationHistory(deviceId, startDateEpoch, endDateEpoch);
        } 
        else {
            this.showMessage(validationResponse.message, MessageType.error);
        }
    }

    refreshMap() {
        this._isPaused = false;
        this._isPlaying = false;
        this.currentIndex = 0;
        this.resetPlayback();
    }

    getDeviceLocationHistory(deviceId, startDate, endDate) {
        this.trackingService.GetHistoryDeviceLocationsByDate(deviceId, startDate, endDate).subscribe((result) => {
            let jsonConvert = new JsonConvert();
            jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    
            this.deviceLocations = jsonConvert.deserializeArray(result.body as any, DeviceLocation);
            this.dataSource = new MatTableDataSource<DeviceLocation>(this.deviceLocations);

            if (this.deviceLocations.length == 0) {
                this.showMessage("No History found.", MessageType.warning);
            }
            else {
                this.dataSource.paginator = this.DeviceLocation_paginator;
            }

            this.isBusy = false;
        }, error => {
            this.isBusy = false;
            this.handleError(error);
        });
    }    

    setMarker(position: L.LatLng): void {
        if (this.marker) {
            this.marker.setLatLng(position);
        }
        else {
            const icon = L.icon({
                iconUrl: '../../../assets/images/maps/map-marker.png',
                iconSize: [50, 50],
                iconAnchor: [25, 30],
                popupAnchor: [0, -41],
            });

            this.marker = L.marker(position, {
                icon: icon,
                draggable: false
            }).bindPopup('Device Location');
            this.marker.addTo(this.map);
        }
    }

    showOnMap(deviceHistory): void {
        const selDevice = deviceHistory as DeviceLocation;
        if (selDevice) {
            this.setMarker(new L.LatLng(Number(selDevice.latitude), Number(selDevice.longitude)));
            this.map.flyTo([Number(selDevice.latitude), Number(selDevice.longitude)], 16);
        } else {
            this.showMessage("The device can not be marked", MessageType.warning);
        }
    }

    playbackForSelectedDevice(playback: string) {
        this.isBusy = false;
        switch (playback) {
            case 'fastForward':
            case 'play': {
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                    delete this.marker;
                }

                if (this.deviceLocations.length > 0) {
                    if (!this._isPlaying) {
                        this._isPlaying = true;
                        this.playback(playback);
                    }
                    if (this._isPaused) {
                        this._isPaused = false;
                    }
                } else {
                    let MessageText: string = "There was no history found for selected device.";
                    this.showMessage(MessageText, MessageType.warning);
                }

                break;
            }
            case 'pause': {
                this._isPaused = true;
                break;
            }
            case 'stop': {
                this._isPaused = false;
                this._isPlaying = false;
                this.currentIndex = 0;
                this.resetPlayback();
                break;
            }
            default:
                {
                    break;
            }
        }
        this.isBusy = false;
    }

    toggleLiveLocation() {
        this.playbackForSelectedDevice('stop');

        this.deviceService.getDeviceLastLocation(this.selectedDevice.id).subscribe((result) => {
            let jsonConvert = new JsonConvert();
            jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    
            this.deviceLiveLocation = jsonConvert.deserializeObject(result.body as any, DeviceLocation);

            this.setMarker(new L.LatLng(Number(this.deviceLiveLocation.latitude), Number(this.deviceLiveLocation.longitude)));
            this.map.flyTo([Number(this.deviceLiveLocation.latitude), Number(this.deviceLiveLocation.longitude)], 16);
        }, error => {
            this.handleError(error);
        });
    }

    createDeviceMarker(deviceId: string, lat: number, lng: number) {
        if (this.deviceMarkers[deviceId]) {
            this.deviceMarkers[deviceId].setLatLng([lat, lng]);
        } else {
            const marker = L.marker([lat, lng], { icon: this.mapIcon }).addTo(this.map);
            this.deviceMarkers[deviceId] = marker;
        }
    }

    resetPlayback(): void {
        this.timer = 0;
        if (this._polyline) {
            this.map.removeLayer(this._polyline);
            this._polyline = L.polyline([], { color: 'blue' });
        }

        this.deviceMarkers[String(this.selectedDevice.id)]?.remove();
        delete this.deviceMarkers[String(this.selectedDevice.id)];

        if (this.marker) {
            this.map.removeLayer(this.marker);
            delete this.marker;
        }
    }

    playback(currentPlayType: string) {
        this.isBusy = true;
        const animateMovement = () => {
            if (!this._isPlaying || this.currentIndex >= this.deviceLocations.length) {
                return;
            }

            if (this._isPaused) {
                setTimeout(animateMovement, 500);
                return;
            }

            if (!this._polyline) {
                this._polyline = L.polyline([], { color: 'blue' }).addTo(this.map);
            }

            const element = this.deviceLocations[this.currentIndex];
            const lat = Number(element.latitude);
            const lng = Number(element.longitude);

            if (!isNaN(lat) && !isNaN(lng)) {
                this._polyline.addLatLng([lat, lng]).addTo(this.map);
                this.map.panTo([lat, lng]);
                this.createDeviceMarker(String(this.selectedDevice.id), lat, lng);
            }

            switch (currentPlayType) {
                case 'play':
                    this.currentIndex++;
                    this.timer = 1000;
                    break;
                case 'fastForward':
                    this.currentIndex++;
                    this.timer = 500;
                    break;
                default:
                    this.timer = 1000;
                    break;
            }

            setTimeout(animateMovement, this.timer);
        };

        animateMovement();
        this.isBusy = false;
    }
}