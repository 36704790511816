import {Injectable} from '@angular/core';
import {CommonService} from './commonService';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EventService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }

  respondToAlertAlarm(alertAlarmResponse) {
    return this.http.post(environment.eventMicroserviceBaseUrl + '/api/v1/alarmAlert/respond', alertAlarmResponse, {
      headers: this.commonService.getAuthHeaders(),
      observe: 'response'
    });
  }

  getEventsByDateRange(startDate: number, endDate: number, companyId) {
    return this.http.get(environment.baseUrl + `/api/v1/alert/byDateRange?startDate=${startDate}&endDate=${endDate}&companyId=${companyId}`, {
      headers: this.commonService.getAuthHeaders(),
      observe: 'response'
    });
  }

  getUnrespondedToAlertAlarms(companyId: number) {
    return this.commonService.getData('/api/v1/alert/unrespondedTo?companyId=' + companyId);
  }
}
