 import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import * as io from 'socket.io-client';
import {StorageService} from './storage-service';

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  private static socket;
  private static tokenUsed: string;
  private static isLoaded = false;

  constructor(
    private storageService: StorageService
  ) {
    const currentToken = this.storageService.getAccessToken();
    if (!SocketService.isLoaded && (!SocketService.socket || (SocketService.tokenUsed !== currentToken))) {
      SocketService.isLoaded = true;
      SocketService.tokenUsed = currentToken;
      SocketService.socket = io.connect(environment.socketBaseUrl, {query: `token=${currentToken}`});
    }
  }

  public getSocket() {
    return SocketService.socket;
  }
}
