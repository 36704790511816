import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EVENT_TYPES, GREEN_ALERTS, RED_ALERTS, YELLOW_ALERTS} from '../../enums/EventTypes';
import {
  getAlertDateTime,
  getAlertDescription,
  getAlertTopic,
  getDeviceDetailFromAlert,
  getEmployeeDetailFromAlert,
  getRouteTakenDetailFromAlert,
  getSiteDetailFromAlert
} from '../../logic/AlertLogic';
import {ComponentBaseComponent, MessageType} from '../../component-base';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StorageService} from '../../services/storage-service';
import {AlertAlarmResponse} from '../../models/AlertAlarmResponse';
import {EventService} from '../../services/event.service';
import {filter, take, tap} from 'rxjs/operators';

@Component({
  selector: 'app-alert-details',
  templateUrl: './alert-details.component.html',
  styleUrls: ['./alert-details.component.scss']
})
export class AlertDetailsComponent extends ComponentBaseComponent implements OnInit {
  alert: any;

  headerStyleClass: string;
  eventTypeTitle: string;
  alertTime: string;
  responseMessage: string;
  constructor(
    private eventService: EventService,
    protected dialog: MatDialog,
    protected router: Router,
    protected snackbar: MatSnackBar,
    protected storageService: StorageService,
    private dialogRef: MatDialogRef<AlertDetailsComponent>
  ) { super(snackbar, dialog, router, storageService) }

  ngOnInit(): void {
    this.closeDialogOnNavigation();

    this.getAlert();
  }

  closeDialogOnNavigation() {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this.dialogRef.close()),
      take(1),
    ).subscribe();
  };

  getAlert() {
    this.alert = this.dialogRef._containerInstance._config.data.alert;
    const alertType = this.alert.type;

    this.getHeaderStyle(alertType);
    this.getEventTypeTitle(alertType);
    this.alertTime = getAlertDateTime(this.alert);
  }

  getHeaderStyle(alertType: string) {
    if (GREEN_ALERTS.includes(alertType)) {
      this.headerStyleClass = 'bg-alert-green';
    } else if (YELLOW_ALERTS.includes(alertType)) {
      this.headerStyleClass = 'bg-alert-yellow';
    } else if (RED_ALERTS.includes(alertType)) {
      this.headerStyleClass = 'bg-alert-red';
    } else {
      this.headerStyleClass = 'bg-alert-misc';
    }
  }

  getEventTypeTitle(alertType: string) {
    switch (alertType) {
      case EVENT_TYPES.PANIC: {
        this.eventTypeTitle = 'Panic';
        break;
      }
      case EVENT_TYPES.ATTENDANCE: {
        this.eventTypeTitle = 'Attendance';
        break;
      }
      case EVENT_TYPES.ALERT_ALARM_RESPONSE: {
        this.eventTypeTitle = 'Alert Alarm Response';
        break;
      }
      case EVENT_TYPES.NFC_TAG_SCAN: {
        this.eventTypeTitle = 'Route Tag Scan';
        break;
      }
      case EVENT_TYPES.NFC_TAG_SCAN_UNALLOCATED: {
        this.eventTypeTitle = 'Unallocated Tag Scan';
        break;
      }
      case EVENT_TYPES.PLEASE_CALL_ME: {
        this.eventTypeTitle = 'Please Call Me';
        break;
      }
      case EVENT_TYPES.PATROL_SCAN_ALERT: {
        this.eventTypeTitle = 'Patrol Tag Scan';
        break;
      }
      case EVENT_TYPES.PATROL_ENDED: {
        this.eventTypeTitle = 'Patrol Ended';
        break;
      }
      default: {
        this.eventTypeTitle = '';
        break;
      }
    }
  }

  getAlertDescription() {
    return getAlertDescription(this.alert, true);
  }

  getRouteTakenPanelTitle() {
    return (this.alert.routeTaken.route && this.alert.routeTaken.route.name) ? 'Patrol - ' + this.alert.routeTaken.route.name : 'Patrol';
  }

  getEmployeePanelContent() {
    return getEmployeeDetailFromAlert(this.alert);
  }

  getDevicePanelContent() {
    return getDeviceDetailFromAlert(this.alert);
  }

  getRouteTakenPanelContent() {
    return getRouteTakenDetailFromAlert(this.alert);
  }

  getSitePanelContent() {
    return getSiteDetailFromAlert(this.alert);
  }

  sendResponseMessage() {
    if (this.responseMessage) {
      let dialogRef = this.showConfirmationDialog('respond to that message');

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const currentDateTimeEpoch = new Date().getTime()/1000;

          const loggedInEmployee = this.storageService.getLoggedInEmployee();

          let alertAlarmResponse = new AlertAlarmResponse();
          alertAlarmResponse.message = this.responseMessage;
          alertAlarmResponse.respondedAt = currentDateTimeEpoch;
          alertAlarmResponse.respondedToType = this.alert.type;
          alertAlarmResponse.respondedToGuid = ('eventGuid' in this.alert) ? this.alert.eventGuid : this.alert.completedEventGuid;
          alertAlarmResponse.companyId = this.alert.companyId;
          alertAlarmResponse.employeeId = loggedInEmployee.id;
          alertAlarmResponse.topic = getAlertTopic(this.alert);

          this.eventService.respondToAlertAlarm(alertAlarmResponse).subscribe(result => {
            this.dialogRef.close({
              successful: true
            });
          }, error => {
            this.handleError(error);
          });
        }
      });
    } else {
      this.showMessage('Please explain what you did to respond to this alert.', MessageType.error);
    }
  }
}
