import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChatMessage} from '../../models/ChatMessage';
import {ChatService} from '../../services/chat-service';
import {MessageType} from '../../component-base';

@Component({
  selector: 'app-send-image-view',
  templateUrl: './send-image-view.component.html',
  styleUrls: ['./send-image-view.component.css']
})
export class SendImageViewComponent implements OnInit {
  public imgUri: any = null;
  public file: any;
  public extentionType: any;
  isBusy: boolean = false;
  siteId: number;
  userId: number;
  senderName: string = '';
  isPreview: boolean = false;


  constructor(public dialogRef: MatDialogRef<SendImageViewComponent>, public chatService: ChatService, @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  ngOnInit(): void {
    this.isPreview = this.data.isPreview;

    if (!this.isPreview) {
      this.siteId = this.data.siteId;
      this.senderName = this.data.senderName;
      this.userId = this.data.userId;
    } else {
      this.imgUri = this.data.url;
    }
  }

  onFileSelected(event) {
    this.isBusy = true;
    let reader = new FileReader();
    this.extentionType = event.target.files[0].type;

    this.file = event.target.files[0];

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event) => {
      this.isBusy = false;
      this.imgUri = reader.result;
    };
  }

  closeDialog(isView, message = '', messageType = '') {
    if (!isView) {
      this.dialogRef.close({message, messageType});
    } else {
      this.dialogRef.close({isView: isView});
    }

  }

  sendImage() {
    if(this.imgUri){
      let messageObject = new ChatMessage();
      this.chatService.uploadImage(this.file, this.extentionType, this.file.name).subscribe(result => {
        let data = result.body as any;
        messageObject.siteId = this.siteId;
        messageObject.senderName = this.senderName;
        messageObject.message = '';
        messageObject.userId = this.userId;
        messageObject.imageUrl = data.documentUrl;

        this.chatService.insertChat(messageObject).subscribe(result => {
          this.closeDialog(false, 'Successfully sent message', MessageType.success);
        }, error => {
          console.log(error);
          this.closeDialog(false, 'Message failed to send. Please try again later.', MessageType.warning);
        });
      }, error => {
        console.log(error);
        this.closeDialog(false, 'Image upload failed. Please try again later.', MessageType.warning);
      });
    }else{
      alert('Select Image');
    }
  }
}
