<div class="side-content-wrap">
  <div class="sidebar-left open rtl-ps-none" data-perfect-scrollbar="" data-suppress-scroll-x="true">
    <ul class="navigation-left">

      <li class="nav-item" ><a class="nav-item-hold" routerLink="/company/alerts" routerLinkActive="nav-selected" *ngIf="currEmployeeRole !== 'sys-admin'"><mat-icon
        class="nav-icon">notification_important</mat-icon><span class="nav-text">Alerts</span></a>
      </li>
      <li class="nav-item" ><a class="nav-item-hold" routerLink="/company/chats" routerLinkActive="nav-selected" *ngIf="currEmployeeRole !== 'sys-admin'"><mat-icon
        class="nav-icon">message</mat-icon><span class="nav-text">Chats</span></a>
      </li>
      <li class="nav-item" ><a class="nav-item-hold" routerLink="/company/sites" routerLinkActive="nav-selected"><mat-icon
        class="nav-icon">business</mat-icon><span class="nav-text">Sites</span></a>
      </li>
      <li class="nav-item" ><a class="nav-item-hold" routerLink="/company/dashboard" routerLinkActive="nav-selected"><mat-icon
        class="nav-icon">dashboard</mat-icon><span class="nav-text">Dashboard</span></a>
      </li>
      <li class="nav-item" ><a class="nav-item-hold" routerLink="/company/reports" routerLinkActive="nav-selected"><mat-icon
        class="nav-icon">analytics</mat-icon><span class="nav-text">Reports</span></a>
      </li>
      <li class="nav-item" ><a class="nav-item-hold" routerLink="/company/employees" routerLinkActive="nav-selected"><mat-icon
        class="nav-icon">assignment_ind</mat-icon><span class="nav-text">Employees</span></a>
      </li>
      <li class="nav-item" ><a class="nav-item-hold" routerLink="/company/areas" routerLinkActive="nav-selected"><mat-icon
        class="nav-icon">terrain</mat-icon><span class="nav-text">Areas</span></a>
      </li>
      <li class="nav-item"><a class="nav-item-hold" routerLink="/company/attendance" routerLinkActive="nav-selected"><mat-icon
        class="nav-icon">schedule</mat-icon><span class="nav-text">Attendance</span></a>
      </li>
      <li class="nav-item"><a class="nav-item-hold" routerLink="/company/tag/enrollment" routerLinkActive="nav-selected"><mat-icon
        class="nav-icon">control_point_duplicate</mat-icon><span class="nav-text">Tag Enrollment</span></a>
      </li>
    </ul>
  </div>
  <div class="sidebar-overlay"></div>
</div>
<!-- =============== Left side End ================-->
