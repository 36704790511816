<div class="row">
  <audio #alertAlarmAudio>
    <!-- Public licensed audio file from https://www.freesfx.co.uk/sfx/alarm  -->
    <source src='../../../assets/audio/AlertAlarm.mp3' type="audio/mp3">
  </audio>

  <div class="column">
    <div class="row hoverable" id="alert-counter-container" routerLink="/company/alerts">
      <div class="alert-count bg-alert-green">
        <span class="alert-count-inner">{{greenAlertCounter}}</span>
      </div>

      <div class="alert-count bg-alert-yellow">
        <span class="alert-count-inner">{{yellowAlertCounter}}</span>
      </div>

      <div class="alert-count bg-alert-red">
        <span class="alert-count-inner">{{redAlertCounter}}</span>
      </div>

      <div class="alert-count bg-alert-misc">
        <span class="alert-count-inner">{{miscAlertCounter}}</span>
      </div>
    </div>

    <!-- <div id="alarm-area" class="column" *ngIf="alertAlarms && currEmployeeRole !== 'sys-admin'">
      <div *ngFor="let alertAlarm of alertAlarms; let i = index" class="alert-alarm" (click)="openAlertAlarmResponseDialog(i)">
        <div class="row">
          <span class="alert-alarm-type">{{alertAlarm.type}}</span>
          <span class="alert-alarm-time">{{getAlertDateTime(alertAlarm)}}</span>
        </div>

        <span>{{getAlertAlarmDescription(alertAlarm)}}</span>
      </div>
    </div> -->
  </div>

  <div class="row" id="server-indicator">
    <mat-icon class="connection-icon-green" *ngIf="socketConnectionAlive" matTooltip={{socketConnectionMessage}}>link</mat-icon>
    <mat-icon class="connection-icon-red" *ngIf="!socketConnectionAlive" matTooltip={{socketConnectionMessage}}>link_off</mat-icon>
  </div>

  <div class="row" id="volume-controls">
    <mat-icon class="clickable" *ngIf="!isMuted" (click)="setAudioVolume(true)">volume_up</mat-icon>
    <mat-icon class="clickable" *ngIf="isMuted" (click)="setAudioVolume(false)">volume_off</mat-icon>
  </div>
</div>


