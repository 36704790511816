<div class="container">
  <div class="column" style="margin-left: 2%; margin-right: 2%">
    <div class="row">
      <h1>Select a Company</h1>

      <button class="btn btn-icon mr-2" (click)="registerCompany()">
        <span class="ul-btn__text">Add a new Company</span>
        <span class="ul-btn__icon"><mat-icon>add</mat-icon></span>
      </button>
    </div>
  </div>
  <div class="card">
    <table mat-table [dataSource]="dataSource" class="table-hover">
      <ng-container matColumnDef="shield">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><img src="../../../assets/dist-assets/images/Shield.jpg" alt=""></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> Address</th>
        <td mat-cell *matCellDef="let element">{{element.address}}</td>
      </ng-container>

      <ng-container matColumnDef="profileIcon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"><img src="../../../assets/dist-assets/images/profileIcon.png" alt="">
        </td>
      </ng-container>

      <ng-container matColumnDef="contactPerson">
        <th mat-header-cell *matHeaderCellDef> Contact Person</th>
        <td mat-cell *matCellDef="let element"> {{element.contactPerson}} <br> {{element.contactNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index"
            (click)="editCompany(companies[i + (paginator.pageSize * paginator.pageIndex)])">
          <mat-icon>edit</mat-icon>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
          (click)="selectCompany(companies[i + (paginator.pageSize * paginator.pageIndex)])"></tr>
    </table>

  </div>

  <mat-paginator #MatPaginator
                 [length]="companies?.length"
                 [pageSize]="10"
                 [pageSizeOptions]="[10, 25, 100]">
  </mat-paginator>

</div>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
