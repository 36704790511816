import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ComponentBaseComponent} from "../../component-base";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  actionText;
  buttonIcon;
  buttonText;

  constructor(
    public dialogRef: MatDialogRef<boolean>,
  ) {}

  ngOnInit(): void {
    let data = this.dialogRef._containerInstance._config.data;

    this.actionText = (data.actionText) ? data.actionText : 'take that action';
    this.buttonIcon = (data.buttonIcon) ? data.actionText : 'save';
    this.buttonText = (data.buttonText) ? data.buttonText : 'Continue';
  }

  confirm(i : boolean){
   this.dialogRef.close(i)
  }
}
