import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../services/storage-service';
import {MessageService} from '../../message.service';
import {Company} from '../../models/Company';
import {Subscription} from 'rxjs';
import {ROLE_TYPES} from '../../enums/RoleTypes';
import {Employee} from '../../models/Employee';

@Component({
  selector: 'app-company-navbar',
  templateUrl: './company-navbar.component.html',
  styleUrls: ['./company-navbar.component.scss']
})

export class CompanyNavbarComponent implements OnInit {
  @ViewChild('sideBarLeftSecondary') sideBarLeftSecondary: ElementRef;
  @ViewChild('sideBarOverlay') sideBarOverlay: ElementRef;
  loggedInEmployee: Employee;
  isOpen = false;
  subscription: Subscription;
  company: Company = null;

  ROLE_TYPES = ROLE_TYPES;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService) {}

  ngOnInit(): void {
    this.getCurrentEmployeeDetails();
  }

  logout() {
    this.router.navigate(['login']);
  }

  public closeSidebarSecondary() {
    this.sideBarLeftSecondary.nativeElement.classList.remove('open');
    this.sideBarOverlay.nativeElement.classList.remove('open');
  }

  getCurrentEmployeeDetails() {
    this.loggedInEmployee = this.storageService.getLoggedInEmployee();

    if (this.loggedInEmployee.role === ROLE_TYPES.SYS_ADMIN) {
      this.company = this.storageService.getSelectedCompany();
    } else {
      this.company = this.loggedInEmployee.company;
    }
  }

  returnHome() {
    this.router.navigate(['companies'], {replaceUrl: true});
  }
}
