<div class="action-bar" *ngIf="currEmployeeRole !== 'controller'">
  <button class="btn btn-icon mr-2" (click)="selectEmployee(null)">
    <span class="ul-btn__text">Add a new Employee</span>
    <span class="ul-btn__icon"><mat-icon>add</mat-icon></span>
  </button>
</div>

<div class="card">
  <table mat-table [dataSource]="dataSource" class="table-hover">

    <ng-container matColumnDef="Icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"> <img src="../../../assets/dist-assets/images/assignment_ind.png" alt=""> </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name + ' ' + element.surname}} </td>
    </ng-container>

    <ng-container matColumnDef="contactNumber">
      <th mat-header-cell *matHeaderCellDef> Contact Number </th>
      <td mat-cell *matCellDef="let element"> {{element.contactNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef> Email Address </th>
      <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
    </ng-container>

    <ng-container matColumnDef="site">
      <th mat-header-cell *matHeaderCellDef> Site </th>
      <td mat-cell *matCellDef="let element"> {{element.site.name}} </td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element"><mat-icon>chevron_right</mat-icon></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (click)="selectEmployee(employees[i + (paginator.pageSize * paginator.pageIndex)])"></tr>
  </table>

</div>

<mat-paginator #MatPaginator
               [length] = "employees?.length"
               [pageSize] = "10"
               [pageSizeOptions] = "[10, 25, 100]">
</mat-paginator>

<mat-spinner class="centered-spinner" *ngIf="isBusy"></mat-spinner>
