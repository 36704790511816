import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export enum MessageTypes {
  CompanySelected = 'companySelected'
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private subject = new Subject<any>();

  sendMessage(id: MessageTypes, value: any) {
    this.subject.next({id, value});
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
