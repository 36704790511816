<div class="page-title">
  <p style="font-size: 22px">{{company.name + ' - Yesterday\'s Shift Performance - ' + lastShiftStart.format('YYYY-MM-DD')}}</p>
  <hr>
</div>

<div class="row">
  <div class="column" style="margin-right: 15px">
    <div class="card gauge-card">
      <h3 style="margin-bottom: 32px">{{'Tags Scanned (' + tagsScannedPercentage + '%)'}}</h3>

      <div id="tags-scanned-gauge"></div>

      <mat-spinner *ngIf="tagsScannedGaugeIsBusy" style="margin-bottom: 15px"></mat-spinner>

      <span>{{'Tags Scanned ' + tagsScanned + ' / ' + tagsShouldveBeenScanned}}</span>
    </div>

    <div class="card gauge-card">
      <h3 style="margin-bottom: 32px">{{'Completed Patrols (' + completedPatrolsPercentage + '%)'}}</h3>

      <div id="completed-patrols-gauge"></div>

      <mat-spinner *ngIf="completedPatrolsGaugeIsBusy" style="margin-bottom: 15px"></mat-spinner>

      <span>{{'Completed Patrols ' + completedPatrols + ' / ' + totalPatrols}}</span>
    </div>
  </div>

  <div class="card sunburst-card" style="margin-right: 15px">
    <h3>{{sitesAboveThreshold.length + ' Sites Above SLA Threshold (' + slaThreshold + '%)'}}</h3>
    <p style="margin-bottom: 32px">{{'A site must have more than ' + slaThreshold + '% tags scanned to pass the SLA' }}</p>

    <div id="sites-above-threshold-sunburst"></div>
    <div *ngIf="sitesAboveThreshold.length === 0  && !sitesAboveThresholdSunburstIsBusy">No Sites above SLA Threshold</div>

    <mat-spinner *ngIf="sitesAboveThresholdSunburstIsBusy"></mat-spinner>
  </div>

  <div class="card sunburst-card">
    <h3>{{sitesBelowThreshold.length + ' Sites Below SLA Threshold (' + slaThreshold + '%)'}}</h3>
    <p style="margin-bottom: 32px">{{'A site must have more than ' + slaThreshold + '% tags scanned to pass the SLA' }}</p>

    <div id="sites-below-threshold-sunburst"></div>
    <div *ngIf="sitesBelowThreshold.length === 0 && !sitesBelowThresholdSunburstIsBusy">No Sites Below SLA Threshold</div>

    <mat-spinner *ngIf="sitesBelowThresholdSunburstIsBusy"></mat-spinner>
  </div>

  <div class="row" style="width: 100%">
    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
      <div class="card-body text-center"><mat-icon>error_outline</mat-icon>
        <div class="content">
          <p class="text-muted mt-2 mb-0">Panic Alerts</p>
          <p class="text-primary text-24 line-height-1 mb-2">{{panicAlerts}}</p>
        </div>
      </div>
    </div>

    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
      <div class="card-body text-center"><mat-icon>phone_outline</mat-icon>
        <div class="content">
          <p class="text-muted mt-2 mb-0">Please Call Me Alerts</p>
          <p class="text-primary text-24 line-height-1 mb-2">{{pleaseCallMeAlerts}}</p>
        </div>
      </div>
    </div>

    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
      <div class="card-body text-center"><mat-icon>battery_alert</mat-icon>
        <div class="content">
          <p class="text-muted mt-2 mb-0">Low Battery Alerts</p>
          <p class="text-primary text-24 line-height-1 mb-2">{{lowBatteryAlerts}}</p>
        </div>
      </div>
    </div>

    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
      <div class="card-body text-center"><mat-icon>offline_bolt_outline</mat-icon>
        <div class="content">
          <p class="text-muted mt-2 mb-0">Device Offline Alerts</p>
          <p class="text-primary text-24 line-height-1 mb-2">{{deviceOfflineAlerts}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
